import React, { useEffect, useState } from 'react';
import { Link, useHistory, RouteComponentProps } from 'react-router-dom';
import { getSortState, translate, Translate } from 'react-jhipster';
import { getUsers, getUsersCount } from '../users.reducer';
import { TableList, TableListHeader, TableListBody, TableListPagination } from 'app/shared/components/table-list';
import { CardUser } from './user-card';
import { PageHeader } from 'app/shared/components/page';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { Flex } from 'app/shared/components/flex';
import { TabFilter } from 'app/shared/components/tab-filter';
import {
  defaultUserSearchParams,
  genUserCountSearchParams,
  genUserSearchParams,
  getUserSearchTableHeader,
  TdefaultUserSearchParams,
  TSearchTabs,
  TSort,
  userSearchTabs,
} from '../user.utils';
import { Button } from 'app/shared/components/button';
import { Icon } from 'app/shared/icons';
import { Loader } from 'app/shared/components/loader';
import { COLORS } from 'app/config/constants';
import { Alert } from 'app/shared/components/alert';
import { FilterNav } from 'app/shared/components/filters-nav';
import { getParamsFromUrl, setParamsToUrl } from 'app/shared/util/pagination-utils';

export const UsersSearch = (props: RouteComponentProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state: IRootState) => state.users);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [params, setParams] = useState<TdefaultUserSearchParams>(getParamsFromUrl(defaultUserSearchParams, props.location));

  const sort = (param: TSort) => () => {
    setParams({
      ...params,
      sortDirection: params.sortDirection === 'ASC' ? 'DESC' : 'ASC',
      sortBy: param,
    });
  };

  const onChangeTab = (tab: TSearchTabs) => {
    setParams({
      ...params,
      tab,
    });
  };

  const handlePagination = (currentPage: number) => {
    setParams({
      ...params,
      currentPage,
    });
  };

  const onChangeParams = filteredParamns => {
    setParams({ ...params, ...filteredParamns });
  };

  const onRemoveParams = key => {
    setParams({
      ...params,
      [key]: null,
    });
  };

  useEffect(() => {
    dispatch(getUsers(genUserSearchParams(params)));
    dispatch(getUsersCount(genUserCountSearchParams(params)));
    setParamsToUrl(params, history);
  }, [
    params.currentPage,
    params.sortDirection,
    params.sortBy,
    params.tab,
    params.name,
    params.login,
    params.email,
    params.rui,
    params.type,
    params.overDraft,
  ]);

  useEffect(() => {
    setParams(getParamsFromUrl(defaultUserSearchParams, props.location));
  }, [props.location.search]);

  return (
    <>
      <PageHeader title={translate('userManagement.home.title')}>
        <div className="button-group ml-auto">
          <Link to={'/users/new'} className="button primary md">
            <Translate contentKey="userManagement.home.createLabel">Create a new user</Translate>
          </Link>
        </div>
      </PageHeader>

      <Flex container alignItems="center" className="mb-1">
        <TabFilter tabs={userSearchTabs()} onChangeTab={onChangeTab} currentTab={params.tab} count={users.count} />

        <div className="button-group">
          <Button size="sm" color="super-light-grey" onClick={() => setShowFilters(true)}>
            <span className="mr-1">{translate('filter.filter')}</span>
            <Icon content="filter" />
          </Button>
        </div>
      </Flex>

      <FilterNav
        showFilters={showFilters}
        params={params}
        onChangeParams={onChangeParams}
        onRemoveParams={onRemoveParams}
        toggleFilters={() => setShowFilters(!showFilters)}
        showFilterBar={true}
      />

      {users.loading && <Loader color={COLORS.PRIMARY} />}
      {users.fetchError && <Alert type="error" message={translate('error.loadingData')} />}
      {!users.loading && !users.fetchError && (
        <>
          {users.users?.length === 0 && translate('global.messages.info.noResults')}
          {users.users?.length > 0 && (
            <TableList className="color-black">
              <TableListHeader items={getUserSearchTableHeader(sort)} sortBy={params.sortBy} sortDirection={params.sortDirection} />
              <TableListBody>
                {users.users.map((item, i) => (
                  <CardUser key={`user-${i}`} user={item} className="card-user" />
                ))}
              </TableListBody>
              <TableListPagination
                activePage={params.currentPage}
                paginate={handlePagination}
                itemsPerPage={params.postPerPage}
                totalItems={users.totalItems}
              />
            </TableList>
          )}
        </>
      )}
    </>
  );
};
