export const ACTION_TYPES = {
  HANDLE_MODAL: 'modal/HANDLE_MODAL',
};

// Define here modal keys
type TModals =
  | 'activateUser'
  | 'deleteUser'
  | 'editAddress'
  | 'deleteAddress'
  | 'selectCategories'
  | 'selectAreas'
  | 'deleteProduct'
  | 'insureeCheck'
  | 'unlinkInsuree';

const initialState: { [key in TModals]: boolean } = {
  activateUser: false,
  deleteUser: false,
  editAddress: false,
  deleteAddress: false,
  selectCategories: false,
  selectAreas: false,
  deleteProduct: false,
  insureeCheck: false,
  unlinkInsuree: false,
};

export type ModalState = Readonly<typeof initialState>;

export default (state: ModalState = initialState, action): ModalState => {
  switch (action.type) {
    case ACTION_TYPES.HANDLE_MODAL:
      return {
        ...state,
        [action.payload.key]: action.payload.state,
      };

    default:
      return state;
  }
};

export const handleModal = (key: TModals, state: boolean) => dispatch => {
  dispatch({
    type: ACTION_TYPES.HANDLE_MODAL,
    payload: { key, state },
  });
};
