import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import LoginPage from './login-page';

import { useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';

const Login = (props: RouteComponentProps) => {
  const { location } = props;
  const authentication = useSelector((state: IRootState) => state.authentication);
  const { from } = (location.state as any) || { from: { pathname: '/', search: location.search } };

  return authentication.isAuthenticated ? <Redirect to={from} /> : <LoginPage />;
};

export default Login;
