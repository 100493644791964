import React, { useState, useEffect } from 'react';
import { Heading } from 'app/shared/components/heading';
import { translate } from 'react-jhipster';
import { useForm } from 'react-hook-form';
import { Form, FormItem, FormError, FormRow } from 'app/shared/components/form/form';
import { Button } from 'app/shared/components/button';
import { Icon } from 'app/shared/icons';

import { Flex } from 'app/shared/components/flex';

import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { updatePassword } from 'app/modules/me/me.reducer';
import { Loader } from 'app/shared/components/loader';
import { validationSchema } from 'app/shared/util/validations.utils';

export const Password = () => {
  const settings = useSelector((state: IRootState) => state.settings);
  const dispatch = useDispatch();

  const defaultFormValues = {
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,
  };

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange', defaultValues: defaultFormValues });

  const [edit, setEdit] = useState<boolean>(false);
  const [clicked, setCLicked] = useState<boolean>(false);

  const onUpdatePassword = data => {
    setCLicked(true);
    dispatch(updatePassword(data));
  };

  const toggleEdit = () => {
    setEdit(!edit);
    reset(defaultFormValues);
  };

  const checkPassword = e => {
    return e === getValues('newPassword');
  };

  useEffect(() => {
    if (!settings.loading && clicked && settings.updateSuccess) {
      setCLicked(false);
      setEdit(false);
    }
  }, [settings]);

  return (
    <div className="Settings-section" id="password">
      <Flex container alignItems="center" className="mb-1">
        <Heading content={translate('global.menu.account.password')} size={15} />
        {!edit && (
          <Button className="ml-auto" size="sm" color="white" onClick={toggleEdit}>
            <Icon content="edit" />
          </Button>
        )}
      </Flex>
      <Form>
        {edit && (
          <>
            <FormRow>
              <FormItem htmlFor="currentPassword" label={translate('global.form.currentpassword.label')}>
                <input
                  autoComplete="off"
                  className={`form-control ${errors.currentPassword ? 'is-invalid' : ''}`}
                  placeholder={translate('global.form.currentpassword.placeholder')}
                  type="password"
                  {...register('currentPassword', validationSchema.password)}
                />
                <FormError error={errors.currentPassword} validation={validationSchema.password} />
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem htmlFor="newPassword" label={translate('global.form.newpassword.label')}>
                <input
                  autoComplete="off"
                  className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`}
                  placeholder={translate('global.form.newpassword.placeholder')}
                  type="password"
                  {...register('newPassword', validationSchema.password)}
                />
                <FormError error={errors.newPassword} validation={validationSchema.password} />
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem htmlFor="secondPassword" label={translate('global.form.confirmpassword.label')}>
                <input
                  autoComplete="off"
                  className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                  name="secondPassword"
                  placeholder={translate('global.form.confirmpassword.placeholder')}
                  type="password"
                  {...register('confirmPassword', {
                    ...validationSchema.confirmPassword,
                    validate: {
                      match: e => checkPassword(e),
                    },
                  })}
                />

                <FormError error={errors.confirmPassword} validation={validationSchema.confirmPassword} />
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem>
                <Flex container alignItems="center">
                  <Button size="md" color="white" onClick={toggleEdit}>
                    {translate('entity.action.cancel')}
                  </Button>
                  <Button
                    className="ml-auto"
                    size="md"
                    color="primary"
                    onClick={handleSubmit(onUpdatePassword)}
                    disabled={!isValid || settings.loading}
                  >
                    {settings.loading ? <Loader /> : translate('entity.action.save')}
                  </Button>
                </Flex>
              </FormItem>
            </FormRow>
          </>
        )}
        {!edit && <>{translate('account.changePassword')}</>}
      </Form>
    </div>
  );
};
