import React from 'react';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';

import Settings from './settings';
import { Switch } from 'react-router-dom';

const Routes = () => {
  return (
    <Switch>
      <ErrorBoundaryRoute path={'/me/settings'} component={Settings} />
      <ErrorBoundaryRoute component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
