import React from 'react';
import { Translate } from 'react-jhipster';
import { Icon } from 'app/shared/icons';
import { Button } from 'app/shared/components/button';
import { useHistory } from 'react-router-dom';
import { Flex } from '../flex';

const Back = () => {
  const history = useHistory();

  return (
    <Flex container alignItems="center">
      <Button size="sm" color="white" onClick={() => history.goBack()}>
        <Icon content="arrowLeft" />
        <Translate contentKey="global.menu.back"> Back </Translate>
      </Button>
    </Flex>
  );
};

export default Back;
