import { imageResize } from 'app/shared/util/image-resize';
import { avatarDto } from 'app/shared/model/user.model';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';

type TFileUpload = {
  target: File;
  size: number;
  type: validationTypes;
  resize?: { width: number; height: number };
};

type TErrorType = 'FILE_TYPE_NOT_VALID' | 'FILE_SIZE_NOT_VALID' | 'FILE_IS_VALID';
type TValidationResponse = { valid: boolean; message: TErrorType; meta: any[] | null };
type TValidateResponse = TValidationResponse;

type validationTypes = 'image' | 'pdf';

const fileTypes = {
  image: ['jpg', 'jpeg', 'png'],
  pdf: ['pdf'],
};

export const handleFileUpload = async (props: TFileUpload) => {
  const validation: TValidateResponse = validateFile({ target: props.target, size: props.size, type: props.type });

  if (!validation.valid) {
    toast.error(translate(`error.fileNotValid.${validation.message}`, { value: validation.meta.join(' ') }));
    return validation;
  }

  let content = null;

  if (props.type === 'image') {
    const image = await imageResize(props.target, props.resize);
    content = image?.split('base64,').pop();
  }

  const data: avatarDto = {
    name: props.target.name,
    fileContent: {
      contents: content,
      contentType: props.target.type,
    },
  };

  return data;
};

export const validateFile = (props: TFileUpload) => {
  const { target, size, type } = props;

  const fileType = target.name.split('.').pop();
  const fileSize = target.size / (1024 * 1024);

  if (!fileTypes[type].includes(fileType)) {
    return { valid: false, message: 'FILE_TYPE_NOT_VALID', meta: fileTypes[type] } as TValidationResponse;
  }
  if (fileSize > size) {
    return { valid: false, message: 'FILE_SIZE_NOT_VALID', meta: [size] } as TValidationResponse;
  }

  return { valid: true, message: 'FILE_IS_VALID', meta: null } as TValidationResponse;
};
