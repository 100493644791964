import React, { useState, useEffect } from 'react';
import './select-inline.scss';

type TSelectInline = {
  options: OptionType[];
  selected?: any;
  onSelect: (option) => void;
  mode: 'SINGLE' | 'MULTIPLE';
  canDeselect?: boolean;
};

type OptionType = {
  label: string;
  value: string | number | boolean;
};

export const SelectInline = (props: TSelectInline) => {
  const { options, onSelect, mode, canDeselect = true } = props;

  const [selected, setSelected] = useState(props.selected);

  const isSelected = value => {
    if (mode === 'SINGLE') {
      return selected === value;
    } else {
      return selected.some(item => item.value === value);
    }
  };

  const onOptionClick = (option: OptionType) => {
    if (mode === 'SINGLE') {
      const s = isSelected(option.value) && canDeselect ? null : option.value;
      setSelected(s);
      onSelect(s);
    } else {
      const s = isSelected(option.value) ? selected.filter(item => item !== option.value) : [...selected, option.value];
      setSelected(s);
      onSelect(s);
    }
  };

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  return (
    <div className="Inline-select">
      {options?.map((option, i) => (
        <div
          key={i}
          className={`Inline-select-option ${isSelected(option.value) ? 'selected' : ''} `}
          onClick={() => onOptionClick(option)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};
