type TSize = {
  width: number;
  height: number;
};

export const imageResize = async (file: File, size?: TSize) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const b64 = (await readAsDataURL(file)) as string;
  const image = (await imageFromB64(b64)) as ImageBitmap;

  const imgSize = Math.min(image.width, image.height);

  const left = size ? (image.width - imgSize) / 2 : 0;
  const top = size ? (image.height - imgSize) / 2 : 0;

  const width = size ? size.width : image.width;
  const height = size ? size.height : image.height;

  canvas.width = width;
  canvas.height = height;

  context.drawImage(image, left, top, imgSize, imgSize, 0, 0, width, height);

  return canvas.toDataURL(file.type);
};

function readAsDataURL(file: File) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = function () {
      resolve(fr.result);
    };
    fr.readAsDataURL(file);
  });
}

function imageFromB64(b64: string) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onerror = reject;
    image.onload = function () {
      resolve(image);
    };
    image.src = b64;
  });
}
