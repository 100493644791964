export type TAuthorities = 'ROLE_SYSTEM' | 'ROLE_ADMIN' | 'ROLE_USER';
export type TUserAccountType = 'BROKER' | 'AGENCY' | 'PHYSICAL' | 'JURIDICAL';

export interface IMe {
  id: number;
  login: string;
  email: string;
  activated: boolean;
  langKey: string;
  verified: boolean;
  overDraft: boolean;
  authorities: TAuthorities[];
  account: TAccount;
  address: IAddress;
  createdDate: string;
}

export interface IUser {
  id: number;
  login: string;
  email: string;
  activated: boolean;
  langKey: string;
  verified: boolean;
  overDraft: boolean;
  authorities: TAuthorities[];
  account: TAccount;
  address: IAddress;
  createdDate: string;
}

export type TAccount = {
  id: number;
  type: TUserAccountType;
  firstName: string;
  lastName: string;
  companyName: string;
  rui: string;
  vat: string;
  taxCode: number;
  landLine: number;
  mobileLine: number;
  payGatewayId: string;
  avatar: avatarDto;
};

export type avatarDto = {
  fileContent: TAvatarContent;
  id?: number;
  name: string;
};

export type TAvatarContent = {
  contents: string;
  contentType: string;
  id?: number;
};

type AddressType = 'HEAD_QUARTER' | 'OFFICE' | 'PRIVATE';

export interface IAddress {
  id: number;
  type: AddressType;
  street: string;
  number: string;
  town: string;
  country: string;
  province: string;
  zipCode: string;
  preferred: boolean;
}

export const defaultAddress: Readonly<IAddress> = {
  id: null,
  type: 'PRIVATE',
  street: null,
  number: null,
  town: null,
  country: null,
  province: null,
  zipCode: null,
  preferred: null,
};

export const defaultAccount: Readonly<TAccount> = {
  id: null,
  type: null,
  firstName: null,
  lastName: null,
  companyName: null,
  rui: null,
  vat: null,
  taxCode: null,
  landLine: null,
  mobileLine: null,
  payGatewayId: null,
  avatar: null,
};

export const defaultUser: Readonly<IUser> = {
  id: null,
  login: null,
  email: null,
  activated: false,
  langKey: null,
  verified: false,
  overDraft: false,
  authorities: ['ROLE_USER'],
  account: defaultAccount,
  address: defaultAddress,
  createdDate: null,
};

export interface IWallet {
  id: number;
  name: string;
  overdraftAmount: number;
  usedAmount: number;
  active: boolean;
  default: boolean;
}

export const defaultWallet: Readonly<IWallet> = {
  id: null,
  name: null,
  overdraftAmount: null,
  usedAmount: null,
  active: null,
  default: null,
};

export interface IInsuree {
  id: number;
  email: string;
  account: TAccount;
  address: IAddress;
  createdDate?: string;
}

export const defaultInsuree: Readonly<IInsuree> = {
  id: null,
  email: null,
  account: defaultAccount,
  address: null,
};

export function isUser(u: any): u is IUser {
  return 'overdraft' in u;
}
