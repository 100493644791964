import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/me/login/login';
import Register from 'app/modules/me/register/register';
import Verify from 'app/modules/me/verify';
import PasswordResetInit from 'app/modules/me/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/me/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/me/login/logout';
import Home from 'app/modules/home/home';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';

import Me from 'app/modules/me';
import Users from './modules/users';
import Products from './modules/products';
import Insurees from './modules/insurees';
import Categories from './modules/categories';
import Areas from './modules/areas';

// const Account = Loadable({
//   loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
//   loading: () => <div>loading ...</div>,
// });

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>,
});

const Routes = () => {
  return (
    <Switch>
      <ErrorBoundaryRoute path="/login" component={Login} />
      <ErrorBoundaryRoute path="/logout" component={Logout} />
      <ErrorBoundaryRoute path="/register" component={Register} />
      <ErrorBoundaryRoute path="/me/verifyEmail/:key?" component={Verify} />
      <ErrorBoundaryRoute path="/me/password/reset/request" component={PasswordResetInit} />
      <ErrorBoundaryRoute path="/me/password/reset/finish/:key?" component={PasswordResetFinish} />
      <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.SYSTEM, AUTHORITIES.ADMIN]} />
      <PrivateRoute path={'/users'} component={Users} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.SYSTEM]} />
      <PrivateRoute path={'/insurees'} component={Insurees} />
      <PrivateRoute path={'/products'} component={Products} />
      <PrivateRoute path="/me" component={Me} hasAnyAuthorities={[AUTHORITIES.SYSTEM, AUTHORITIES.ADMIN, AUTHORITIES.USER]} />
      <PrivateRoute path="/categories" exact component={Categories} />
      <PrivateRoute path="/areas" exact component={Areas} />
      <PrivateRoute path="/" exact component={Home} />
      <ErrorBoundaryRoute component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
