import React, { useEffect, useState } from 'react';
import { Heading } from 'app/shared/components/heading';
import { translate } from 'react-jhipster';
import { useForm } from 'react-hook-form';
import { Form, FormItem, FormError, FormRow, ReadOnly } from 'app/shared/components/form/form';
import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Select } from 'app/shared/components/select';
import { Icon } from 'app/shared/icons';
import { languages, locales } from 'app/config/translation';

import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { updateMe } from 'app/modules/me/me.reducer';
import { Loader } from 'app/shared/components/loader';
import { validationSchema } from 'app/shared/util/validations.utils';

export const Main = () => {
  const dispatch = useDispatch();
  const me = useSelector((state: IRootState) => state.authentication.me);
  const settings = useSelector((state: IRootState) => state.settings);
  const [clicked, setCLicked] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: me,
  });

  const [edit, setEdit] = useState<boolean>(false);

  const optionLanguages = locales.map(locale => ({ label: languages[locale].name, value: locale }));

  const onUpdateAccount = data => {
    setCLicked(true);
    dispatch(updateMe(data));
  };

  const onLanguageChange = option => {
    setValue('langKey', option.value, { shouldValidate: true });
  };

  const toggleEdit = () => {
    setEdit(!edit);
    reset(me);
  };

  useEffect(() => {
    if (!settings.loading && clicked && settings.updateSuccess) {
      setCLicked(false);
      setEdit(false);
    }
  }, [settings]);

  return (
    <div className="Settings-section">
      <Flex container alignItems="center" className="mb-1">
        <Heading content={translate('global.menu.account.general')} size={15} />
        {!edit && (
          <Button className="ml-auto" size="sm" color="white" onClick={toggleEdit}>
            <Icon content="edit" />
          </Button>
        )}
      </Flex>
      <Form>
        <FormRow col="2">
          <FormItem htmlFor="username" label={translate('userManagement.username')} className="col col-50 col-xs-100">
            {edit ? (
              <>
                <input
                  className={`form-control ${errors.login ? 'is-invalid' : ''}`}
                  id="username"
                  type="text"
                  placeholder={translate('userManagement.username')}
                  {...register('login', validationSchema.userName)}
                />
                <FormError error={errors.login} validation={validationSchema.userName} />
              </>
            ) : (
              <ReadOnly content={me.login} />
            )}
          </FormItem>

          <FormItem htmlFor="langKey" label={translate('userManagement.langKey')} className="col col-50 col-xs-100">
            {edit ? (
              <>
                <Select
                  options={optionLanguages}
                  onSelect={onLanguageChange}
                  selected={me.langKey}
                  showReset={false}
                  isPlaceholderIcon={false}
                />
                <input type="hidden" {...register('langKey')} defaultValue={me.langKey} />
              </>
            ) : (
              <ReadOnly content={optionLanguages.filter(lang => lang.value === me.langKey)[0].label} />
            )}
          </FormItem>
        </FormRow>

        {edit && (
          <FormRow>
            <FormItem>
              <Flex container alignItems="center">
                <Button size="md" color="white" onClick={toggleEdit}>
                  {translate('entity.action.cancel')}
                </Button>
                <Button className="ml-auto" size="md" color="primary" onClick={handleSubmit(onUpdateAccount)} disabled={!isValid}>
                  {settings.loading ? <Loader /> : translate('entity.action.save')}
                </Button>
              </Flex>
            </FormItem>
          </FormRow>
        )}
      </Form>
    </div>
  );
};
