import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Icon } from 'app/shared/icons';
import { IAddress } from 'app/shared/model/user.model';
import { add } from 'lodash';
import React from 'react';
import { translate } from 'react-jhipster';

type TAddressCardProps = {
  address: IAddress;
  onEditAddress: (address: IAddress) => void;
  onDeleteAddress: (address: IAddress) => void;
};

export const AddressCard = (props: TAddressCardProps) => {
  const { address, onEditAddress, onDeleteAddress } = props;

  return (
    <Flex container alignItems="center" className="address-card">
      <Flex container flexDirection="column">
        <div>
          <b>{translate(`userManagement.address.type.${address.type}`)}</b>
        </div>
        <div className="mt-05">
          {address.street} {address.number}, {address.zipCode} {address.province} {address.town} - {address.country}
        </div>
      </Flex>

      <Flex container className="ml-auto">
        <Button size="sm" color="white" onClick={() => onEditAddress(address)}>
          <Icon content="edit" />
        </Button>

        <Button size="sm" color="white" onClick={() => onDeleteAddress(address)}>
          <Icon content="bin" />
        </Button>
      </Flex>
    </Flex>
  );
};
