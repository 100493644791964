import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IGetAreas } from 'app/shared/model/categories-zones.model';

export const ACTION_TYPES = {
  FETCH_AREAS: 'areas/FETCH_AREAS',
  RESET: 'areas/RESET',
};

const initialState = {
  loading: false,
  data: null as IGetAreas,
  fetchSuccess: false,
  fetchError: false,
};

export type AreasState = Readonly<typeof initialState>;

// Reducer
export default (state: AreasState = initialState, action): AreasState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_AREAS):
      return {
        ...state,
        fetchSuccess: false,
        loading: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_AREAS):
      return {
        ...state,
        loading: false,
        fetchSuccess: false,
        fetchError: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_AREAS):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
        data: action.payload.data,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
export const getAreas = () => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.FETCH_AREAS,
    payload: axios.get(`api/areas`),
  });
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
