import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import InsureesSearch from './search';
import InsureesView from './view';
import InsureesNew from './new';

const Routes = () => {
  return (
    <>
      <Switch>
        <ErrorBoundaryRoute exact path={'/insurees/new'} component={InsureesNew} />
        <ErrorBoundaryRoute exact path={'/insurees/:id'} component={InsureesView} />
        <ErrorBoundaryRoute path="/insurees" component={InsureesSearch} />
      </Switch>
    </>
  );
};

export default Routes;
