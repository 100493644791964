import React from 'react';

type TPageHeader = {
  title: string;
  children?: any;
  className?: string;
};

export const PageHeader = (props: TPageHeader) => {
  const { title, children, className } = props;

  return (
    <div className={`Page-header ${className || ''} `}>
      <h1> {title} </h1>
      {children}
    </div>
  );
};
