import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  VERIFY_ACCOUNT: 'me/VERIFY_ACCOUNT',
  RESET: 'me/RESET',
};

const initialState = {
  verificationSuccess: false,
  verificationFailure: false,
};

export type VerifyState = Readonly<typeof initialState>;

// Reducer
export default (state: VerifyState = initialState, action): VerifyState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.VERIFY_ACCOUNT):
      return {
        ...state,
      };
    case FAILURE(ACTION_TYPES.VERIFY_ACCOUNT):
      return {
        ...state,
        verificationFailure: true,
      };
    case SUCCESS(ACTION_TYPES.VERIFY_ACCOUNT):
      return {
        ...state,
        verificationSuccess: true,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
export const activateAction = key => ({
  type: ACTION_TYPES.VERIFY_ACCOUNT,
  payload: axios.get('api/me/verifyEmail?key=' + key),
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
