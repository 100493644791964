import React from 'react';
import dayjs from 'dayjs';

type TTextDate = {
  content: string | number | Date;
  type: 'text' | 'date';
  format?: TDateFormat;
};

type TDateFormat =
  | 'APP_DATE_FORMAT'
  | 'APP_LOCAL_DATE_FORMAT'
  | 'APP_LOCAL_DATETIME_FORMAT'
  | 'APP_LOCAL_DATETIME_FORMAT_Z'
  | 'APP_WHOLE_NUMBER_FORMAT'
  | 'APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT'
  | 'DATETIME';

type TDateFromats = {
  [key in TDateFormat]: string;
};

const FORMATS: TDateFromats = {
  APP_DATE_FORMAT: 'DD/MM/YY HH:mm',
  APP_LOCAL_DATE_FORMAT: 'DD MMM YYYY',
  APP_LOCAL_DATETIME_FORMAT: 'YYYY-MM-DDTHH:mm',
  APP_LOCAL_DATETIME_FORMAT_Z: 'YYYY-MM-DDTHH:mm Z',
  APP_WHOLE_NUMBER_FORMAT: '0,0',
  APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT: '0,0.[00]',
  DATETIME: 'DD MMM YYYY HH:mm',
};

export const Text = (props: TTextDate) => {
  const { content, type, format } = props;

  switch (type) {
    case 'text':
      return <> {content} </>;

    case 'date':
      return <>{dayjs(content).format(FORMATS[format])}</>;

    default:
      return <> {content} </>;
  }
};
