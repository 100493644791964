import { translate } from 'react-jhipster';
import { TTableListHeaderItem } from 'app/shared/components/table-list';
import { IUser } from 'app/shared/model/user.model';

export const userSearchTabs: () => { [key in TSearchTabs]: string } = () => {
  return {
    ALL: translate('userManagement.tabs.ALL'),
    ACTIVATED: translate('userManagement.tabs.ACTIVATED'),
    VERIFIED: translate('userManagement.tabs.PENDING'),
    UNVERIFIED: translate('userManagement.tabs.UNVERIFIED'),
  };
};

export const getUserStatus = (user: IUser) => {
  return !user?.verified ? 'UNVERIFIED' : user.activated ? 'ACTIVATED' : 'PENDING';
};

export type TOrder = 'ASC' | 'DESC';
export type TSort = 'login' | 'email' | 'rui' | 'createdDate';
export type TSearchTabs = 'ALL' | 'ACTIVATED' | 'VERIFIED' | 'UNVERIFIED';

export const defaultUserSearchParams = {
  postPerPage: 10,
  currentPage: 0,
  sortBy: 'createdDate' as TSort,
  sortDirection: 'ASC' as TOrder,
  tab: 'ALL' as TSearchTabs,
  name: null,
  login: null,
  email: null,
  rui: null,
  type: null,
  overDraft: null,
};

export type TdefaultUserSearchParams = Readonly<typeof defaultUserSearchParams>;

export const genUserSearchParams = (params: TdefaultUserSearchParams) => {
  let isActive = null;
  let isVerified = null;

  if (params.tab !== 'ALL') {
    if (params.tab === 'UNVERIFIED') {
      isActive = null;
      isVerified = false;
    } else {
      isActive = params.tab === 'ACTIVATED';
      isVerified = true;
    }
  }

  return {
    currentPage: params.currentPage,
    postPerPage: params.postPerPage,
    sortBy: [params.sortBy],
    sortDirection: params.sortDirection,
    activated: isActive,
    verified: isVerified,
    name: params.name,
    login: params.login,
    email: params.email,
    rui: params.rui,
    type: params.type,
    hasOverdraft: params.overDraft === 'YES' ? true : params.overDraft === 'NO' ? false : null,
    areas: [],
    categories: [],
  };
};

export const genUserCountSearchParams = (params: TdefaultUserSearchParams) => {
  return {
    name: params.name,
    login: params.login,
    email: params.email,
    rui: params.rui,
    type: params.type,
    hasOverdraft: params.overDraft === 'YES' ? true : params.overDraft === 'NO' ? false : null,
    areas: [],
    categories: [],
  };
};

export const getUserSearchTableHeader = sort => {
  return [
    {},
    {
      id: 'login',
      name: translate('userManagement.username'),
      onClick: sort('login'),
    },
    {
      name: translate('userManagement.type'),
    },
    {
      id: 'createdDate',
      name: translate('userManagement.createdDate'),
      onClick: sort('createdDate'),
    },
    {
      name: translate('userManagement.status'),
    },
    {
      name: translate('userManagement.rui'),
    },
    {
      name: translate('userManagement.categories'),
    },
    {
      name: translate('userManagement.areas'),
    },
    {
      name: translate('userManagement.overdraft'),
    },
    {},
  ] as TTableListHeaderItem[];
};
