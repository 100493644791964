import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { getSession } from './authentication';

export const ACTION_TYPES = {
  REFRESH_TOKEN: 'me/REFRESH_TOKEN',
};

type TinitialState = {
  loading: boolean;
  refreshGrants: boolean;
};

const initialState: TinitialState = {
  loading: false,
  refreshGrants: false,
};

export type RefreshTokenState = Readonly<typeof initialState>;

// Reducer

export default (state: RefreshTokenState = initialState, action): RefreshTokenState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.REFRESH_TOKEN):
      return {
        loading: true,
        refreshGrants: false,
      };
    case FAILURE(ACTION_TYPES.REFRESH_TOKEN):
      return {
        loading: false,
        refreshGrants: false,
      };

    case SUCCESS(ACTION_TYPES.REFRESH_TOKEN):
      return {
        loading: false,
        refreshGrants: true,
      };

    default:
      return state;
  }
};

export const refreshToken: () => void = () => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.REFRESH_TOKEN,
    payload: axios.post('api/me/refreshToken'),
  });
  await dispatch(getSession());
};
