import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { translate } from 'react-jhipster';
import { Form, FormError, FormItem, FormRow } from 'app/shared/components/form/form';
import { Select } from 'app/shared/components/select';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { IRootState } from 'app/shared/reducers';
import { useSelector } from 'react-redux';
import it from 'date-fns/locale/it';
import { SelectInline } from '../select-inline';
import { optionsOverdraft, optionsTypeUser, optionsTypeInsuree } from 'app/shared/util/select.utils';
import { useForm } from 'react-hook-form';
import { Button } from '../button';
import { validationSchema } from 'app/shared/util/validations.utils';

type FilterPropsType = {
  params: any;
  onFilterParams: (params: any) => void;
};

const Filters = (props: FilterPropsType) => {
  const { params, onFilterParams } = props;

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: params,
  });

  registerLocale('it', it);
  const locale = useSelector((state: IRootState) => state.locale.currentLocale);

  const onUpdateParams = data => {
    onFilterParams(data);
  };

  const onSelectChange = (key, value) => {
    setValue(key, value, { shouldValidate: true });
  };

  useEffect(() => {
    reset(params);
  }, [params]);

  if (!params) {
    return <></>;
  }

  return (
    <>
      <PerfectScrollbar
        options={{
          wheelPropagation: false,
        }}
      >
        <Form>
          <FormRow>
            {'name' in params && (
              <FormItem htmlFor="name" label={translate('filter.labels.name')}>
                <input
                  type="text"
                  placeholder={translate('filter.labels.name')}
                  name="name"
                  defaultValue={params.name}
                  {...register('name')}
                />
              </FormItem>
            )}

            {'login' in params && (
              <FormItem htmlFor="login" label={translate('filter.labels.login')}>
                <input
                  type="text"
                  placeholder={translate('filter.labels.login')}
                  name="login"
                  defaultValue={params.login}
                  {...register('login')}
                />
              </FormItem>
            )}

            {'email' in params && (
              <FormItem htmlFor="email" label={translate('filter.labels.email')}>
                <input
                  autoComplete="off"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  name="email"
                  placeholder={translate('filter.labels.email')}
                  {...register('email')}
                  defaultValue={params?.email}
                />
              </FormItem>
            )}

            {'rui' in params && (
              <FormItem htmlFor="rui" label={translate('filter.labels.rui')}>
                <input type="text" placeholder={translate('filter.labels.rui')} name="rui" defaultValue={params.rui} {...register('rui')} />
              </FormItem>
            )}

            {'taxCode' in params && (
              <FormItem htmlFor="taxCode" label={translate('filter.labels.taxCode')}>
                <input
                  type="text"
                  placeholder={translate('filter.labels.taxCode')}
                  name="taxCode"
                  defaultValue={params.taxCode}
                  {...register('taxCode')}
                />
              </FormItem>
            )}

            {'type' in params && (
              <FormItem htmlFor="type" label={translate('filter.labels.type')}>
                <SelectInline
                  options={optionsTypeUser()}
                  selected={params.type || 'ALL'}
                  mode="SINGLE"
                  onSelect={value => onSelectChange('type', value === 'ALL' ? null : value)}
                  canDeselect={false}
                />
              </FormItem>
            )}

            {'typeInsuree' in params && (
              <FormItem htmlFor="typeInsuree" label={translate('filter.labels.type')}>
                <SelectInline
                  options={optionsTypeInsuree()}
                  selected={params.typeInsuree || 'ALL'}
                  mode="SINGLE"
                  onSelect={value => onSelectChange('typeInsuree', value === 'ALL' ? null : value)}
                  canDeselect={false}
                />
              </FormItem>
            )}

            {'overDraft' in params && (
              <FormItem htmlFor="type" label={translate('filter.labels.overDraft')}>
                <SelectInline
                  options={optionsOverdraft()}
                  selected={params.overDraft || 'ALL'}
                  mode="SINGLE"
                  onSelect={value => onSelectChange('overDraft', value === 'ALL' ? null : value)}
                  canDeselect={false}
                />
              </FormItem>
            )}

            {/* {'from' in params && (
              <FormItem htmlFor="from" label={translate('filter.labels.dateFrom')}>
                <DatePicker
                  locale={locale}
                  className=""
                  name="from"
                  placeholderText={translate('filter.labels.dateFrom')}
                  dateFormat="dd/MM/yyyy HH:mm"
                  autoComplete="off"
                  selected={params.from}
                  maxDate={params.to}
                  showTimeSelect
                  onChange={date => updateParams('from', date)}
                  defaultValue={params.from || ''}
                />
              </FormItem>
            )}
            {'to' in params && (
              <FormItem htmlFor="to" label={translate('filter.labels.dateTo')}>
                <DatePicker
                  locale={locale}
                  className=""
                  name="to"
                  placeholderText={translate('filter.labels.dateTo')}
                  dateFormat="dd/MM/yyyy HH:mm"
                  autoComplete="off"
                  selected={params.to}
                  minDate={params.from}
                  showTimeSelect
                  onChange={date => updateParams('to', date)}
                  defaultValue={params.to || ''}
                />
              </FormItem>
            )} */}
          </FormRow>
        </Form>
      </PerfectScrollbar>

      <div className="filter-form-footer">
        <Button size="md" color="primary" onClick={handleSubmit(onUpdateParams)}>
          {translate('filter.applyFilters')}
        </Button>
      </div>
    </>
  );
};

export default Filters;
