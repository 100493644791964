import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { Link } from 'react-router-dom';
import { clearInsuree, getInsuree } from '../insurees.reducer';
import { useParams } from 'react-router-dom';

import { Translate, translate } from 'react-jhipster';

import { Loader } from 'app/shared/components/loader';
import { Alert } from 'app/shared/components/alert';

import { PageHeader } from 'app/shared/components/page';
import { Form, FormItem, FormRow, ReadOnly } from 'app/shared/components/form/form';
import { Text } from 'app/shared/components/text';
import { Back } from 'app/shared/components/back';
import { Dropdown, DropdownContainer, DropdownItem, DropdownToogle } from 'app/shared/components/dropdown';
import { Icon } from 'app/shared/icons';
import { Button } from 'app/shared/components/button';
import { Account } from 'app/shared/forms/account/account';
import { Flex } from 'app/shared/components/flex';
import { Heading } from 'app/shared/components/heading';
import { COLORS } from 'app/config/constants';
import MainForm from 'app/shared/forms/main';
import { AvatarUpload } from 'app/shared/components/avatar-upload';
import Address from 'app/shared/forms/address';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import UnlinkInsuree from '../unlink';
import { Modal } from 'app/shared/components/modal';
import { handleModal } from 'app/shared/reducers/modals';

export const InsureesView = () => {
  const { id }: any = useParams();

  const dispatch = useDispatch();
  const insureesState = useSelector((state: IRootState) => state.insurees);
  const authentication = useSelector((state: IRootState) => state.authentication);
  const modalState = useSelector((state: IRootState) => state.modal);
  const isRoleUser = hasAnyAuthority(authentication.me.authorities, ['ROLE_USER']);

  const { insuree } = insureesState;

  useEffect(() => {
    dispatch(getInsuree(id));

    return () => {
      dispatch(clearInsuree());
    };
  }, []);

  return (
    <>
      <div className="container xl">
        <Back />

        <div className="Settings">
          <PageHeader title={translate('insurees.view.title')}>
            {insuree && isRoleUser && (
              <>
                <Flex container alignItems="center" className="ml-auto">
                  <Dropdown className="ml-1">
                    <DropdownToogle>
                      <Button size="md" color="white">
                        <Icon content="dotsHor" />
                      </Button>
                    </DropdownToogle>
                    <DropdownContainer>
                      <DropdownItem onClick={() => dispatch(handleModal('unlinkInsuree', true))}>
                        <Translate contentKey="entity.action.remove">remove</Translate>
                      </DropdownItem>
                    </DropdownContainer>
                  </Dropdown>
                </Flex>
              </>
            )}
          </PageHeader>

          {insureesState.loading && <Loader color={COLORS.PRIMARY} />}
          {insureesState.errorMessage && <Alert type="error" message={translate('error.loadingData')} />}

          {!insureesState.loading && !insureesState.errorMessage && insuree && (
            <div className="Settings-container">
              <div className="Settings-left-col">
                <AvatarUpload account={insuree.account} />
              </div>
              <div className="Settings-right-col">
                <MainForm user={insuree} type="INSUREE" />
                <Account account={insuree.account} type="INSUREE" canEdit={true} />
                <Address email={insuree.email} type="INSUREE" />
              </div>
            </div>
          )}
        </div>
      </div>

      {modalState.unlinkInsuree && (
        <Modal title={translate('insurees.unlink.title')} size="xs" close={() => dispatch(handleModal('unlinkInsuree', false))}>
          <UnlinkInsuree id={insuree.id} />
        </Modal>
      )}
    </>
  );
};
