import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Heading } from 'app/shared/components/heading';
import { Modal } from 'app/shared/components/modal';
import { Icon } from 'app/shared/icons';
import { defaultAddress, IAddress } from 'app/shared/model/user.model';
import { IRootState } from 'app/shared/reducers';
import { handleModal } from 'app/shared/reducers/modals';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { AddressCard } from './address-card';
import { AddressEdit } from './edit/address-edit';
import { getAddresses } from './address.reducer';
import { AddressDelete } from './delete/address-delete';
import { Loader } from 'app/shared/components/loader';
import { COLORS } from 'app/config/constants';
import { Alert } from 'app/shared/components/alert';

type TaddressProps = {
  email: string;
  type: 'USER' | 'INSUREE';
};

export const Address = (props: TaddressProps) => {
  const dispatch = useDispatch();
  const modal = useSelector((state: IRootState) => state.modal);
  const addressState = useSelector((state: IRootState) => state.addresses);

  const [currentAddress, setCurrentAddress] = useState<IAddress>(null);

  const onAddAddress = () => {
    setCurrentAddress(defaultAddress);
    dispatch(handleModal('editAddress', true));
  };

  const onEditAddress = (address: IAddress) => {
    setCurrentAddress(address);
    dispatch(handleModal('editAddress', true));
  };

  const onDeleteAddress = (address: IAddress) => {
    setCurrentAddress(address);
    dispatch(handleModal('deleteAddress', true));
  };

  useEffect(() => {
    dispatch(getAddresses());
  }, []);

  return (
    <>
      <div className="Settings-section">
        <Flex container alignItems="center" className="mb-1">
          <Heading content={translate('userManagement.address.title')} size={15} />

          {(props.type === 'USER' || (addressState.addresses?.length === 0 && props.type === 'INSUREE')) && (
            <Button className="ml-auto" size="sm" color="white" onClick={onAddAddress}>
              <Icon content="add" />
            </Button>
          )}
        </Flex>

        {addressState.loading && <Loader color={COLORS.PRIMARY} />}
        {addressState.fetchError && <Alert type="error" message={translate('error.loadingData')} />}
        {addressState.addresses && (
          <>
            {addressState.addresses?.length === 0 && translate('global.messages.info.noResults')}
            {addressState.addresses?.length > 0 &&
              addressState.addresses.map(address => (
                <AddressCard key={address.id} address={address} onEditAddress={onEditAddress} onDeleteAddress={onDeleteAddress} />
              ))}
          </>
        )}
      </div>

      {modal.editAddress && (
        <Modal
          title={translate(`userManagement.address.${currentAddress.id ? 'editAddress' : 'createAddress'}`)}
          close={() => dispatch(handleModal('editAddress', false))}
        >
          <AddressEdit email={props.email} address={currentAddress} />
        </Modal>
      )}

      {modal.deleteAddress && (
        <Modal
          title={translate('userManagement.address.deleteAddress')}
          size="xs"
          close={() => dispatch(handleModal('deleteAddress', false))}
        >
          <AddressDelete address={currentAddress} />
        </Modal>
      )}
    </>
  );
};
