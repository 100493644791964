import { IUser } from 'app/shared/model/user.model';
import React, { useEffect, useRef, useState } from 'react';
import { Form, FormItem, FormRow } from 'app/shared/components/form';
import { Translate, translate } from 'react-jhipster';
import { Flex } from 'app/shared/components/flex';
import { Button } from 'app/shared/components/button';
import { Loader } from 'app/shared/components/loader';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { updateUser } from '../../users.reducer';
import { getUserStatus } from '../../user.utils';
import { handleModal } from 'app/shared/reducers/modals';

type TActivateModal = {
  user: IUser;
};

export const ActivateUser = (props: TActivateModal) => {
  const { user } = props;
  const status = getUserStatus(user);

  const dispatch = useDispatch();
  const userState = useSelector((state: IRootState) => state.users);

  const onActivate = () => {
    dispatch(updateUser({ ...user, activated: !user.activated }));
  };

  return (
    <>
      <Form>
        <FormRow>
          <FormItem>
            {translate(`userManagement.${status === 'PENDING' ? 'activate' : 'deactivate'}.question`, { login: user.login })}
          </FormItem>
        </FormRow>

        <FormRow>
          <FormItem>
            <Flex container>
              <Button size="md" color="white" onClick={() => dispatch(handleModal('activateUser', false))}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button size="md" color="primary" className="ml-auto" disabled={userState.updating} onClick={onActivate}>
                {!userState.loading ? translate('entity.action.confirm') : <Loader />}
              </Button>
            </Flex>
          </FormItem>
        </FormRow>
      </Form>
    </>
  );
};
