import React from 'react';
import { locales } from 'app/config/translation';
import { Button } from 'app/shared/components/button';
import { Storage } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { setLocale } from 'app/shared/reducers/locale';

export const MenuLanguage = () => {
  const dispatch = useDispatch();
  const currentLocale = useSelector((state: IRootState) => state.locale.currentLocale);

  const handleLocaleChange = (langKey: 'it' | 'en') => {
    Storage.session.set('locale', langKey);

    dispatch(setLocale(langKey));
  };

  const language = locales.filter(lang => lang !== currentLocale)[0];

  return (
    <Button size="md" color="white" onClick={() => handleLocaleChange(language as any)}>
      {language}
    </Button>
  );
};
