import { COLORS } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Alert } from 'app/shared/components/alert';
import { Flex } from 'app/shared/components/flex';
import { Loader } from 'app/shared/components/loader';
import { PageHeader } from 'app/shared/components/page';
import { TabFilter } from 'app/shared/components/tab-filter';
import { TableList, TableListBody, TableListHeader, TableListPagination } from 'app/shared/components/table-list';
import { IRootState } from 'app/shared/reducers';
import { getParamsFromUrl, setParamsToUrl } from 'app/shared/util/pagination-utils';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { getProducts, getProductsCounts } from '../products.reducer';
import {
  defaultProductsSearchParams,
  genProductCountSearchParams,
  genProductSearchParams,
  getProductsSearchTableHeader,
  productsSearchTabs,
  TdefaultProductsSearchParams,
  TSearchTabs,
  TSort,
} from '../products.utils';
import { ProductsCardAdmin } from './products-card-admin';
import { ProductsCardUser } from './products-card-user';

export const ProductsSearch = (props: RouteComponentProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const products = useSelector((state: IRootState) => state.products);
  const authentication = useSelector((state: IRootState) => state.authentication);
  const isRoleAdmin = hasAnyAuthority(authentication.me.authorities, ['ROLE_ADMIN']);

  const defaultParams: TdefaultProductsSearchParams = isRoleAdmin
    ? defaultProductsSearchParams
    : { ...defaultProductsSearchParams, tab: 'PUBLISHED' };

  const [params, setParams] = useState<TdefaultProductsSearchParams>(getParamsFromUrl(defaultParams, props.location));

  const onChangeTab = (tab: TSearchTabs) => {
    setParams({
      ...params,
      tab,
    });
  };

  const handlePagination = (currentPage: number) => {
    setParams({
      ...params,
      currentPage,
    });
  };

  const sort = (param: TSort) => () => {
    setParams({
      ...params,
      sortDirection: params.sortDirection === 'ASC' ? 'DESC' : 'ASC',
      sortBy: param,
    });
  };

  useEffect(() => {
    dispatch(getProducts(genProductSearchParams(params)));
    dispatch(getProductsCounts(genProductCountSearchParams(params)));
    setParamsToUrl(params, history);
  }, [params.currentPage, params.sortDirection, params.sortBy, params.tab]);

  useEffect(() => {
    setParams(getParamsFromUrl(defaultParams, props.location));
  }, [props.location.search]);

  return (
    <>
      <PageHeader title={translate('products.search.title')}>
        {isRoleAdmin && (
          <div className="button-group ml-auto">
            <Link to={'/products/new'} className="button primary md">
              <Translate contentKey="products.search.addNewProduct">Create a new product</Translate>
            </Link>
          </div>
        )}
      </PageHeader>
      {isRoleAdmin && (
        <Flex container alignItems="center" className="mb-1">
          <TabFilter tabs={productsSearchTabs()} onChangeTab={onChangeTab} currentTab={params.tab} count={products.count} />
        </Flex>
      )}

      {products.loading && <Loader color={COLORS.PRIMARY} />}
      {!products.loading && products.fetchError && <Alert type="error" message={translate('error.loadingData')} />}
      {!products.loading && !products.fetchError && (
        <>
          {products.products?.length === 0 && translate('global.messages.info.noResults')}
          {products.products?.length > 0 && (
            <>
              {isRoleAdmin ? (
                <TableList className="color-black">
                  <TableListHeader items={getProductsSearchTableHeader(sort)} sortBy={params.sortBy} sortDirection={params.sortDirection} />
                  <TableListBody>
                    {products.products.map((product, i) => (
                      <ProductsCardAdmin key={`user-${i}`} product={product} className="card-product" />
                    ))}
                  </TableListBody>
                </TableList>
              ) : (
                <div className="Products-grid">
                  {products.products.map((product, i) => (
                    <ProductsCardUser key={`user-${i}`} product={product} />
                  ))}
                </div>
              )}
              <TableListPagination
                activePage={params.currentPage}
                paginate={handlePagination}
                itemsPerPage={params.postPerPage}
                totalItems={products.totalItems}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
