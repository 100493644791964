import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IAddress } from 'app/shared/model/user.model';
import { handleModal } from 'app/shared/reducers/modals';
import { translate } from 'react-jhipster';

export const ACTION_TYPES = {
  FETCH_ADDRESSES: 'address/FETCH_ADDRESSES',
  CREATE_ADDRESS: 'address/CREATE_ADDRESS',
  UPDATE_ADDRESS: 'address/UPDATE_ADDRESS',
  DELETE_ADDRESS: 'address/DELETE_ADDRESS',
};

// const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

const initialState = {
  loading: false,
  addresses: null as IAddress[],
  errorMessage: (null as unknown) as string, // Errors returned from server side
  fetchSuccess: false,
  fetchError: false,
};

export type AddressesState = Readonly<typeof initialState>;

// Reducer

export default (state: AddressesState = initialState, action): AddressesState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ADDRESSES):
    case REQUEST(ACTION_TYPES.CREATE_ADDRESS):
    case REQUEST(ACTION_TYPES.UPDATE_ADDRESS):
    case REQUEST(ACTION_TYPES.DELETE_ADDRESS):
      return {
        ...state,
        loading: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_ADDRESSES):
    case FAILURE(ACTION_TYPES.CREATE_ADDRESS):
    case FAILURE(ACTION_TYPES.UPDATE_ADDRESS):
    case FAILURE(ACTION_TYPES.DELETE_ADDRESS):
      return {
        ...state,
        loading: false,
        fetchSuccess: false,
        fetchError: true,
        errorMessage: action.payload,
      };

    case SUCCESS(ACTION_TYPES.FETCH_ADDRESSES):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
        addresses: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ADDRESS):
    case SUCCESS(ACTION_TYPES.UPDATE_ADDRESS):
    case SUCCESS(ACTION_TYPES.DELETE_ADDRESS):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
      };

    default:
      return state;
  }
};

export const getAddresses: () => void = () => async (dispatch, getState) => {
  let url = 'api/me/addresses';

  const user = getState().users.user;
  const insuree = getState().insurees.insuree;

  if (user) {
    url = `api/admin/users/${user.id}/addresses`;
  } else if (insuree) {
    url = `api/insurees/${insuree.id}/addresses`;
  }

  await dispatch({
    type: ACTION_TYPES.FETCH_ADDRESSES,
    payload: axios.get(url),
  });
};

export const createAddress: (address: IAddress, email: string) => void = (address, email) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.CREATE_ADDRESS,
    payload: axios.post(`api/addresses/${email}`, address),
    meta: {
      successMessage: translate('userManagement.address.createSuccess'),
    },
  });
  dispatch(getAddresses());
  dispatch(handleModal('editAddress', false));
};

export const updateAddress: (address: IAddress) => void = address => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_ADDRESS,
    payload: axios.patch(`api/addresses/${address.id}`, address),
    meta: {
      successMessage: translate('userManagement.address.editSuccess'),
    },
  });
  dispatch(getAddresses());
  dispatch(handleModal('editAddress', false));
};

export const deleteAddress: (id: number) => void = id => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.DELETE_ADDRESS,
    payload: axios.delete(`api/addresses/${id}`),
    meta: {
      successMessage: translate('userManagement.address.deleteSuccess'),
    },
  });
  dispatch(getAddresses());
  dispatch(handleModal('deleteAddress', false));
};
