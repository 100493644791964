import React from 'react';

import './switch.scss';

type TSwitch = {
  className?: string;
  switched: boolean;
  onSwitch: () => void;
};

export const Switch = (props: TSwitch) => {
  const { className, switched, onSwitch } = props;
  return (
    <div className={`Switch ${switched ? 'switched' : ''} ${className || ''}`} onClick={onSwitch}>
      <div className={`Switch-handle  ${switched ? 'switched' : ''} `} />
    </div>
  );
};
