import { Avatar } from 'app/shared/components/avatar';
import Price from 'app/shared/components/price';
import { Icon } from 'app/shared/icons';
import { IProduct } from 'app/shared/model/products.model';
import React from 'react';
import { translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import { Text } from 'app/shared/components/text';
import ProductImage from '../product-image';

type ProductsCardProps = {
  product: IProduct;
  className?: string;
};

export const ProductsCardUser = (props: ProductsCardProps) => {
  const { product, className } = props;

  return (
    <div className="product-card">
      <Link to={`products/${product.id}`}>
        <ProductImage image={product.avatar} size="auto" />
      </Link>

      <div className="product-meta">
        <Link to={`products/${product.id}`}>
          <h3>{product.title}</h3>
        </Link>
        <Price amount={product.price} />
      </div>
    </div>
  );
};
