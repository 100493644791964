import React, { useState, useEffect } from 'react';
import { Translate, translate } from 'react-jhipster';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { getActiveParams, getParamValue } from './filter.utils';
import { Icon } from 'app/shared/icons';

type FilterBarsProps = {
  params: any;
  onRemoveParams: (param: string) => void;
};

export const FilterStatusBar = (props: FilterBarsProps) => {
  const { params, onRemoveParams } = props;

  const activeParams = getActiveParams(params);

  useEffect(() => {}, [params]);

  return (
    <>
      {activeParams?.length > 0 && (
        <div className="filters-status">
          <h3>{translate('filter.activeFilters')}</h3>
          <PerfectScrollbar
            options={{
              wheelPropagation: false,
            }}
          >
            <div className="active-filter-container">
              {activeParams.map((item, i) => {
                return (
                  <div className="active-filter" key={i}>
                    {item.label} &nbsp; <b>{item.value}</b>
                    <span onClick={() => onRemoveParams(item.key)}>
                      <Icon content="close"></Icon>
                    </span>
                  </div>
                );
              })}
            </div>
          </PerfectScrollbar>
        </div>
      )}
    </>
  );
};
