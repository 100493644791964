import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'swiper/swiper.scss';
import './app.scss';
import 'app/config/dayjs.ts';

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { IRootState } from 'app/shared/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getSession } from 'app/shared/reducers/authentication';
import Header from 'app/shared/layout/header/header';
import ErrorBoundary from 'app/shared/error/error-boundary';
import AppRoutes from 'app/routes';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');
import LoadingBar from 'react-redux-loading-bar';

export const App = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);
  const currentLocale = useSelector((state: IRootState) => state.locale.currentLocale);

  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    if (window.innerWidth < 1024) {
      document.body.classList.toggle('oy-hidden');
      setMenuOpen(!isMenuOpen);
    }
  };

  useEffect(() => {
    dispatch(getSession());
  }, [isAuthenticated]);

  useEffect(() => {}, [currentLocale]);

  return (
    <Router basename={baseHref}>
      <div className="app-container">
        <LoadingBar style={{ backgroundColor: '#2c42af', height: '3px', position: 'fixed', top: '0px', zIndex: 999 }} />
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} className="toastify-container" toastClassName="toastify-toast" />
        <ErrorBoundary>{isAuthenticated && <Header toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />}</ErrorBoundary>

        <div className={`app-content ${!isAuthenticated ? 'auth-page' : ''}`}>
          <ErrorBoundary>
            <AppRoutes />
          </ErrorBoundary>
        </div>
      </div>
    </Router>
  );
};

export default App;
