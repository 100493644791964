import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './tab-filter.scss';

type CustomTabProps = {
  tabs: any;
  count?: any;
  className?: string;
  currentTab: string;
  onChangeTab?: (tab: string) => void;
};

const TabFilter = (props: CustomTabProps) => {
  const { tabs, currentTab, onChangeTab, className } = props;

  return (
    <div className={`tab-filter ${className || ''}`}>
      <div className="tab-filter-container">
        <Swiper slidesPerView={'auto'} style={{ marginLeft: 0, marginRight: 0, width: '100%' }}>
          {Object.entries(tabs).map(([key, val]) => {
            return (
              <SwiperSlide
                className={`tab-filter-item ${currentTab === key ? 'active' : ''} `}
                key={key}
                onClick={() => (onChangeTab ? onChangeTab(key) : null)}
              >
                <div className="tab-title">{val}</div> {<div className="count"> {props.count && props.count[key]} </div>}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default TabFilter;
