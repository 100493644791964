import './header.scss';
import React from 'react';
import { Brand } from './header-components';
import { NavTop, NavSide } from 'app/shared/nav';
import { MenuLanguage, MenuUser, Burger, MenuPrimary } from 'app/shared/nav/menus';
import { Flex } from 'app/shared/components/flex';

export interface IHeaderProps {
  toggleMenu: () => void;
  isMenuOpen: boolean;
}

const Header = (props: IHeaderProps) => {
  const { isMenuOpen, toggleMenu } = props;

  return (
    <>
      <NavTop>
        <Burger className="burger-nav" click={toggleMenu} state={isMenuOpen} />
        <Brand />
        <Flex container alignItems="center" className="ml-auto">
          <MenuUser />
          <MenuLanguage />
        </Flex>
      </NavTop>

      <NavSide isMenuOpen={isMenuOpen}>
        <div className="Nav-side-header">
          <Brand />
        </div>
        <MenuPrimary />
      </NavSide>
    </>
  );
};

export default Header;
