import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { translate } from 'react-jhipster';
import { Form, FormItem, FormError, FormRow, ReadOnly } from 'app/shared/components/form/form';
import { validationSchema } from 'app/shared/util/validations.utils';
import { SelectInline } from 'app/shared/components/select-inline';
import { optionsTypeAddress } from '../address.utils';
import { useDispatch, useSelector } from 'react-redux';
import { createAddress, updateAddress } from '../address.reducer';
import { IRootState } from 'app/shared/reducers';
import { Loader } from 'app/shared/components/loader';
import { IAddress } from 'app/shared/model/user.model';
import { handleModal } from 'app/shared/reducers/modals';

type TAddressProps = {
  address: IAddress;
  email: string;
};

export const AddressEdit = (props: TAddressProps) => {
  const { address, email } = props;

  const dispatch = useDispatch();
  const addressState = useSelector((state: IRootState) => state.addresses);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: address,
  });

  const onSaveAddress = data => {
    if (!data.id) {
      // create an address
      dispatch(createAddress(data, email));
    } else {
      // already exists, update the address
      dispatch(updateAddress(data));
    }
  };

  const onAddressTypeChange = value => {
    setValue('type', value, { shouldValidate: true });
  };

  return (
    <Form>
      <FormRow>
        <FormItem htmlFor="addressType" label={translate('userManagement.address.addressType')}>
          <SelectInline
            options={optionsTypeAddress()}
            selected={address.type}
            mode="SINGLE"
            onSelect={value => onAddressTypeChange(value)}
            canDeselect={false}
          />
        </FormItem>
      </FormRow>

      <FormRow>
        <FormItem htmlFor="street" label={translate('userManagement.address.street')} className="col-75 col-xs-100">
          <input
            className={`form-control ${errors.street ? 'is-invalid' : ''}`}
            id="street"
            type="text"
            placeholder={translate('userManagement.address.street')}
            {...register('street', validationSchema.street)}
          />
          <FormError error={errors.street} validation={validationSchema.street} />
        </FormItem>
        <FormItem htmlFor="street" label={translate('userManagement.address.streetNumber')} className="col-25 col-xs-100">
          <input
            className={`form-control ${errors.number ? 'is-invalid' : ''}`}
            id="number"
            type="text"
            placeholder={translate('userManagement.address.streetNumber')}
            {...register('number', validationSchema.streetNumber)}
          />
          <FormError error={errors.number} validation={validationSchema.streetNumber} />
        </FormItem>
      </FormRow>

      <FormRow>
        <FormItem htmlFor="zipCode" label={translate('userManagement.address.zipCode')} className="col-25 col-xs-50 col-xxs-100">
          <input
            className={`form-control ${errors.zipCode ? 'is-invalid' : ''}`}
            id="zipCode"
            type="text"
            placeholder={translate('userManagement.address.zipCode')}
            {...register('zipCode', validationSchema.zipCode)}
          />
          <FormError error={errors.zipCode} validation={validationSchema.zipCode} />
        </FormItem>
        <FormItem htmlFor="town" label={translate('userManagement.address.town')} className="col-25 col-xs-50 col-xxs-100">
          <input
            className={`form-control ${errors.town ? 'is-invalid' : ''}`}
            id="town"
            type="text"
            placeholder={translate('userManagement.address.town')}
            {...register('town', validationSchema.town)}
          />
          <FormError error={errors.town} validation={validationSchema.town} />
        </FormItem>

        <FormItem htmlFor="province" label={translate('userManagement.address.province')} className="col-25 col-xs-50 col-xxs-100">
          <input
            className={`form-control text-uppercase ${errors.province ? 'is-invalid' : ''}`}
            id="province"
            type="text"
            placeholder={translate('userManagement.address.province')}
            {...register('province', validationSchema.province)}
          />
          <FormError error={errors.province} validation={validationSchema.province} />
        </FormItem>

        <FormItem htmlFor="country" label={translate('userManagement.address.country')} className="col-25 col-xs-50 col-xxs-100">
          <input
            className={`form-control ${errors.country ? 'is-invalid' : ''}`}
            id="country"
            type="text"
            placeholder={translate('userManagement.address.country')}
            {...register('country', validationSchema.town)}
          />
          <FormError error={errors.country} validation={validationSchema.town} />
        </FormItem>
      </FormRow>

      <FormRow>
        <FormItem>
          <Flex container alignItems="center">
            <Button size="md" color="white" onClick={() => dispatch(handleModal('editAddress', false))}>
              {translate('entity.action.cancel')}
            </Button>

            <Button className="ml-auto" size="md" color="primary" onClick={handleSubmit(onSaveAddress)} disabled={!isValid}>
              {addressState.loading ? <Loader /> : translate('entity.action.save')}
            </Button>
          </Flex>
        </FormItem>
      </FormRow>
    </Form>
  );
};
