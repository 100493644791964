import { COLORS } from 'app/config/constants';
import { Alert } from 'app/shared/components/alert';
import { Loader } from 'app/shared/components/loader';
import { PageHeader } from 'app/shared/components/page';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect } from 'react';
import { translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from './categories.reducer';
import { CategoryCard } from './category-card';

export const Categories = () => {
  const dispatch = useDispatch();
  const categoriesState = useSelector((state: IRootState) => state.categories);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  return (
    <>
      <PageHeader title={translate('categories.title')} />
      {categoriesState.loading && <Loader color={COLORS.PRIMARY} />}
      {categoriesState.fetchError && <Alert type="error" message={translate('error.loadingData')} />}
      {!categoriesState.loading && !categoriesState.fetchError && categoriesState.data && (
        <>
          {categoriesState.data?.parents?.length === 0 && translate('global.messages.info.noResults')}
          {categoriesState.data?.parents
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map(parent => (
              <CategoryCard key={parent.id} category={parent} subcategories={categoriesState.data.children[parent.id]} />
            ))}
        </>
      )}
    </>
  );
};
