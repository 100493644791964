import React, { useState, useEffect } from 'react';
import { Translate, translate } from 'react-jhipster';

import { Form, FormItem, FormRow, FormError } from 'app/shared/components/form';
import { useForm } from 'react-hook-form';
import { Button } from 'app/shared/components/button';
import { Loader } from 'app/shared/components/loader';

import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';

import { handlePasswordResetInit } from 'app/modules/me/password-reset/password-reset.reducer';
import { validationSchema } from 'app/shared/util/validations.utils';

type ForgotPasswordProps = {
  close: () => void;
};

const ForgotPassword = (props: ForgotPasswordProps) => {
  const dispatch = useDispatch();
  const forgotState = useSelector((state: IRootState) => state.passwordReset);

  const [clicked, setClicked] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const doForgot = data => {
    setClicked(true);
    dispatch(handlePasswordResetInit(data));
  };

  useEffect(() => {
    clicked && forgotState.resetPasswordSuccess && props.close();
  }, [forgotState.resetPasswordSuccess]);

  return (
    <>
      <Form>
        <p>
          <Translate contentKey="reset.request.messages.info">Enter the email address you used to register</Translate>
        </p>
        <FormRow>
          <FormItem>
            <input
              autoComplete="off"
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
              id="email"
              name="email"
              placeholder={translate('global.form.email.placeholder')}
              {...register('email', validationSchema.email)}
            />
            <FormError error={errors.email} validation={validationSchema.email} />
          </FormItem>
        </FormRow>

        <FormRow>
          <Button className="full-width" size="md" color="primary" onClick={handleSubmit(doForgot)} disabled={!isValid}>
            {!forgotState.loading ? <Translate contentKey="global.menu.send">Send</Translate> : <Loader />}
          </Button>
        </FormRow>
      </Form>
    </>
  );
};

export default ForgotPassword;
