import { Back } from 'app/shared/components/back';
import { PageHeader } from 'app/shared/components/page';
import { ProductForm } from 'app/shared/forms/product/product-form';
import React from 'react';
import { translate } from 'react-jhipster';

export const ProductNew = () => {
  return (
    <div className="container sm">
      <Back />
      <PageHeader title={translate('products.new.title')} />
      <ProductForm />
    </div>
  );
};
