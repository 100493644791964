import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Form, FormItem, FormRow } from 'app/shared/components/form';
import { Loader } from 'app/shared/components/loader';
import { IRootState } from 'app/shared/reducers';
import { handleModal } from 'app/shared/reducers/modals';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { deleteProduct } from '../../products.reducer';

export const DeleteProduct = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const products = useSelector((state: IRootState) => state.products);

  const onDelete = () => {
    dispatch(deleteProduct(products.product.id, history));
  };

  return (
    <>
      <Form>
        <FormRow>
          <FormItem>
            <Translate contentKey="products.delete.question" interpolate={{ title: products.product.title }}>
              Are you sure you want to delete this product?
            </Translate>
          </FormItem>
        </FormRow>

        <FormRow>
          <FormItem>
            <Flex container>
              <Button size="md" color="white" onClick={() => dispatch(handleModal('deleteProduct', false))}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button size="md" color="primary" className="ml-auto" disabled={products.deleting} onClick={onDelete}>
                {!products.deleting ? translate('entity.action.confirm') : <Loader />}
              </Button>
            </Flex>
          </FormItem>
        </FormRow>
      </Form>
    </>
  );
};
