import React from 'react';
import { translate } from 'react-jhipster';

import { MenuDropdown } from 'app/shared/nav/menus/menu-dropdown';
import { MenuItem } from 'app/shared/nav/menus/menu-item';

export const MenuSystem = (showOpenAPI: boolean) => {
  return (
    <div className="Menu-admin">
      <MenuDropdown icon="admin" title={translate('global.menu.admin.main')}>
        <MenuItem icon="metrics" title={translate('global.menu.admin.metrics')} to="/admin/metrics" />
        <MenuItem icon="heart" title={translate('global.menu.admin.health')} to="/admin/health" />
        <MenuItem icon="settings" title={translate('global.menu.admin.configuration')} to="/admin/configuration" />
        <MenuItem icon="logs" title={translate('global.menu.admin.logs')} to="/admin/logs" />
        {showOpenAPI && <MenuItem icon="api" title={translate('global.menu.admin.apidocs')} to="/admin/docs" />}
      </MenuDropdown>
    </div>
  );
};
