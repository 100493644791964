import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { handleModal } from '../reducers/modals';
import { getProductCategories, getUserCategories } from '../forms/categories-container/categories-container.reducer';
import { getProductAreas, getUserAreas } from '../forms/areas-container/areas-container.reducer';
import { getProduct } from 'app/modules/products/products.reducer';
import { getUser } from 'app/modules/users/users.reducer';

export const ACTION_TYPES = {
  SAVE_CATEGORIES: 'select/SELECT_CATEGORIES',
  SAVE_AREAS: 'select/SELECT_AREAS',
  RESET: 'select/RESET',
};

const initialState = {
  loading: false,
  fetchSuccess: false,
  fetchError: false,
};

export type SelectState = Readonly<typeof initialState>;

// Reducer
export default (state: SelectState = initialState, action): SelectState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SAVE_CATEGORIES):
    case REQUEST(ACTION_TYPES.SAVE_AREAS):
      return {
        ...state,
        fetchSuccess: false,
        loading: true,
      };

    case FAILURE(ACTION_TYPES.SAVE_CATEGORIES):
    case FAILURE(ACTION_TYPES.SAVE_AREAS):
      return {
        ...state,
        loading: false,
        fetchSuccess: false,
        fetchError: true,
      };

    case SUCCESS(ACTION_TYPES.SAVE_CATEGORIES):
    case SUCCESS(ACTION_TYPES.SAVE_AREAS):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
// USERS

export const saveUserCategories = (login: string, ids: number[]) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.SAVE_CATEGORIES,
    payload: axios.put(`api/categories/users/${login}`, ids),
  });

  dispatch(handleModal('selectCategories', false));
  dispatch(getUserCategories(login));
};

export const saveUserAreas = (login: string, ids: number[]) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.SAVE_AREAS,
    payload: axios.put(`api/areas/users/${login}`, ids),
  });

  dispatch(handleModal('selectAreas', false));
  dispatch(getUserAreas(login));
};

// PRODUCTS
export const saveProductCategories = (id: number, ids: number[]) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.SAVE_CATEGORIES,
    payload: axios.put(`api/categories/products/${id}`, ids),
  });

  dispatch(handleModal('selectCategories', false));
  dispatch(getProductCategories(id));
  dispatch(getProduct(id));
};

export const saveProductAreas = (id: number, ids: number[]) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.SAVE_AREAS,
    payload: axios.put(`api/areas/products/${id}`, ids),
  });

  dispatch(handleModal('selectAreas', false));
  dispatch(getProductAreas(id));
  dispatch(getProduct(id));
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
