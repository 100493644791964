import React, { useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { Form, FormItem, FormError, FormRow } from 'app/shared/components/form/form';
import { Button } from 'app/shared/components/button';
import { Heading } from 'app/shared/components/heading';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Switch } from 'app/shared/components/switch';
import { Loader } from 'app/shared/components/loader';

import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';

import { login } from 'app/shared/reducers/authentication';
import ForgotPassword from './forgot-password';
import { Modal } from 'app/shared/components/modal';
import { validationSchema } from 'app/shared/util/validations.utils';

const LoginPage = () => {
  const dispatch = useDispatch();
  const authentication = useSelector((state: IRootState) => state.authentication);

  const [remember, setRemember] = useState<boolean>(false);
  const [forgotPassworModal, setForgotPasswordModal] = useState<boolean>(false);

  const doLogin = data => {
    dispatch(login(data.username, data.password, remember));
  };

  const onModalOpen = () => {
    setForgotPasswordModal(true);
  };
  const onModalClose = () => {
    setForgotPasswordModal(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  return (
    <>
      <div className="auth-form">
        <PerfectScrollbar>
          <div className="auth-form-container">
            <div className="logo">
              <img src="content/images/quotass-logo.svg" alt="Logo" />
            </div>

            <Form>
              <Heading content={translate('login.title')} size={2} className="mb-1" />

              <FormRow>
                <FormItem htmlFor="username" label={translate('global.form.username.label')}>
                  <input
                    className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                    id="username"
                    placeholder={translate('global.form.username.placeholder')}
                    {...register('username', validationSchema.required)}
                  />
                  <FormError error={errors.username} validation={validationSchema.required} />
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem htmlFor="password" label={translate('global.form.password.label')}>
                  <input
                    type="password"
                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                    id="password"
                    placeholder={translate('global.form.password.placeholder')}
                    {...register('password', validationSchema.required)}
                  />
                  <FormError error={errors.password} validation={validationSchema.required} />
                  <p>
                    <a className="link primary font-size-sm" onClick={onModalOpen}>
                      <Translate contentKey="login.password.forgot">Did you forget your password?</Translate>
                    </a>
                  </p>
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem htmlFor="state">
                  <div className="d-flex">
                    <Switch className="mr-sm" switched={remember} onSwitch={() => setRemember(!remember)} />
                    <Translate contentKey="login.form.rememberme">Remember me</Translate>
                  </div>
                </FormItem>
              </FormRow>

              <FormRow>
                <Button className="full-width" size="md" color="primary" onClick={handleSubmit(doLogin)} disabled={!isValid}>
                  {!authentication.loading ? <Translate contentKey="login.form.button">Sign in</Translate> : <Loader />}
                </Button>
              </FormRow>

              {!authentication.loading && authentication.loginError && (
                <Alert color="danger">
                  <p>
                    {authentication.loginErrorMessage?.includes('activated')
                      ? translate('login.messages.error.activation')
                      : translate('login.messages.error.authentication')}
                  </p>
                </Alert>
              )}
            </Form>

            <div className="auth-footer">
              <Translate contentKey="register.notAmember">Register</Translate>
              <Link to="/register" className="link primary ml-xs font-weight-bold">
                <Translate contentKey="register.action">Register</Translate>
              </Link>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
      {forgotPassworModal && (
        <Modal size="xs" close={onModalClose} title={translate('reset.request.title')}>
          <ForgotPassword close={onModalClose} />
        </Modal>
      )}
    </>
  );
};

export default LoginPage;
