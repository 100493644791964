import { AvatarUpload } from 'app/shared/components/avatar-upload';
import { Back } from 'app/shared/components/back';
import { Button } from 'app/shared/components/button';
import { Form, FormItem, FormRow, ReadOnly } from 'app/shared/components/form';
import { Loader } from 'app/shared/components/loader';
import { PageHeader } from 'app/shared/components/page';
import AreasContainer from 'app/shared/forms/areas-container';
import CategoriesContainer from 'app/shared/forms/categories-container';
import { ProductForm } from 'app/shared/forms/product/product-form';
import { IProduct } from 'app/shared/model/products.model';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ProductImageUpload } from '../product-image/product-image-upload';
import { clearProduct, getProduct, publishProduct } from '../products.reducer';
import { Text } from 'app/shared/components/text';
import { Flex } from 'app/shared/components/flex';
import { Dropdown, DropdownContainer, DropdownItem, DropdownToogle } from 'app/shared/components/dropdown';
import { Icon } from 'app/shared/icons';
import { handleModal } from 'app/shared/reducers/modals';
import { Modal } from 'app/shared/components/modal';
import DeleteProduct from './delete-product';
import { Alert } from 'app/shared/components/alert';

export const ProductViewAdmin = () => {
  const { id }: any = useParams();

  const products = useSelector((state: IRootState) => state.products);
  const modalState = useSelector((state: IRootState) => state.modal);
  const [canPublish, setCanPublish] = useState<boolean>(false);

  const dispatch = useDispatch();

  const onPublish = () => {
    dispatch(publishProduct({ ...products.product, status: 'PUBLISHED' }));
  };

  const handlePublish = () => {
    setCanPublish(products.product.categories.length > 0 && products.product.areas.length && products.product.avatar ? true : false);
  };

  useEffect(() => {
    dispatch(getProduct(id));
    return () => {
      dispatch(clearProduct());
    };
  }, []);

  useEffect(() => {
    products.product && handlePublish();
  }, [products]);

  return (
    <>
      <div className="container xl">
        <Back />

        <div className="Settings">
          <PageHeader title={translate('products.view.title')}>
            <Flex container alignItems="center" className="ml-auto">
              {products.product?.status === 'DRAFT' && (
                <Button size="md" color="primary" onClick={onPublish} disabled={!canPublish}>
                  {products.publishing ? <Loader /> : translate('products.view.publish')}
                </Button>
              )}
              <Dropdown className="ml-1">
                <DropdownToogle>
                  <Button size="md" color="white">
                    <Icon content="dotsHor" />
                  </Button>
                </DropdownToogle>
                <DropdownContainer>
                  <DropdownItem onClick={() => dispatch(handleModal('deleteProduct', true))}>
                    <Translate contentKey="entity.action.delete">delete</Translate>
                  </DropdownItem>
                </DropdownContainer>
              </Dropdown>
            </Flex>
          </PageHeader>
          {products.loading && !products.product && <Loader />}
          {!products.loading && products.fetchError && <Alert type="error" message={translate('error.loadingData')} />}
          {products.product && (
            <div className="Settings-container">
              <div className="Settings-left-col">
                <ProductImageUpload />
              </div>
              <div className="Settings-right-col">
                <Form>
                  <FormRow>
                    <FormItem label={translate('products.labels.status')} className="col col-50 col-xs-100">
                      <ReadOnly
                        content={
                          <div className={`status-badge ${products.product.status}`}>
                            {translate(`products.search.tabs.${products.product.status}`)}
                          </div>
                        }
                      />
                    </FormItem>
                    <FormItem label={translate('products.labels.createdDate')} className="col col-50 col-xs-100">
                      <ReadOnly content={<Text type="date" format="DATETIME" content={products.product.createdDate} />} />
                    </FormItem>
                  </FormRow>
                </Form>

                <ProductForm product={products.product} />
                <CategoriesContainer type="PRODUCT" id={products.product.id} mode="EDIT" />
                <AreasContainer type="PRODUCT" id={products.product.id} mode="EDIT" />
              </div>
            </div>
          )}
        </div>
      </div>

      {modalState.deleteProduct && (
        <Modal title="Delete product" size="xs" close={() => dispatch(handleModal('deleteProduct', true))}>
          <DeleteProduct />
        </Modal>
      )}
    </>
  );
};
