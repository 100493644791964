const required = { required: 'validation.required' };
const minLenght = (value: number) => ({
  minLength: {
    value,
    message: 'validation.minLength',
  },
});
const maxLenght = (value: number) => ({
  maxLength: {
    value,
    message: 'validation.maxLength',
  },
});

const pattern = {
  nAn: {
    pattern: {
      value: /^\d+$/,
      message: 'validation.pattern.nan',
    },
  },
  taxCode: {
    pattern: {
      value: /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/i,
      message: 'validation.pattern.fiscalCode',
    },
  },
  email: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'validation.pattern.email',
    },
  },
  blank: {
    pattern: {
      value: /^\S+$/i,
      message: 'validation.pattern.blank',
    },
  },
  letters: {
    pattern: {
      value: /^[a-zA-Z]+$/i,
      message: 'validation.pattern.letters',
    },
  },
};

export const validationSchema = {
  required,
  userName: {
    ...required,
    ...minLenght(5),
    ...maxLenght(20),
    ...pattern.blank,
  },
  password: {
    ...required,
    ...minLenght(5),
    ...maxLenght(20),
  },
  confirmPassword: {
    ...required,
    type: {
      match: {
        message: 'global.messages.error.dontmatch',
      },
    },
  },
  firstName: {
    ...required,
    ...minLenght(3),
    ...maxLenght(50),
  },
  lastName: {
    ...required,
    ...minLenght(3),
    ...maxLenght(50),
  },
  companyName: {
    ...required,
    ...minLenght(3),
    ...maxLenght(50),
  },
  taxCode: {
    ...required,
    ...pattern.taxCode,
  },
  mobileLine: {
    ...required,
    ...minLenght(8),
    ...maxLenght(20),
    ...pattern.nAn,
  },
  landLine: {
    ...minLenght(8),
    ...maxLenght(20),
    ...pattern.nAn,
  },
  rui: {
    ...required,
    ...minLenght(9),
    ...maxLenght(9),
  },
  vat: {
    ...required,
    ...minLenght(9),
    ...maxLenght(9),
  },
  email: {
    ...required,
    ...pattern.email,
  },
  street: {
    ...required,
    ...minLenght(3),
    ...maxLenght(50),
  },
  streetNumber: {
    ...required,
    ...maxLenght(5),
    ...pattern.nAn,
  },
  zipCode: {
    ...required,
    ...maxLenght(5),
    ...pattern.nAn,
  },
  town: {
    ...required,
    ...minLenght(3),
    ...maxLenght(50),
  },
  province: {
    ...required,
    ...maxLenght(2),
    ...pattern.letters,
  },
};
