import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Form, FormItem, FormRow } from 'app/shared/components/form';
import { Loader } from 'app/shared/components/loader';
import { IRootState } from 'app/shared/reducers';
import { handleModal } from 'app/shared/reducers/modals';
import React, { useEffect } from 'react';
import { translate, Translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onUnlinkInsuree } from '../check/insuree-check.reducer';

type UnlinkInsureeProps = {
  id: number;
};

export const UnlinkInsuree = (props: UnlinkInsureeProps) => {
  const { id } = props;
  const history = useHistory();

  const dispatch = useDispatch();
  const checkInsuree = useSelector((state: IRootState) => state.checkInsuree);

  const onRemove = () => {
    dispatch(onUnlinkInsuree(id, history));
  };

  return (
    <>
      <Form>
        <FormRow>
          <FormItem>
            <Translate contentKey="insurees.unlink.question">Are you sure you want to remove this insuree?</Translate>
          </FormItem>
        </FormRow>

        <FormRow>
          <FormItem>
            <Flex container>
              <Button size="md" color="white" onClick={() => dispatch(handleModal('unlinkInsuree', false))}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button size="md" color="primary" className="ml-auto" disabled={checkInsuree.unlinking} onClick={onRemove}>
                {!checkInsuree.unlinking ? translate('entity.action.confirm') : <Loader />}
              </Button>
            </Flex>
          </FormItem>
        </FormRow>
      </Form>
    </>
  );
};
