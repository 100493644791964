import axios from 'axios';
import { translate } from 'react-jhipster';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { getSession } from 'app/shared/reducers/authentication';
import { avatarDto, TAccount } from 'app/shared/model/user.model';
import { getUser } from '../users/users.reducer';
import { getInsuree } from '../insurees/insurees.reducer';

export const ACTION_TYPES = {
  UPDATE_ACCOUNT: 'account/UPDATE_ACCOUNT',
  UPDATE_AVATAR: 'account/UPDATE_AVATAR',
};

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  updateFailure: false,
  me: null,
};

export type AccountState = Readonly<typeof initialState>;

// Reducer
export default (state: AccountState = initialState, action): AccountState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.UPDATE_ACCOUNT):
    case REQUEST(ACTION_TYPES.UPDATE_AVATAR):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.UPDATE_ACCOUNT):
    case FAILURE(ACTION_TYPES.UPDATE_AVATAR):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        updateFailure: true,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ACCOUNT):
    case SUCCESS(ACTION_TYPES.UPDATE_AVATAR):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        updateFailure: false,
      };

    default:
      return state;
  }
};

// Actions

export const updateAccount: (account: TAccount) => void = account => async (dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_ACCOUNT,
    payload: axios.patch(`api/account/${account.id}`, account),
    meta: {
      successMessage: translate('settings.messages.success'),
    },
  });

  const user = getState().users.user;
  const insuree = getState().insurees.insuree;

  if (user) {
    await dispatch(getUser(user.login));
  } else if (insuree) {
    await dispatch(getInsuree(insuree.id));
  } else {
    await dispatch(getSession());
  }
};

export const updateAvatar: (avatar: avatarDto, accountId: number) => void = (avatar, accountId) => async (dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_AVATAR,
    payload: axios.post(`api/account/${accountId}/avatar`, avatar),
    meta: {
      successMessage: translate('settings.messages.avatar.success'),
      errorMessage: translate('settings.messages.avatar.error'),
    },
  });

  const user = getState().users.user;
  const insuree = getState().insurees.insuree;

  if (user) {
    await dispatch(getUser(user.login));
  } else if (insuree) {
    await dispatch(getInsuree(insuree.id));
  } else {
    await dispatch(getSession());
  }
};
