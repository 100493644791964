import React, { useState } from 'react';
import { TIcons } from 'app/config/types';
import { Icon } from 'app/shared/icons';

type TMenuDropdown = {
  icon?: TIcons;
  title: string;
  children: any;
};

export const MenuDropdown = (props: TMenuDropdown) => {
  const { icon, title, children } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={`menu-dropdown ${isOpen ? 'open' : ''}`}>
      <div className="menu-item dropdown-toggle" onClick={() => setIsOpen(!isOpen)}>
        {icon && <Icon content={icon} />}
        <span className="hideIfMenuIsCollapsed">{title}</span>
        <Icon content={isOpen ? 'arrowUp' : 'arrowDown'} className="ml-auto hideIfMenuIsCollapsed" />
      </div>

      {isOpen && <div className="menu-dropdown-container"> {children} </div>}
    </div>
  );
};
