import { IArea, ICategory } from './categories-zones.model';
import { avatarDto } from './user.model';

export interface IProduct {
  id?: number;
  createdDate?: string;
  lastModifiedDate?: string;
  title: string;
  insurance: string;
  description: string;
  price: number;
  avatar: avatarDto;
  areas: number[];
  categories: number[];
  status: IProductStatus;
}

type IProductStatus = 'DRAFT' | 'PUBLISHED';

export const defaultProduct: Readonly<IProduct> = {
  title: null,
  insurance: null,
  description: null,
  price: null,
  avatar: null,
  areas: [],
  categories: [],
  status: 'DRAFT',
};
