import { getCategories } from 'app/modules/categories/categories.reducer';
import React, { Children, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../components/button';
import { IRootState } from '../reducers';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Icon } from '../icons';
import { Flex } from '../components/flex';
import { translate } from 'react-jhipster';
import { getAreas } from 'app/modules/areas/areas.reducer';
import { Loader } from '../components/loader';
import { COLORS } from 'app/config/constants';
import { Alert } from '../components/alert';

type SelectItemsProps = {
  selected: number[];
  type: 'CATEGORIES' | 'AREAS';
  onSave: (ids: number[]) => void;
};

type Item = {
  name: string;
  id: number;
  parent: number;
};

type ItemProps = {
  item: Item;
  isSelected: boolean;
  onSelect: (item: Item) => void;
};

const Item = (props: ItemProps) => {
  const { item, isSelected, onSelect } = props;

  return (
    <div className={`item  ${isSelected ? 'selected' : ''}`} onClick={() => onSelect(item)}>
      <div className={`dot ${isSelected ? 'selected' : ''}`}>{isSelected && <Icon content="checked" />}</div>
      {item.name}
    </div>
  );
};

export const SelectItems = (props: SelectItemsProps) => {
  const { selected } = props;

  const dispatch = useDispatch();
  const itemsState = useSelector((state: IRootState) => (props.type === 'CATEGORIES' ? state.categories : state.areas));
  const selectState = useSelector((state: IRootState) => state.selectState);

  const [currentSelection, setCurrentSelection] = useState<number[]>(selected || []);
  const [expanded, setExpanded] = useState<number[]>([]);

  const onSelect = (selectedItem: Item) => {
    const parent = itemsState.data?.children[selectedItem.id];
    const childrenItems = parent ? parent.map(children => children.id) : [];

    if (currentSelection.includes(selectedItem.id)) {
      const filtered = currentSelection.filter(id => id !== selectedItem.id && !childrenItems?.includes(id));
      setCurrentSelection([...new Set(filtered)]);
    } else {
      setCurrentSelection([...new Set([...currentSelection, ...childrenItems, selectedItem.id])]);
    }
  };

  const onExpand = (id: number) => {
    if (expanded.includes(id)) {
      const filtered = expanded.filter(item => item !== id);
      setExpanded(filtered);
    } else {
      setExpanded([...expanded, id]);
    }
  };

  useEffect(() => {
    dispatch(props.type === 'CATEGORIES' ? getCategories() : getAreas());
  }, []);

  return (
    <>
      {itemsState.loading && <Loader color={COLORS.PRIMARY} />}
      {itemsState.fetchError && <Alert type="error" message={translate('error.loadingData')} />}
      {!itemsState.loading && !itemsState.fetchError && (
        <>
          {itemsState.data?.parents?.length === 0 && translate('global.messages.info.noResults')}
          {itemsState.data?.parents?.length > 0 && (
            <>
              <PerfectScrollbar className="select-container">
                {itemsState.data?.parents
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  ?.map(category => {
                    const isExpanded = expanded.includes(category.id);
                    return (
                      <Flex container key={category.id}>
                        <div className="parent-container">
                          <Item item={category} isSelected={currentSelection.includes(category.id)} onSelect={onSelect} />

                          {isExpanded && (
                            <div className="children-container">
                              {itemsState.data?.children[category.id]?.map(children => (
                                <Item
                                  key={children.id}
                                  item={children}
                                  isSelected={currentSelection.includes(children.id)}
                                  onSelect={onSelect}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                        <div>
                          <Button size="sm" color="white" className="ml-auto" onClick={() => onExpand(category.id)}>
                            <Icon content={isExpanded ? 'arrowUp' : 'arrowDown'} />
                          </Button>
                        </div>
                      </Flex>
                    );
                  })}
              </PerfectScrollbar>
              <Flex container justifyContent="flex-end">
                <Button size="md" color="primary" disabled={currentSelection.length === 0} onClick={() => props.onSave(currentSelection)}>
                  {selectState.loading ? <Loader /> : translate('entity.action.save')}
                </Button>
              </Flex>
            </>
          )}
        </>
      )}
    </>
  );
};
