import { COLORS } from 'app/config/constants';
import { Loader } from 'app/shared/components/loader';
import { IRootState } from 'app/shared/reducers';
import { handleFileUpload } from 'app/shared/util/upload-utils';
import React, { useEffect, useRef } from 'react';
import { translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductAvatar } from '../products.reducer';

export const ProductImageUpload = () => {
  const products = useSelector((state: IRootState) => state.products);
  const avatar = products.product.avatar;
  const dispatch = useDispatch();
  const input = useRef(null);

  const onAvatarChange = async event => {
    const content = await handleFileUpload({ target: event.target.files[0], size: 3, type: 'image', resize: { width: 600, height: 400 } });
    'fileContent' in content && dispatch(updateProductAvatar(products.product.id, content));
    input.current.value = '';
  };

  useEffect(() => {}, [avatar]);

  return (
    <div className={`Avatar-upload `}>
      <div className="avatar-container">
        {avatar && <img src={`data:${avatar.fileContent?.contentType};base64,` + avatar?.fileContent.contents} />}
        {!avatar && <img src="content/images/default-product.png" />}
      </div>

      <div className="button-upload">
        <input type="file" id="avatar-upload-button" onChange={event => onAvatarChange(event)} ref={input} disabled={products.uploading} />
        <label className="button super-light-grey sm" htmlFor="avatar-upload-button">
          {products.uploading ? <Loader color={COLORS.BLACK} /> : translate('entity.action.selectImage')}
        </label>
      </div>
    </div>
  );
};
