import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUrlParameter, translate, Translate } from 'react-jhipster';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Loader } from 'app/shared/components/loader';

import { handlePasswordResetFinish, reset } from '../password-reset.reducer';
import { Flex } from 'app/shared/components/flex';
import { IRootState } from 'app/shared/reducers';

import { useForm } from 'react-hook-form';
import { Form, FormItem, FormError, FormRow } from 'app/shared/components/form/form';
import { Button } from 'app/shared/components/button';
import { Brand } from 'app/shared/layout/header/header-components';
import { validationSchema } from 'app/shared/util/validations.utils';

export const PasswordResetFinishPage = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [key] = useState(getUrlParameter('key', props.location.search));

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const resetState = useSelector((state: IRootState) => state.passwordReset);

  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (resetState.resetPasswordSuccess) {
      history.push('/login');
    }
  }, [resetState.resetPasswordSuccess]);

  const checkPassword = e => {
    return e === getValues('newPassword');
  };

  const updatePassword = data => dispatch(handlePasswordResetFinish(key, data.newPassword));

  const getResetForm = () => {
    return (
      <Form>
        <FormRow>
          <FormItem htmlFor="newPassword" label={translate('global.form.newpassword.label')}>
            <input
              autoComplete="off"
              className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`}
              placeholder={translate('global.form.newpassword.placeholder')}
              type="password"
              {...register('newPassword', validationSchema.password)}
            />
            <FormError error={errors.newPassword} validation={validationSchema.password} />
          </FormItem>
        </FormRow>

        <FormRow>
          <FormItem htmlFor="secondPassword" label={translate('global.form.confirmpassword.label')}>
            <input
              autoComplete="off"
              className={`form-control ${errors.secondPassword ? 'is-invalid' : ''}`}
              placeholder={translate('global.form.confirmpassword.placeholder')}
              type="password"
              {...register('secondPassword', {
                ...validationSchema.confirmPassword,
                validate: {
                  match: e => checkPassword(e),
                },
              })}
            />
            <FormError error={errors.secondPassword} validation={validationSchema.confirmPassword} />
          </FormItem>
        </FormRow>

        <FormRow>
          <FormItem>
            <Flex container alignItems="center">
              <Button className="ml-auto" size="md" color="primary" onClick={handleSubmit(updatePassword)} disabled={!isValid}>
                {!resetState.loading ? translate('entity.action.save') : <Loader />}
              </Button>
            </Flex>
          </FormItem>
        </FormRow>
      </Form>
    );
  };

  return (
    <>
      <Flex container flexDirection="column" className="no-auth-form">
        <Brand />
        <h1>
          <Translate contentKey="reset.finish.title">Reset password</Translate>
        </h1>
        {key ? getResetForm() : null}
      </Flex>
    </>
  );
};

export default PasswordResetFinishPage;
