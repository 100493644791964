import { translate } from 'react-jhipster';

export const optionsRegisterType = () => [
  { label: translate('userManagement.options.agency'), value: 'AGENCY' },
  { label: translate('userManagement.options.broker'), value: 'BROKER' },
];

export const optionsType = () => [
  { label: translate('userManagement.options.agency'), value: 'AGENCY' },
  { label: translate('userManagement.options.broker'), value: 'BROKER' },
  { label: translate('insurees.options.physical'), value: 'PHYSICAL' },
  { label: translate('insurees.options.juridical'), value: 'JURIDICAL' },
];

export const optionsTypeUser = () => [
  { label: translate('userManagement.options.all'), value: 'ALL' },
  { label: translate('userManagement.options.agency'), value: 'AGENCY' },
  { label: translate('userManagement.options.broker'), value: 'BROKER' },
];
export const optionsTypeInsuree = () => [
  { label: translate('insurees.options.all'), value: 'ALL' },
  { label: translate('insurees.options.physical'), value: 'PHYSICAL' },
  { label: translate('insurees.options.juridical'), value: 'JURIDICAL' },
];

export const optionsTypeUserSelect = () => [
  { label: translate('userManagement.options.agency'), value: 'AGENCY' },
  { label: translate('userManagement.options.broker'), value: 'BROKER' },
];
export const optionsTypeInsureeSelect = () => [
  { label: translate('insurees.options.physical'), value: 'PHYSICAL' },
  { label: translate('insurees.options.juridical'), value: 'JURIDICAL' },
];

export const optionsOverdraft = () => [
  { label: translate('userManagement.options.all'), value: 'ALL' },
  { label: translate('userManagement.options.yes'), value: 'YES' },
  { label: translate('userManagement.options.no'), value: 'NO' },
];
