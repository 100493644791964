import { COLORS } from 'app/config/constants';
import { Alert } from 'app/shared/components/alert';
import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Heading } from 'app/shared/components/heading';
import { Loader } from 'app/shared/components/loader';
import { Modal } from 'app/shared/components/modal';
import { Icon } from 'app/shared/icons';
import { IRootState } from 'app/shared/reducers';
import { handleModal } from 'app/shared/reducers/modals';
import { SelectItems } from 'app/shared/select-items/select-items';
import { saveProductAreas, saveUserAreas } from 'app/shared/select-items/select-items.reducer';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { getProductAreas, getUserAreas } from './areas-container.reducer';

type UserAreas = {
  type: 'USER';
  login: string;
};

type ProductAreas = {
  type: 'PRODUCT';
  id: number;
};

type ModeAreas = {
  mode: 'VIEW' | 'EDIT';
};

type AreasContainerProps = ModeAreas & (UserAreas | ProductAreas);

export const AreasContainer = (props: AreasContainerProps) => {
  const selectedAreas = useSelector((state: IRootState) => state.selectedAreas);
  const modalState = useSelector((state: IRootState) => state.modal);
  const dispatch = useDispatch();

  const getSelected = () => {
    return selectedAreas?.areas?.map(area => area.id);
  };

  const onSave = (ids: number[]) => {
    props.type === 'USER' ? dispatch(saveUserAreas(props.login, ids)) : dispatch(saveProductAreas(props.id, ids));
  };

  useEffect(() => {
    props.type === 'USER' ? dispatch(getUserAreas(props.login)) : dispatch(getProductAreas(props.id));
  }, []);

  return (
    <div className="Settings-section">
      <Flex container alignItems="center" className="mb-1">
        <Heading content={translate('areas.title')} size={15} />

        {props.mode === 'EDIT' && (
          <Button className="ml-auto" size="sm" color="white" onClick={() => dispatch(handleModal('selectAreas', true))}>
            <Icon content="edit" />
          </Button>
        )}
      </Flex>

      {selectedAreas.loading && <Loader color={COLORS.PRIMARY} />}
      {selectedAreas.fetchError && <Alert type="error" message={translate('error.loadingData')} />}
      {selectedAreas.areas && (
        <>
          {selectedAreas.areas?.length === 0 && translate('global.messages.info.noResults')}
          {selectedAreas.areas?.length > 0 && (
            <Flex container flexWrap="wrap" style={{ gridGap: '1rem' }}>
              {selectedAreas.areas.map((category, i) => (
                <div key={category.id} className="category-badge">
                  {category.name}
                </div>
              ))}
            </Flex>
          )}
        </>
      )}

      {modalState.selectAreas && (
        <Modal title={translate('areas.selectAreas')} size="xs" close={() => dispatch(handleModal('selectAreas', false))}>
          <SelectItems selected={getSelected()} type="AREAS" onSave={onSave} />
        </Modal>
      )}
    </div>
  );
};
