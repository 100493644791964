import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Form, FormError, FormItem, FormRow } from 'app/shared/components/form';
import { Loader } from 'app/shared/components/loader';
import { IRootState } from 'app/shared/reducers';
import { handleModal } from 'app/shared/reducers/modals';
import { validationSchema } from 'app/shared/util/validations.utils';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Translate, translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { onCheckInsuree, onLinkInsuree } from './insuree-check.reducer';

export const InsureeCheck = () => {
  const checkInsuree = useSelector((state: IRootState) => state.checkInsuree);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: { taxCode: null },
    shouldUnregister: true,
  });

  const onSubmit = data => {
    dispatch(onCheckInsuree(data.taxCode));
  };

  const onLink = () => {
    dispatch(onLinkInsuree(checkInsuree.exists));
  };

  const onAdd = () => {
    dispatch(handleModal('insureeCheck', false));
    history.push('insurees/new');
  };

  return (
    <Form className="form-link">
      {checkInsuree.exists === null && (
        <>
          <FormRow>
            <FormItem htmlFor="taxCode" label={translate('userManagement.fiscalCode')}>
              <input
                className={`form-control ${errors?.taxCode ? 'is-invalid' : ''}`}
                id="taxCode"
                type="text"
                placeholder={translate('userManagement.fiscalCode')}
                {...register('taxCode', validationSchema.taxCode)}
              />
              <FormError error={errors?.taxCode} validation={validationSchema.taxCode} />
            </FormItem>
          </FormRow>

          <FormRow>
            <FormItem>
              <Flex container alignItems="center">
                <Button
                  className="ml-auto"
                  size="md"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid || checkInsuree.checking}
                >
                  {checkInsuree.checking ? <Loader /> : translate('entity.action.search')}
                </Button>
              </Flex>
            </FormItem>
          </FormRow>
        </>
      )}
      {checkInsuree.checkSuccess && (
        <>
          <FormRow>
            <FormItem>{translate(`insurees.${checkInsuree.exists > 0 ? 'link' : 'create'}.title`)}</FormItem>
          </FormRow>
          <FormRow>
            {checkInsuree.exists > 0 ? (
              <Button size="md" color="primary" onClick={onLink} disabled={checkInsuree.linking}>
                {checkInsuree.linking ? <Loader /> : translate('insurees.link.action')}
              </Button>
            ) : (
              <Button size="md" color="primary" onClick={onAdd} disabled={checkInsuree.linking}>
                <Translate contentKey="insurees.create.action">Create a new insuree</Translate>
              </Button>
            )}
          </FormRow>
        </>
      )}
    </Form>
  );
};
