import { COLORS } from 'app/config/constants';
import { Alert } from 'app/shared/components/alert';
import { Loader } from 'app/shared/components/loader';
import { PageHeader } from 'app/shared/components/page';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect } from 'react';
import { translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { AreaCard } from './areas-card';
import { getAreas } from './areas.reducer';

export const Areas = () => {
  const dispatch = useDispatch();
  const areasState = useSelector((state: IRootState) => state.areas);

  useEffect(() => {
    dispatch(getAreas());
  }, []);

  return (
    <>
      <PageHeader title={translate('areas.title')} />
      {areasState.loading && <Loader color={COLORS.PRIMARY} />}
      {areasState.fetchError && <Alert type="error" message={translate('error.loadingData')} />}
      {!areasState.loading && !areasState.fetchError && areasState.data && (
        <>
          {areasState.data?.parents.length === 0 && translate('global.messages.info.noResults')}
          {areasState.data?.parents
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map(parent => (
              <AreaCard key={parent.id} category={parent} subcategories={areasState.data.children[parent.id]} />
            ))}
        </>
      )}
    </>
  );
};
