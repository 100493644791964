import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-jhipster';
import { Icon } from 'app/shared/icons';
import { Text } from 'app/shared/components/text';
import { Avatar } from 'app/shared/components/avatar';
import { IInsuree } from 'app/shared/model/user.model';
import { Flex } from 'app/shared/components/flex';

type TInsureeCard = {
  insuree: IInsuree;
  className?: string;
};

export const InsureeCard = (props: TInsureeCard) => {
  const { insuree, className } = props;

  return (
    <tr className={`table-item ${className || ''} `}>
      <td>
        <Link to={`insurres/${insuree.id}`}>
          <Avatar avatar={insuree?.account?.avatar} size="lg" />
        </Link>
      </td>
      <td>
        <Flex container flexDirection="column">
          <Link to={`insurees/${insuree.id}`}>
            <b>
              {insuree.account.type === 'JURIDICAL'
                ? insuree.account.companyName
                : insuree.account.firstName + ' ' + insuree.account.lastName}
            </b>
          </Link>
          {insuree.email}
        </Flex>
      </td>
      <td>{translate(`insurees.${insuree.account.type}`)}</td>
      <td>{insuree.account.taxCode}</td>
      <td>{insuree.createdDate ? <Text type="date" format="DATETIME" content={insuree.createdDate} /> : '-'}</td>

      <td className="text-right">
        <div className="button-group">
          <Link to={`insurees/${insuree.id}`} className="button sm white">
            <Icon content="arrowRight" />
          </Link>
        </div>
      </td>
    </tr>
  );
};
