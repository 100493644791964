import axios from 'axios';
import { Storage, translate } from 'react-jhipster';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { getSession, logout } from 'app/shared/reducers/authentication';
import { avatarDto, TAccount } from 'app/shared/model/user.model';
import { refreshToken } from 'app/shared/reducers/refresh';

export const ACTION_TYPES = {
  UPDATE_ME: 'me/UPDATE_ME',
  UPDATE_PASSWORD: 'me/UPDATE_PASSWORD',
  RESET: 'me/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  updateFailure: false,
  me: null,
};

export type SettingsState = Readonly<typeof initialState>;

// Reducer
export default (state: SettingsState = initialState, action): SettingsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.UPDATE_ME):
    case REQUEST(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.UPDATE_ME):
    case FAILURE(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        updateFailure: true,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ME):
    case SUCCESS(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        updateFailure: false,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions

export const updateMe: (account: any) => void = account => async (dispatch, getState) => {
  const hasLoginChanged = getState().authentication.me?.login !== account.login;

  await dispatch({
    type: ACTION_TYPES.UPDATE_ME,
    payload: axios.put('api/me', account),
    meta: {
      successMessage: translate(hasLoginChanged ? 'settings.messages.loginChanged' : 'settings.messages.success'),
    },
  });

  if (Storage.session.get(`locale`)) {
    Storage.session.remove(`locale`);
  }

  if (hasLoginChanged) {
    dispatch(logout());
  } else {
    dispatch(getSession());
  }
};

export const updatePassword = data => ({
  type: ACTION_TYPES.UPDATE_PASSWORD,
  payload: axios.post('api/me/password/change', data),
  meta: {
    successMessage: translate('password.messages.success'),
    errorMessage: translate('password.messages.error'),
  },
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
