import React from 'react';

type Tloader = {
  className?: string;
  size?: 'sm' | 'md' | 'xl';
  color?: string;
};

export const Loader = (props: Tloader) => {
  const { className, size, color = '#ffffff' } = props;

  return (
    <div className={`Loader ${className} ${size}`}>
      <svg style={{ margin: 'auto', display: 'block' }} width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke={`${color}`}
          strokeWidth="2"
          r="40"
          strokeDasharray="188.49555921538757 64.83185307179586"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="0.6211180124223602s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
    </div>
  );
};
