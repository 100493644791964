import React, { useState, useEffect } from 'react';
import { defaultAccount, TAccount } from 'app/shared/model/user.model';
import { Heading } from 'app/shared/components/heading';
import { translate } from 'react-jhipster';
import { useForm } from 'react-hook-form';
import { Form, FormItem, FormError, FormRow, ReadOnly } from 'app/shared/components/form/form';
import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Icon } from 'app/shared/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateAccount } from 'app/modules/me/account.reducer';
import { getOptionLabel } from 'app/shared/util/account-utils';
import { IRootState } from 'app/shared/reducers';
import { Loader } from 'app/shared/components/loader';
import { optionsType } from 'app/shared/util/select.utils';
import { validationSchema } from 'app/shared/util/validations.utils';

type TUserAccountProps = {
  account: TAccount;
  type: 'USER' | 'INSUREE';
  canEdit?: boolean;
};

export const Account = (props: TUserAccountProps) => {
  const { account, type, canEdit = false } = props;

  const dispatch = useDispatch();
  const accountState = useSelector((state: IRootState) => state.account);

  const [edit, setEdit] = useState<boolean>(false);
  const [clicked, setCLicked] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultAccount,
  });

  const onUpdateAccount = data => {
    setCLicked(true);
    dispatch(updateAccount(data));
  };

  const toggleEdit = () => {
    setEdit(!edit);
    reset(props.account);
  };

  useEffect(() => {
    if (!accountState.loading && clicked && accountState.updateSuccess) {
      setCLicked(false);
      setEdit(false);
    }
  }, [accountState]);

  return (
    <div className="Settings-section">
      <Flex container alignItems="center" className="mb-1">
        <Heading content={translate('global.menu.account.main')} size={15} />
        {!edit && canEdit && (
          <Button className="ml-auto" size="sm" color="white" onClick={toggleEdit}>
            <Icon content="edit" />
          </Button>
        )}
      </Flex>
      <Form>
        <FormRow>
          <FormItem htmlFor="type" label={translate('userManagement.type')}>
            <ReadOnly content={getOptionLabel(optionsType(), account?.type)} />
          </FormItem>
        </FormRow>

        {(account?.type === 'BROKER' || account?.type === 'PHYSICAL') && (
          <FormRow col="2">
            <FormItem htmlFor="firstName" label={translate('userManagement.firstName')} className="col-50 col-xs-100">
              {edit ? (
                <>
                  <input
                    className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                    id="firstName"
                    type="text"
                    placeholder={translate('userManagement.firstName')}
                    {...register('firstName', validationSchema.firstName)}
                  />
                  <FormError error={errors.firstName} validation={validationSchema.firstName} />
                </>
              ) : (
                <ReadOnly content={account?.firstName} />
              )}
            </FormItem>

            <FormItem htmlFor="lastName" label={translate('userManagement.lastName')} className="col-50 col-xs-100">
              {edit ? (
                <>
                  <input
                    className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                    id="lastName"
                    type="text"
                    placeholder={translate('userManagement.lastName')}
                    {...register('lastName', validationSchema.lastName)}
                  />
                  <FormError error={errors.lastName} validation={validationSchema.lastName} />
                </>
              ) : (
                <ReadOnly content={account?.lastName} />
              )}
            </FormItem>
          </FormRow>
        )}

        {(account?.type === 'AGENCY' || account?.type === 'JURIDICAL') && (
          <FormRow>
            <FormItem htmlFor="companyName" label={translate('userManagement.companyName')}>
              {edit ? (
                <>
                  <input
                    className={`form-control ${errors.companyName ? 'is-invalid' : ''}`}
                    id="companyName"
                    type="text"
                    placeholder={translate('userManagement.companyName')}
                    {...register('companyName', validationSchema.companyName)}
                  />
                  <FormError error={errors.companyName} validation={validationSchema.companyName} />
                </>
              ) : (
                <ReadOnly content={account?.companyName} />
              )}
            </FormItem>
          </FormRow>
        )}

        <FormRow>
          <FormItem htmlFor="taxCode" label={translate('userManagement.fiscalCode')}>
            {edit ? (
              <>
                <input
                  className={`form-control ${errors.taxCode ? 'is-invalid' : ''}`}
                  id="taxCode"
                  type="text"
                  placeholder={translate('userManagement.fiscalCode')}
                  {...register('taxCode', validationSchema.taxCode)}
                />
                <FormError error={errors.taxCode} validation={validationSchema.taxCode} />
              </>
            ) : (
              <ReadOnly content={account?.taxCode} />
            )}
          </FormItem>
        </FormRow>

        <FormRow>
          <FormItem htmlFor="mobileLine" label={translate('userManagement.mobileLine')} className="col-50 col-xs-100">
            {edit ? (
              <>
                <input
                  className={`form-control ${errors.mobileLine ? 'is-invalid' : ''}`}
                  id="mobileLine"
                  type="text"
                  placeholder={translate('userManagement.mobileLine')}
                  {...register('mobileLine', validationSchema.mobileLine)}
                />
                <FormError error={errors.mobileLine} validation={validationSchema.mobileLine} />
              </>
            ) : (
              <ReadOnly content={account?.mobileLine} />
            )}
          </FormItem>

          <FormItem htmlFor="landLine" label={translate('userManagement.landLine')} className="col-50 col-xs-100">
            {edit ? (
              <>
                <input
                  className={`form-control ${errors.landLine ? 'is-invalid' : ''}`}
                  id="landLine"
                  type="text"
                  placeholder={translate('userManagement.landLine')}
                  {...register('landLine', validationSchema.landLine)}
                />
                <FormError error={errors.landLine} validation={validationSchema.landLine} />
              </>
            ) : (
              <ReadOnly content={account?.landLine} />
            )}
          </FormItem>
        </FormRow>

        {type === 'USER' && (
          <FormRow>
            <FormItem htmlFor="rui" label={translate('userManagement.rui')}>
              {edit ? (
                <>
                  <input
                    className={`form-control ${errors.rui ? 'is-invalid' : ''}`}
                    id="rui"
                    type="text"
                    placeholder={translate('userManagement.rui')}
                    {...register('rui', validationSchema.rui)}
                  />
                  <FormError error={errors.rui} validation={validationSchema.rui} />
                </>
              ) : (
                <ReadOnly content={account?.rui} />
              )}
            </FormItem>
          </FormRow>
        )}
        {(type === 'USER' || (type === 'INSUREE' && account?.type === 'JURIDICAL')) && (
          <FormRow>
            <FormItem htmlFor="vat" label={translate('userManagement.vat')}>
              {edit ? (
                <>
                  <input
                    className={`form-control ${errors.vat ? 'is-invalid' : ''}`}
                    id="vat"
                    name="vat"
                    type="text"
                    placeholder={translate('userManagement.vat')}
                    {...register('vat', validationSchema.vat)}
                  />
                  <FormError error={errors.vat} validation={validationSchema.vat} />
                </>
              ) : (
                <ReadOnly content={account?.vat} />
              )}
            </FormItem>
          </FormRow>
        )}

        {edit && (
          <FormRow>
            <FormItem>
              <Flex container alignItems="center">
                <Button size="md" color="white" onClick={toggleEdit}>
                  {translate('entity.action.cancel')}
                </Button>
                <Button className="ml-auto" size="md" color="primary" onClick={handleSubmit(onUpdateAccount)} disabled={!isValid}>
                  {accountState.loading ? <Loader /> : translate('entity.action.save')}
                </Button>
              </Flex>
            </FormItem>
          </FormRow>
        )}
      </Form>
    </div>
  );
};
