export const ACTION_TYPES = {
  SET_PARAMS: 'params/SET_PARAMS',
  CLEAR_PARAMS: 'params/CLEAR_PARAMS',
};

const initialState = {};

export type ParamsState = Readonly<typeof initialState>;

export default (state: ParamsState = initialState, action): ParamsState => {
  switch (action.type) {
    case ACTION_TYPES.SET_PARAMS:
      return action.payload;
    case ACTION_TYPES.CLEAR_PARAMS:
      return initialState;

    default:
      return state;
  }
};

export const setStoreParams: (params) => void = params => {
  return {
    type: ACTION_TYPES.SET_PARAMS,
    payload: params,
  };
};

export const clearStoreParams: () => void = () => {
  return {
    type: ACTION_TYPES.CLEAR_PARAMS,
  };
};
