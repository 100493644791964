import React, { useEffect } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { handleRegister } from './register.reducer';
import { Link, Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, FormItem, FormError, FormRow } from 'app/shared/components/form/form';
import { Button } from 'app/shared/components/button';
import { Heading } from 'app/shared/components/heading';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Loader } from 'app/shared/components/loader';
import { SelectInline } from 'app/shared/components/select-inline';
import { optionsRegisterType } from 'app/shared/util/select.utils';
import { validationSchema } from 'app/shared/util/validations.utils';

export const RegisterPage = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);
  const registerState = useSelector((state: IRootState) => state.register);
  const currentLocale = useSelector((state: IRootState) => state.locale.currentLocale);

  const defaultRegisterForm = {
    account: {
      type: 'AGENCY',
    },
    username: null,
    email: null,
    firstPassword: null,
    secondPassword: null,
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: 'onChange', defaultValues: defaultRegisterForm });

  const onSelectChange = value => {
    setValue('account.type', value, { shouldValidate: true });
  };

  const matchPassword = value => {
    return value === getValues('firstPassword');
  };

  const doRegister = data => {
    dispatch(handleRegister(data.username, data.email, data.firstPassword, currentLocale, data.account));
  };

  useEffect(() => {
    if (registerState.registrationSuccess) {
      reset(defaultRegisterForm);
    }
  }, [registerState.registrationSuccess]);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="auth-form">
      <PerfectScrollbar>
        <div className="auth-form-container">
          <div className="logo">
            <img src="content/images/quotass-logo.svg" alt="Logo" />
          </div>

          <Form>
            <Heading content={translate('register.title')} size={2} className="mb-1" />

            <FormRow>
              <FormItem label={translate('userManagement.type')}>
                <SelectInline
                  options={optionsRegisterType()}
                  selected={defaultRegisterForm.account.type}
                  mode="SINGLE"
                  onSelect={onSelectChange}
                  canDeselect={false}
                />
                <input type="hidden" {...register('account.type', validationSchema.required)} />
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem htmlFor="username" label={translate('global.form.username.label')}>
                <input
                  autoComplete="new-password"
                  className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                  id="username"
                  placeholder={translate('global.form.username.placeholder')}
                  {...register('username', validationSchema.userName)}
                />
                <FormError error={errors.username} validation={validationSchema.userName} />
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem htmlFor="email" label={translate('global.form.email.label')}>
                <input
                  autoComplete="new-password"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  name="email"
                  placeholder={translate('global.form.email.placeholder')}
                  {...register('email', validationSchema.email)}
                />
                <FormError error={errors.email} validation={validationSchema.email} />
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem htmlFor="firstPassword" label={translate('global.form.password.label')}>
                <input
                  autoComplete="new-password"
                  className={`form-control ${errors.firstPassword ? 'is-invalid' : ''}`}
                  id="firstPassword"
                  name="firstPassword"
                  placeholder={translate('global.form.password.placeholder')}
                  type="password"
                  {...register('firstPassword', validationSchema.password)}
                />
                <FormError error={errors.firstPassword} validation={validationSchema.password} />
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem htmlFor="secondPassword" label={translate('global.form.confirmpassword.label')}>
                <input
                  autoComplete="off"
                  className={`form-control ${errors.secondPassword ? 'is-invalid' : ''}`}
                  id="secondPassword"
                  name="secondPassword"
                  placeholder={translate('global.form.confirmpassword.placeholder')}
                  type="password"
                  {...register('secondPassword', {
                    ...validationSchema.confirmPassword,
                    validate: {
                      match: v => matchPassword(v),
                    },
                  })}
                />
                <FormError error={errors.secondPassword} validation={validationSchema.confirmPassword} />
              </FormItem>
            </FormRow>

            <FormRow>
              <Button className="full-width" size="md" color="primary" onClick={handleSubmit(doRegister)} disabled={!isValid}>
                {!registerState.loading ? <Translate contentKey="register.form.button">Register</Translate> : <Loader />}
              </Button>
              <div className="signUp-terms">{translate('privacy.signUp')}</div>
            </FormRow>
          </Form>

          <div className="auth-footer">
            Alredy registered?
            <Link to="/login" className="link primary ml-sm font-weight-bold">
              <Translate contentKey="login.title">Login</Translate>
            </Link>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default RegisterPage;
