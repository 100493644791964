import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Loader } from 'app/shared/components/loader';
import { IAddress } from 'app/shared/model/user.model';
import { IRootState } from 'app/shared/reducers';
import { handleModal } from 'app/shared/reducers/modals';
import { transform } from 'lodash';
import React from 'react';
import { translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAddress } from '../address.reducer';

type TAddressDelete = {
  address: IAddress;
};

export const AddressDelete = (props: TAddressDelete) => {
  const dispatch = useDispatch();

  const addressState = useSelector((state: IRootState) => state.addresses);

  const onConfirm = () => {
    dispatch(deleteAddress(props.address.id));
  };

  return (
    <>
      <p>{translate('userManagement.address.deleteQuestion')}</p>
      <Flex container>
        <Button size="md" color="white" onClick={() => dispatch(handleModal('deleteAddress', false))}>
          {translate('entity.action.cancel')}
        </Button>
        <Button size="md" color="primary" className="ml-auto" onClick={onConfirm}>
          {addressState.loading ? <Loader /> : translate('entity.action.confirm')}
        </Button>
      </Flex>
    </>
  );
};
