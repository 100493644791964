import React from 'react';
import { translate } from 'react-jhipster';

import { MenuItem } from 'app/shared/nav/menus/menu-item';

export const MenuAdmin = () => {
  return (
    <>
      {/* <MenuItem icon="costs" title={translate('global.menu.project.costs')} to={`/costs`} />
      <MenuItem icon="nodes" title={translate('global.menu.project.nodes')} to={`/nodes`} /> */}
    </>
  );
};
