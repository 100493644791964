import React, { useState } from 'react';

type NavSideProps = {
  children: any;
  isMenuOpen: boolean;
};

export const NavSide = (props: NavSideProps) => {
  const { isMenuOpen, children } = props;
  return (
    <div className={`Nav-side ${isMenuOpen ? 'isMenuOpen' : ''}`}>
      <div className="Nav-side-container">{children}</div>
    </div>
  );
};
