import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { IMe } from 'app/shared/model/user.model';
import { Avatar } from 'app/shared/components/avatar';
import { Icon } from 'app/shared/icons';

import { IRootState } from 'app/shared/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'app/shared/reducers/authentication';
import { Button } from 'app/shared/components/button';

export const MenuUser = () => {
  const me = useSelector((state: IRootState) => state.authentication.me);

  const userDropdown = useRef(null);
  const [isUserMenuOpen, setUserMenuOpen] = useState<boolean>(false);

  function handleClickOutside(event) {
    if (userDropdown && !userDropdown.current.contains(event.target)) {
      setUserMenuOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="Menu-user" ref={userDropdown}>
      <div onClick={() => setUserMenuOpen(!isUserMenuOpen)}>
        <UserPreview {...me} />
      </div>
      {isUserMenuOpen && <UserDropdownMenu me={me} onClick={() => setUserMenuOpen(false)} />}
    </div>
  );
};

const UserPreview = (props: IMe) => {
  const { account } = props;
  return (
    <div className="Menu-user-avatar">
      <Avatar avatar={account?.avatar} size="sm" />
    </div>
  );
};

type TUserDropdownMenu = {
  me: IMe;
  onClick: () => void;
};

const UserDropdownMenu = (props: TUserDropdownMenu) => {
  const { email, login, account } = props.me;

  const dispatch = useDispatch();

  const doLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="Menu-user-dropdown">
      <div className="user-meta">
        <Avatar avatar={account?.avatar} size="md" />
        <div className="ml-1">
          <div className="user-name">{login}</div>
          <div className="user-email"> {email} </div>
        </div>
      </div>

      <div className="dropdown-menu">
        <div className="user-sep"></div>

        <Link to="/me/settings" className="dropdown-menu-item menu-item" onClick={() => props.onClick()}>
          <Icon content="settings" />
          <Translate contentKey="global.menu.account.settings">Settings</Translate>
        </Link>

        <div className="dropdown-menu-item menu-item" onClick={doLogout}>
          <Icon content="logout" />
          <Translate contentKey="global.menu.account.logout">Sign out</Translate>
        </div>
      </div>
    </div>
  );
};
