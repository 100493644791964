import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Form, FormError, FormItem, FormRow, ReadOnly } from 'app/shared/components/form';
import { Heading } from 'app/shared/components/heading';
import { Icon } from 'app/shared/icons';
import React, { useState } from 'react';
import { translate } from 'react-jhipster';
import { Text } from 'app/shared/components/text';
import { IInsuree, IUser } from 'app/shared/model/user.model';
import { useForm } from 'react-hook-form';
import { Loader } from 'app/shared/components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { updateInsuree } from 'app/modules/insurees/insurees.reducer';
import { validationSchema } from 'app/shared/util/validations.utils';

type TMainForm = { type: 'USER' | 'INSUREE'; user: IUser | IInsuree };

export const MainForm = (props: TMainForm) => {
  const { type, user } = props;
  const [edit, setEdit] = useState<boolean>(false);
  const dispatch = useDispatch();
  const updateState = useSelector((state: IRootState) => state.insurees);

  const toggleEdit = () => {
    setEdit(!edit);
    reset(props.user);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: user,
  });

  const onUpdate = data => {
    if (type === 'INSUREE') {
      dispatch(updateInsuree(data));
    }
  };

  return (
    <>
      <div className="Settings-section">
        <Flex container alignItems="center" className="mb-1">
          <Heading content={translate('global.menu.account.general')} size={15} className="mb-1" />
          {!edit && (
            <Button className="ml-auto" size="sm" color="white" onClick={toggleEdit}>
              <Icon content="edit" />
            </Button>
          )}
        </Flex>

        <Form>
          <FormRow col="2">
            <FormItem label={translate('userManagement.email')}>
              {edit ? (
                <>
                  <input
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                    id="email"
                    name="email"
                    type="text"
                    placeholder={translate('userManagement.firstName')}
                    {...register('email', validationSchema.email)}
                  />
                  <FormError error={errors.email} validation={validationSchema.email} />
                </>
              ) : (
                <ReadOnly content={user.email} />
              )}
            </FormItem>
            <FormItem label={translate('userManagement.createdDate')}>
              <ReadOnly content={<Text type="date" format="DATETIME" content={user.createdDate} />} />
            </FormItem>
          </FormRow>

          {edit && (
            <FormRow>
              <FormItem>
                <Flex container alignItems="center">
                  <Button size="md" color="white" onClick={toggleEdit}>
                    {translate('entity.action.cancel')}
                  </Button>
                  <Button className="ml-auto" size="md" color="primary" onClick={handleSubmit(onUpdate)} disabled={!isValid}>
                    {updateState.loading ? <Loader /> : translate('entity.action.save')}
                  </Button>
                </Flex>
              </FormItem>
            </FormRow>
          )}
        </Form>
      </div>
    </>
  );
};
