import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import params, { ParamsState } from './params';

import wallets, { WalletState } from './account-wallets';
import refreshToken, { RefreshTokenState } from './refresh';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import users, { UsersState } from 'app/modules/users/users.reducer';
import insurees, { InsureesState } from 'app/modules/insurees/insurees.reducer';
import register, { RegisterState } from 'app/modules/me/register/register.reducer';
import verify, { VerifyState } from 'app/modules/me/verify/verify.reducer';
import settings, { SettingsState } from 'app/modules/me/me.reducer';
import account, { AccountState } from 'app/modules/me/account.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/me/password-reset/password-reset.reducer';
import modal, { ModalState } from './modals';
import addresses, { AddressesState } from '../forms/address/address.reducer';
import categories, { CategoriesState } from 'app/modules/categories/categories.reducer';
import areas, { AreasState } from 'app/modules/areas/areas.reducer';
import selectedCategories, { SelectedCategoriesState } from '../forms/categories-container/categories-container.reducer';
import selectedAreas, { SelectedAreasState } from '../forms/areas-container/areas-container.reducer';
import selectState, { SelectState } from '../select-items/select-items.reducer';
import products, { ProductsState } from 'app/modules/products/products.reducer';
import checkInsuree, { CheckInsureeState } from 'app/modules/insurees/check/insuree-check.reducer';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly params: ParamsState;
  readonly addresses: AddressesState;
  readonly wallets: WalletState;
  readonly refreshToken: RefreshTokenState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly users: UsersState;
  readonly insurees: InsureesState;
  readonly register: RegisterState;
  readonly verify: VerifyState;
  readonly passwordReset: PasswordResetState;
  readonly settings: SettingsState;
  readonly account: AccountState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
  readonly modal: ModalState;
  readonly categories: CategoriesState;
  readonly areas: AreasState;
  readonly selectedCategories: SelectedCategoriesState;
  readonly selectedAreas: SelectedAreasState;
  readonly selectState: SelectState;
  readonly products: ProductsState;
  readonly checkInsuree: CheckInsureeState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  params,
  addresses,
  wallets,
  refreshToken,
  locale,
  applicationProfile,
  administration,
  users,
  insurees,
  register,
  verify,
  passwordReset,
  settings,
  account,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  modal,
  categories,
  areas,
  selectedCategories,
  selectedAreas,
  selectState,
  products,
  checkInsuree,
});

export default rootReducer;
