import React from 'react';
import { TIcons } from 'app/config/types';
import Account from './icon-account.svg';
import Bell from './icon-bell.svg';
import MenuCollapse from './icon-menu-collapse.svg';
import MenuCollapsed from './icon-menu-collapsed.svg';
import Metrics from './icon-metrics.svg';
import Heart from './icon-heart.svg';
import Settings from './icon-settings.svg';
import Users from './icon-users.svg';
import Logs from './icon-logs.svg';
import Api from './icon-api.svg';
import Admin from './icon-admin.svg';
import ArrowDown from './icon-arrow-down.svg';
import ArrowUp from './icon-arrow-up.svg';
import ArrowLeft from './icon-arrow-left.svg';
import ArrowLeftDouble from './icon-arrow-left-double.svg';
import ArrowRight from './icon-arrow-right.svg';
import ArrowRightDouble from './icon-arrow-right-double.svg';
import SortList from './icon-sort-list.svg';
import SortListAsc from './icon-sort-list-asc.svg';
import SortListDesc from './icon-sort-list-desc.svg';
import Sort from './icon-sort.svg';
import Filter from './icon-filter.svg';
import Bin from './icon-bin.svg';
import Edit from './icon-edit.svg';
import Eye from './icon-eye.svg';
import Refresh from './icon-refresh.svg';
import Logout from './icon-logout.svg';
import Insuree from './icon-insuree.svg';
import Image from './icon-image.svg';
import Mail from './icon-mail.svg';
import Password from './icon-password.svg';
import Search from './icon-search.svg';
import Star from './icon-star.svg';
import StarSolid from './icon-star-solid.svg';
import DotsHor from './icon-dots-horizontal.svg';
import Quotations from './icon-quotations.svg';
import Home from './icon-home.svg';
import Transactions from './icon-transactions.svg';
import Costs from './icon-costs.svg';
import ArrowLeftThin from './icon-arrow-left-thin.svg';
import Close from './icon-close.svg';
import Payments from './icon-payments.svg';
import Invitation from './icon-invitation.svg';
import Checked from './icon-checked.svg';
import Products from './icon-products.svg';
import Categories from './icon-categories.svg';
import Zones from './icon-zones.svg';
import Chat from './icon-chat.svg';
import Wallet from './icon-wallet.svg';
import Download from './icon-download.svg';
import Upload from './icon-upload.svg';
import Add from './icon-add.svg';
import Areas from './icon-areas.svg';

const IconsMenu = {
  bell: Bell,
  metrics: Metrics,
  heart: Heart,
  settings: Settings,
  users: Users,
  logs: Logs,
  api: Api,
  admin: Admin,
};

const IconsCommon = {
  account: Account,
  menuCollapse: MenuCollapse,
  menuCollapsed: MenuCollapsed,
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  sortList: SortList,
  sort: Sort,
  sortAsc: SortListAsc,
  sortDesc: SortListDesc,
  filter: Filter,
  arrowLeft: ArrowLeft,
  arrowLeftDouble: ArrowLeftDouble,
  arrowRight: ArrowRight,
  arrowRightDouble: ArrowRightDouble,
  bin: Bin,
  edit: Edit,
  eye: Eye,
  refresh: Refresh,
  logout: Logout,
  insuree: Insuree,
  image: Image,
  mail: Mail,
  password: Password,
  search: Search,
  star: Star,
  starSolid: StarSolid,
  dotsHor: DotsHor,
  home: Home,
  transactions: Transactions,
  costs: Costs,
  arrowLeftThin: ArrowLeftThin,
  quotations: Quotations,
  close: Close,
  payments: Payments,
  invitation: Invitation,
  checked: Checked,
  products: Products,
  categories: Categories,
  zones: Zones,
  chat: Chat,
  wallet: Wallet,
  download: Download,
  upload: Upload,
  add: Add,
  areas: Areas,
};

const Icons = { ...IconsMenu, ...IconsCommon };

type TIconProps = {
  content: TIcons;
  className?: string;
};

export const Icon = (props: TIconProps) => {
  const Svg = Icons[props.content];
  return (
    <div className={`Icon ${props.className || ''}`}>
      <Svg />
    </div>
  );
};
