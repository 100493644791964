import { Avatar } from 'app/shared/components/avatar';
import Price from 'app/shared/components/price';
import { Icon } from 'app/shared/icons';
import { IProduct } from 'app/shared/model/products.model';
import React from 'react';
import { translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import { Text } from 'app/shared/components/text';
import ProductImage from '../product-image';

type ProductsCardProps = {
  product: IProduct;
  className?: string;
};

export const ProductsCardAdmin = (props: ProductsCardProps) => {
  const { product, className } = props;

  return (
    <tr className={`table-item ${className || ''} `}>
      <td>
        <Link to={`products/${product.id}`}>
          <ProductImage image={product.avatar} size="sm" />
        </Link>
      </td>

      <td>{product.title} </td>
      <td>
        <div className={`status-badge ${product.status}`}>{translate(`products.search.tabs.${product.status}`)}</div>
      </td>
      <td>
        <Price amount={product.price} />
      </td>
      <td>{product.createdDate ? <Text type="date" format="DATETIME" content={product.createdDate} /> : '-'}</td>
      <td>{product.lastModifiedDate ? <Text type="date" format="DATETIME" content={product.lastModifiedDate} /> : '-'}</td>

      <td className="text-right">
        <div className="button-group">
          <Link to={`products/${product.id}`} className="button sm white">
            <Icon content="arrowRight" />
          </Link>
        </div>
      </td>
    </tr>
  );
};
