import React from 'react';
import { avatarDto } from 'app/shared/model/user.model';

type TAvatar = {
  avatar?: avatarDto;
  size: 'sm' | 'md' | 'lg' | 'xl';
};

export const Avatar = (props: TAvatar) => {
  const { avatar, size } = props;

  const contents = avatar?.fileContent?.contents;
  const type = avatar?.fileContent?.contentType;

  const image = contents && type ? `data:${type};base64,${contents}` : 'content/images/default-avatar.png';

  return (
    <div className={`Avatar ${size}`}>
      <img src={image} />
    </div>
  );
};
