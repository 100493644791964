import React from 'react';
import { MenuItem } from 'app/shared/nav/menus/menu-item';
import { translate } from 'react-jhipster';
import { MenuSystem, MenuAdmin } from 'app/shared/nav/menus';

import { useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

const MenuPrimary = () => {
  const me = useSelector((state: IRootState) => state.authentication.me);
  const isOpenAPIEnabled = useSelector((state: IRootState) => state.applicationProfile.isOpenAPIEnabled);

  return (
    <>
      <MenuItem icon="home" title={translate('global.menu.items.dashboard')} to="/" />
      <MenuItem icon="insuree" title={translate('global.menu.items.insurees')} to="/insurees" />
      <MenuItem icon="users" title={translate('global.menu.items.users')} to="/users" />
      <MenuItem icon="quotations" title={translate('global.menu.items.quotations')} to="/quotations" />
      <MenuItem icon="payments" title={translate('global.menu.items.payments')} to="/payments" />
      <MenuItem icon="products" title={translate('global.menu.items.products')} to="/products" />
      <MenuItem icon="categories" title={translate('global.menu.items.categories')} to="/categories" />
      <MenuItem icon="areas" title={translate('global.menu.items.areas')} to="/areas" />
      <MenuItem icon="chat" title={translate('global.menu.items.chats')} to="/chats" />

      {hasAnyAuthority(me.authorities, [AUTHORITIES.SYSTEM, AUTHORITIES.ADMIN]) && <MenuAdmin />}
      {hasAnyAuthority(me.authorities, [AUTHORITIES.SYSTEM]) && (
        <>
          <MenuItem icon="users" title={translate('global.menu.project.members')} to="/admin/user-management" />
          <MenuSystem {...isOpenAPIEnabled} />
        </>
      )}
    </>
  );
};

export default MenuPrimary;
