import { Button } from 'app/shared/components/button';
import { Icon } from 'app/shared/icons';
import { ICategory } from 'app/shared/model/categories-zones.model';
import { subtract } from 'lodash';
import React, { useState } from 'react';

type CategoryCardProps = {
  category: ICategory;
  subcategories?: ICategory[];
};

export const CategoryCard = (props: CategoryCardProps) => {
  const { category, subcategories } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div className={`category-card ${isExpanded ? 'expanded' : ''}`}>
      <div className="category-parent" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="category-details">{category.name}</div>
        <Button size="sm" color="super-light-grey">
          {isExpanded ? <Icon content="arrowUp" /> : <Icon content="arrowDown" />}
        </Button>
      </div>
      {isExpanded && (
        <div className="category-child">
          {subcategories.length === 0 && 'No subcategories'}
          {subcategories.length > 0 &&
            subcategories
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(subcategory => (
                <div key={subcategory.id} className="subcategory-card">
                  {subcategory.name}
                </div>
              ))}
        </div>
      )}
    </div>
  );
};
