import { TTableListHeaderItem } from 'app/shared/components/table-list';
import { translate } from 'react-jhipster';

export type TSearchTabs = 'ALL' | 'DRAFT' | 'PUBLISHED';
export type TOrder = 'ASC' | 'DESC';
export type TSort = 'createdDate';

export const productsSearchTabs: () => { [key in TSearchTabs]: string } = () => {
  return {
    ALL: translate('products.search.tabs.ALL'),
    DRAFT: translate('products.search.tabs.DRAFT'),
    PUBLISHED: translate('products.search.tabs.PUBLISHED'),
  };
};

export const defaultProductsSearchParams = {
  postPerPage: 10,
  currentPage: 0,
  sortBy: 'createdDate' as TSort,
  sortDirection: 'DESC' as TOrder,
  tab: 'ALL' as TSearchTabs,
};

export type TdefaultProductsSearchParams = Readonly<typeof defaultProductsSearchParams>;

export const genProductSearchParams = (params: TdefaultProductsSearchParams) => {
  return {
    currentPage: params.currentPage,
    postPerPage: params.postPerPage,
    sortBy: [params.sortBy],
    sortDirection: params.sortDirection,
    status: params.tab === 'ALL' ? null : params.tab,
    areas: [],
    categories: [],
  };
};

export const genProductCountSearchParams = (params: TdefaultProductsSearchParams) => {
  return {
    status: params.tab === 'ALL' ? null : params.tab,
  };
};

export const getProductsSearchTableHeader = sort => {
  return [
    {},
    {
      id: 'title',
      name: translate('products.labels.title'),
      onClick: sort('title'),
    },
    {
      name: translate('products.labels.status'),
    },
    {
      id: 'price',
      name: translate('products.labels.price'),
      onClick: sort('price'),
    },
    {
      id: 'createdDate',
      name: translate('products.labels.createdDate'),
      onClick: sort('createdDate'),
    },
    {
      id: 'lastModifiedDate',
      name: translate('products.labels.lastModifiedDate'),
      onClick: sort('lastModifiedDate'),
    },
    {},
  ] as TTableListHeaderItem[];
};
