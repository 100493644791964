export const isAccountCompleted = account => {
  if (account.authority !== 'ROLE_VIEWER') {
    return account.userAccountDTO?.firstName || account.userAccountDTO?.companyName ? true : false;
  }
  return true;
};

export const getOptionLabel = (array: any[], value) => {
  const selectedItem = array.filter(type => type.value === value);
  if (selectedItem.length > 0) {
    return selectedItem[0].label;
  }

  return '';
};
