import React from 'react';
import { Icon } from 'app/shared/icons';
import { Translate } from 'react-jhipster';
import { Flex } from 'app/shared/components/flex';
import './table-list.scss';

type TTableList = {
  children: any;
  className?: string;
};

export const TableList = (props: TTableList) => {
  const { children, className } = props;
  return (
    <div className={`Table-list ${className || ''}`}>
      <table>{children}</table>
    </div>
  );
};

type TTableListHeader = {
  items: TTableListHeaderItem[];
  sortBy?: string;
  sortDirection?: string;
};

export type TTableListHeaderItem = {
  id?: string;
  name?: string;
  onClick?: (param?: string) => void;
};

export const TableListHeader = (props: TTableListHeader) => {
  const { items, sortBy, sortDirection } = props;

  return (
    <thead>
      <tr>
        {items.map((item, i) => {
          const isActive = item.id === sortBy;
          return (
            <th key={i}>
              <div>
                {item.name && (
                  <>
                    <span>{item.name}</span>
                    {item.onClick && (
                      <div className={`handle-sort ${isActive ? 'active' : ''}`} onClick={() => item.onClick('id')}>
                        {!sortDirection || (sortDirection && !isActive && <Icon content="sortList" />)}
                        {sortDirection && isActive && (sortDirection === 'ASC' ? <Icon content="sortAsc" /> : <Icon content="sortDesc" />)}
                      </div>
                    )}
                  </>
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

type TTableListBody = {
  children: any;
};

export const TableListBody = (props: TTableListBody) => {
  const { children } = props;
  return <tbody>{children}</tbody>;
};

type TTableListPagination = {
  activePage: number;
  itemsPerPage: number;
  totalItems: number;
  paginate: any;
};

export const TableListPagination = (props: TTableListPagination) => {
  const { activePage, itemsPerPage, totalItems, paginate } = props;
  const lastPage = Math.ceil(totalItems / itemsPerPage);

  const goToFirstPage = () => {
    paginate(0);
  };
  const goToPrevPage = () => {
    paginate(activePage - 1);
  };
  const goToNextPage = () => {
    paginate(activePage + 1);
  };
  const goToLastPage = () => {
    paginate(lastPage - 1);
  };

  if (!totalItems || totalItems <= itemsPerPage) {
    return null;
  }

  return (
    <Flex container justifyContent="center" alignItems="center" className="Table-list-pagination">
      <button className="button solid white circle sm" disabled={activePage === 0} onClick={goToFirstPage}>
        <Icon content="arrowLeftDouble"></Icon>
      </button>

      <button className="button solid white circle sm mr-4" disabled={activePage === 0} onClick={goToPrevPage}>
        <Icon content="arrowLeft"></Icon>
      </button>

      <div className="pagination-position">
        <b>{activePage + 1}</b> <Translate contentKey="table.paginate.of">of</Translate> {lastPage}
      </div>

      <button className="button solid white circle sm ml-4" disabled={activePage === lastPage - 1} onClick={goToNextPage}>
        <Icon content="arrowRight"></Icon>
      </button>

      <button className="button solid white circle sm" disabled={activePage === lastPage - 1} onClick={goToLastPage}>
        <Icon content="arrowRightDouble"></Icon>
      </button>
    </Flex>
  );
};
