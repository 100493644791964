export const getParamsFromUrl = (defaultParams, location) => {
  if (!location.search) {
    return defaultParams;
  }

  const searchParams = new URLSearchParams(location.search);

  const paramsObj = {};
  Object.keys(defaultParams).forEach(item => {
    const value = searchParams.get(item);
    const isNumber = typeof defaultParams[item] === 'number';
    paramsObj[item] = isNumber ? parseInt(value, 10) : value;
  });

  return paramsObj;
};

export const setParamsToUrl = (params, history) => {
  let urlParams = '';

  Object.entries(params).forEach(([key, value]) => {
    if (value || value === 0) {
      urlParams += `${urlParams === '' ? '?' : '&'}${key}=${value}`;
    }
  });

  if (window.location.search !== urlParams) {
    history.push(`${window.location.pathname}${urlParams}`);
  }
};
