import React, { useState, useEffect } from 'react';
import { Heading } from 'app/shared/components/heading';
import { translate } from 'react-jhipster';
import { useForm } from 'react-hook-form';
import { Form, FormItem, FormError, FormRow } from 'app/shared/components/form/form';
import { Button } from 'app/shared/components/button';
import { Icon } from 'app/shared/icons';

import { Flex } from 'app/shared/components/flex';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { updateMe } from 'app/modules/me/me.reducer';
import { Loader } from 'app/shared/components/loader';
import { validationSchema } from 'app/shared/util/validations.utils';

export const Email = () => {
  const dispatch = useDispatch();
  const me = useSelector((state: IRootState) => state.authentication.me);
  const settings = useSelector((state: IRootState) => state.settings);
  const [clicked, setCLicked] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange', defaultValues: me });

  const [edit, setEdit] = useState<boolean>(false);

  const updateEmail = data => {
    setCLicked(true);
    dispatch(updateMe(data));
  };

  const toggleEdit = () => {
    setEdit(!edit);
    reset(me);
  };

  useEffect(() => {
    if (!settings.loading && clicked && settings.updateSuccess) {
      setCLicked(false);
      setEdit(false);
    }
  }, [settings]);

  return (
    <div className="Settings-section" id="email">
      <Flex container alignItems="center" className="mb-1">
        <Heading content={translate('global.menu.account.email')} size={15} />
        {!edit && (
          <Button className="ml-auto" size="sm" color="white" onClick={toggleEdit}>
            <Icon content="edit" />
          </Button>
        )}
      </Flex>
      <Form>
        {edit && (
          <>
            <FormRow>
              <FormItem>
                <input
                  autoComplete="off"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  placeholder={translate('global.form.email.placeholder')}
                  {...register('email', validationSchema.email)}
                  defaultValue={me.email}
                />
                <FormError error={errors.email} validation={validationSchema.email} />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <Flex container alignItems="center">
                  <Button size="md" color="white" onClick={toggleEdit}>
                    {translate('entity.action.cancel')}
                  </Button>
                  <Button
                    className="ml-auto"
                    size="md"
                    color="primary"
                    onClick={handleSubmit(updateEmail)}
                    disabled={!isValid || settings.loading}
                  >
                    {settings.loading ? <Loader /> : translate('entity.action.save')}
                  </Button>
                </Flex>
              </FormItem>
            </FormRow>
          </>
        )}
        {!edit && (
          <>
            {translate('account.currentEmail')} <b>{me.email}</b>
          </>
        )}
      </Form>
    </div>
  );
};
