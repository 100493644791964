import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IInsuree } from 'app/shared/model/user.model';
import { translate } from 'react-jhipster';

export const ACTION_TYPES = {
  FETCH_INSUREES: 'insurees/FETCH_INSUREES',
  FETCH_INSUREES_COUNTS: 'insurees/FETCH_INSUREES_COUNTS',
  FETCH_INSUREE: 'insurees/FETCH_INSUREE',
  CREATE_INSUREE: 'insurees/CREATE_INSUREE',
  UPDATE_INSUREE: 'insurees/UPDATE_INSUREE',
  CLEAR_INSUREE: 'insurees/CLEAR_INSUREE',
  RESET: 'insurees/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  insurees: [] as IInsuree[],
  insuree: null as IInsuree,
  updating: false,
  fetchSuccess: false,
  fetchError: false,
  totalItems: 0,
  count: null as any,
};

export type InsureesState = Readonly<typeof initialState>;

// Reducer
export default (state: InsureesState = initialState, action): InsureesState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INSUREES):
    case REQUEST(ACTION_TYPES.FETCH_INSUREES_COUNTS):
    case REQUEST(ACTION_TYPES.FETCH_INSUREE):
    case REQUEST(ACTION_TYPES.CREATE_INSUREE):
    case REQUEST(ACTION_TYPES.UPDATE_INSUREE):
      return {
        ...state,
        errorMessage: null,
        fetchSuccess: false,
        loading: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_INSUREES):
    case FAILURE(ACTION_TYPES.FETCH_INSUREES_COUNTS):
    case FAILURE(ACTION_TYPES.FETCH_INSUREE):
    case FAILURE(ACTION_TYPES.CREATE_INSUREE):
    case FAILURE(ACTION_TYPES.UPDATE_INSUREE):
      return {
        ...state,
        loading: false,
        updating: false,
        fetchSuccess: false,
        fetchError: true,
        errorMessage: action.payload,
      };

    case SUCCESS(ACTION_TYPES.FETCH_INSUREES):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
        insurees: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_INSUREES_COUNTS):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
        count: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INSUREE):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
        insuree: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_INSUREE):
    case SUCCESS(ACTION_TYPES.UPDATE_INSUREE):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
        insuree: action.payload.data,
      };

    case ACTION_TYPES.CLEAR_INSUREE:
      return {
        ...initialState,
        insuree: null,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/insurees';
// Actions

export const getInsurees = params => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.FETCH_INSUREES,
    payload: axios.post(`${apiUrl}/search`, params),
  });
};
export const getInsureesCount = params => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.FETCH_INSUREES_COUNTS,
    payload: axios.post(`${apiUrl}/counts`, params),
    meta: 'HIDE_NOTIFICATIONS',
  });
};

export const getInsuree = id => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.FETCH_INSUREE,
    payload: axios.get(`${apiUrl}/${id}`),
  });
};

export const updateInsuree = insuree => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_INSUREE,
    payload: axios.patch(`${apiUrl}/${insuree.id}`, insuree),
  });
};

export const createInsuree = insuree => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.CREATE_INSUREE,
    payload: axios.post(`${apiUrl}`, insuree),
    meta: {
      successMessage: translate('insurees.add.success'),
    },
  });
};

export const clearInsuree: () => void = () => {
  return {
    type: ACTION_TYPES.CLEAR_INSUREE,
  };
};
