import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { IUser } from 'app/shared/model/user.model';
import { Translate, translate } from 'react-jhipster';
import { Flex } from 'app/shared/components/flex';
import { Button } from 'app/shared/components/button';
import { Loader } from 'app/shared/components/loader';
import { Form, FormItem, FormRow } from 'app/shared/components/form';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { deleteUser } from '../../users.reducer';
import { handleModal } from 'app/shared/reducers/modals';

type UserDeleteProps = {
  user: IUser;
};

export const DeleteUser = (props: UserDeleteProps) => {
  const { user } = props;
  const history = useHistory();

  const dispatch = useDispatch();
  const userState = useSelector((state: IRootState) => state.users);

  const onDelete = () => {
    dispatch(deleteUser(user.login));
  };

  useEffect(() => {
    if (userState.fetchSuccess) {
      history.push('/admin/user-management');
    }
  }, [userState.fetchSuccess]);

  return (
    <>
      <Form>
        <FormRow>
          <FormItem>
            <Translate contentKey="userManagement.delete.question" interpolate={{ login: user.login }}>
              Are you sure you want to delete this User?
            </Translate>
          </FormItem>
        </FormRow>

        <FormRow>
          <FormItem>
            <Flex container>
              <Button size="md" color="white" onClick={() => dispatch(handleModal('deleteUser', false))}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button size="md" color="primary" className="ml-auto" disabled={userState.updating} onClick={onDelete}>
                {!userState.updating ? translate('entity.action.confirm') : <Loader />}
              </Button>
            </Flex>
          </FormItem>
        </FormRow>
      </Form>
    </>
  );
};
