import React, { useState } from 'react';

export const Burger = props => {
  return (
    <div className={`${props.className} burger-menu ${props.state ? 'isOpen' : ''} `} onClick={() => props.click()}>
      <div className="dish">
        <div className="burger-item bread-top"></div>
        <div className="burger-item meat"></div>
        <div className="burger-item bread-bottom"></div>
      </div>
    </div>
  );
};
