import { translate } from 'react-jhipster';
import dayjs from 'dayjs';
import { APP_TIMESTAMP_FORMAT } from 'app/config/constants';

type ActiveParams = {
  label: string;
  value: any;
  key: string;
};

type ActiveKeys = 'from' | 'to' | 'name' | 'login' | 'email' | 'rui' | 'type' | 'typeInsuree' | 'overDraft' | 'taxCode';
type ActiveKeysTypes = 'DATE' | 'TEXT' | 'ENUM';

const baseParams: { [key in ActiveKeys]: ActiveKeysTypes } = {
  from: 'DATE',
  to: 'DATE',
  name: 'TEXT',
  login: 'TEXT',
  email: 'TEXT',
  rui: 'TEXT',
  type: 'ENUM',
  typeInsuree: 'ENUM',
  overDraft: 'ENUM',
  taxCode: 'TEXT',
};

export const getActiveParams = params => {
  let activeParams = [];

  Object.entries(params).forEach(([key, val]) => {
    if (val && key in baseParams) {
      activeParams = [...activeParams, { label: translate(`filter.labels.${key}`), value: getParamValue(baseParams[key], val), key }];
    }
  });

  return activeParams as ActiveParams[];
};

export const getParamValue = (type: ActiveKeysTypes, value: any) => {
  if (type === 'DATE') {
    return dayjs(value).format(APP_TIMESTAMP_FORMAT);
  }
  if (type === 'ENUM') {
    return translate(`filter.enum.${value}`);
  }
  return value;
};
