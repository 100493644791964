import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { getProduct } from 'app/modules/products/products.reducer';

export const ACTION_TYPES = {
  FETCH_USER_AREAS: 'areas/FETCH_USER_AREAS',
  FETCH_PRODUCT_AREAS: 'areas/FETCH_PRODUCT_AREAS',
  RESET: 'select/RESET',
};

const initialState = {
  loading: false,
  fetchSuccess: false,
  fetchError: false,
  areas: null as any,
};

export type SelectedAreasState = Readonly<typeof initialState>;

// Reducer
export default (state: SelectedAreasState = initialState, action): SelectedAreasState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_USER_AREAS):
    case REQUEST(ACTION_TYPES.FETCH_PRODUCT_AREAS):
      return {
        ...state,
        fetchSuccess: false,
        loading: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_USER_AREAS):
    case FAILURE(ACTION_TYPES.FETCH_PRODUCT_AREAS):
      return {
        ...state,
        loading: false,
        fetchSuccess: false,
        fetchError: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_USER_AREAS):
    case SUCCESS(ACTION_TYPES.FETCH_PRODUCT_AREAS):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
        areas: action.payload.data,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
// USERS

export const getUserAreas = (login: string) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.FETCH_USER_AREAS,
    payload: axios.get(`api/areas/users/${login}`),
  });
};

export const getProductAreas = (id: number) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.FETCH_PRODUCT_AREAS,
    payload: axios.get(`api/areas/products/${id}`),
  });
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
