import React from 'react';
import { TMenuItem } from 'app/config/types';
import { Icon } from 'app/shared/icons';
import { Link, useLocation } from 'react-router-dom';

export const MenuItem = (props: TMenuItem) => {
  const { icon, to, title } = props;
  const location = useLocation();

  const isActive = to === '/' ? location.pathname === to : location.pathname.startsWith(to);

  return (
    <Link to={to} className={`menu-item  ${isActive ? 'active' : ''} `}>
      <Icon content={icon} />
      <span className="hideIfMenuIsCollapsed">{title}</span>
    </Link>
  );
};
