import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, translate } from 'react-jhipster';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultUser, IUser } from 'app/shared/model/user.model';
import { handleModal } from 'app/shared/reducers/modals';

export const ACTION_TYPES = {
  FETCH_USERS: 'users/FETCH_USERS',
  FETCH_USERS_COUNTS: 'users/FETCH_USERS_COUNTS',
  FETCH_USER: 'users/FETCH_USER',
  CREATE_USER: 'users/CREATE_USER',
  UPDATE_USER: 'users/UPDATE_USER',
  DELETE_USER: 'users/DELETE_USER',
  CLEAR_USER: 'users/CLEAR_USER',
  RESET: 'users/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  users: [] as IUser[],
  user: null as IUser,
  updating: false,
  fetchSuccess: false,
  fetchError: false,
  totalItems: 0,
  count: null as any,
};

export type UsersState = Readonly<typeof initialState>;

// Reducer
export default (state: UsersState = initialState, action): UsersState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_USERS):
    case REQUEST(ACTION_TYPES.FETCH_USERS_COUNTS):
    case REQUEST(ACTION_TYPES.FETCH_USER):
      return {
        ...state,
        errorMessage: null,
        fetchSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_USER):
    case REQUEST(ACTION_TYPES.UPDATE_USER):
    case REQUEST(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        errorMessage: null,
        fetchSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_USERS):
    case FAILURE(ACTION_TYPES.FETCH_USERS_COUNTS):
    case FAILURE(ACTION_TYPES.FETCH_USER):
    case FAILURE(ACTION_TYPES.CREATE_USER):
    case FAILURE(ACTION_TYPES.UPDATE_USER):
    case FAILURE(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        loading: false,
        updating: false,
        fetchSuccess: false,
        fetchError: true,
        errorMessage: action.payload,
      };

    case SUCCESS(ACTION_TYPES.FETCH_USERS):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
        users: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERS_COUNTS):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
        count: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USER):
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_USER):
    case SUCCESS(ACTION_TYPES.UPDATE_USER):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        user: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        user: defaultUser,
      };
    case ACTION_TYPES.CLEAR_USER:
      return {
        ...initialState,
        user: null,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const adminUrl = 'api/admin/users';
// Actions
export const getUsers = params => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.FETCH_USERS,
    payload: axios.post(`${adminUrl}/search`, params),
  });
};
export const getUsersCount = params => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.FETCH_USERS_COUNTS,
    payload: axios.post(`${adminUrl}/counts`, params),
    meta: 'HIDE_NOTIFICATIONS',
  });
};

export const getUser = (login: string) => async (dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.FETCH_USER,
    payload: axios.get<IUser>(`${adminUrl}/${login}`),
  });
};

export const clearUser: () => void = () => {
  return {
    type: ACTION_TYPES.CLEAR_USER,
  };
};

export const updateUser = user => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_USER,
    payload: axios.put(adminUrl, user),
    meta: {
      successMessage: translate('userManagement.activate.success', { login: user.login }),
    },
  });
  dispatch(handleModal('activateUser', false));
  // dispatch(getUsers());
};

export const createUser: ICrudPutAction<IUser> = user => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USER,
    payload: axios.post(adminUrl, user),
  });
  return result;
};

export const deleteUser: ICrudDeleteAction<IUser> = id => async dispatch => {
  const requestUrl = `${adminUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USER,
    payload: axios.delete(requestUrl),
  });
  // dispatch(getUsers());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
