import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { translate } from 'react-jhipster';
import { handleModal } from 'app/shared/reducers/modals';
import { getInsurees } from '../insurees.reducer';
import { defaultInsureesSearchParams } from '../insurees.utils';

export const ACTION_TYPES = {
  CHECK_INSUREES: 'insurees/CHECK_INSUREES',
  LINK_INSUREES: 'insurees/LINK_INSUREES',
  UNLINK_INSUREES: 'insurees/UNLINK_INSUREES',
  RESET_CHECK: 'insurees/RESET_CHECK',
};

const initialState = {
  checking: false,
  checkSuccess: false,
  checkError: false,
  exists: null,
  linking: false,
  linkSuccess: false,
  linkError: false,
  unlinking: false,
  unlinkSuccess: false,
  unlinkError: false,
};

export type CheckInsureeState = Readonly<typeof initialState>;

// Reducer
export default (state: CheckInsureeState = initialState, action): CheckInsureeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CHECK_INSUREES):
      return {
        ...state,
        checking: true,
      };
    case REQUEST(ACTION_TYPES.LINK_INSUREES):
      return {
        ...state,
        linking: true,
      };
    case REQUEST(ACTION_TYPES.UNLINK_INSUREES):
      return {
        ...state,
        unlinking: true,
      };

    case FAILURE(ACTION_TYPES.CHECK_INSUREES):
      return {
        ...state,
        checking: false,
        checkSuccess: false,
        checkError: true,
      };

    case FAILURE(ACTION_TYPES.LINK_INSUREES):
      return {
        ...state,
        linking: false,
        linkSuccess: false,
        linkError: true,
      };

    case FAILURE(ACTION_TYPES.UNLINK_INSUREES):
      return {
        ...state,
        unlinking: false,
        unlinkSuccess: false,
        unlinkError: true,
      };

    case SUCCESS(ACTION_TYPES.CHECK_INSUREES):
      return {
        ...state,
        checking: false,
        checkError: false,
        checkSuccess: true,
        exists: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.LINK_INSUREES):
      return {
        ...state,
        linking: false,
        linkError: false,
        linkSuccess: true,
      };

    case SUCCESS(ACTION_TYPES.UNLINK_INSUREES):
      return {
        ...state,
        unlinking: false,
        unlinkError: false,
        unlinkSuccess: true,
      };

    case ACTION_TYPES.RESET_CHECK:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions

export const onCheckInsuree = (taxCode: string) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.CHECK_INSUREES,
    payload: axios.get(`api/insurees/exists/${taxCode}`),
  });
};

export const onLinkInsuree = (id: number) => async (dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.LINK_INSUREES,
    payload: axios.post(`api/insurees/${id}/users/link`),
    meta: {
      successMessage: translate('insurees.link.success'),
    },
  });
  dispatch(handleModal('insureeCheck', false));
  dispatch(getInsurees(getState().params));
};

export const onUnlinkInsuree = (id: number, history) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.UNLINK_INSUREES,
    payload: axios.post(`api/insurees/${id}/users/unlink`),
    meta: {
      successMessage: translate('insurees.unlink.success'),
    },
  });
  dispatch(handleModal('unlinkInsuree', false));
  history.push('/insurees');
};

export const resetCheck: () => void = () => {
  return {
    type: ACTION_TYPES.RESET_CHECK,
  };
};
