import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import UserDetail from './view';
import UsersNew from './new';
import UsersSearch from './search';
import PageNotFound from 'app/shared/error/page-not-found';

const Routes = () => {
  return (
    <>
      <Switch>
        <ErrorBoundaryRoute exact path={'/users/new'} component={UsersNew} />
        <ErrorBoundaryRoute exact path={'/users/:login'} component={UserDetail} />
        <ErrorBoundaryRoute path="/users" component={UsersSearch} />
        <ErrorBoundaryRoute component={PageNotFound} />
      </Switch>
    </>
  );
};

export default Routes;
