import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { Link } from 'react-router-dom';
import { clearUser, getUser } from '../users.reducer';
import { useParams } from 'react-router-dom';

import { Translate, translate } from 'react-jhipster';

import { Loader } from 'app/shared/components/loader';
import { Alert } from 'app/shared/components/alert';

import { PageHeader } from 'app/shared/components/page';
import { Form, FormItem, FormRow, ReadOnly } from 'app/shared/components/form/form';
import { Text } from 'app/shared/components/text';
import { Back } from 'app/shared/components/back';
import { Dropdown, DropdownContainer, DropdownItem, DropdownToogle } from 'app/shared/components/dropdown';
import { Icon } from 'app/shared/icons';
import { Button } from 'app/shared/components/button';
import { Modal } from 'app/shared/components/modal';
import { Account } from 'app/shared/forms/account/account';
import { Flex } from 'app/shared/components/flex';
import { getUserStatus } from '../user.utils';
import { Heading } from 'app/shared/components/heading';
import ActivateUser from './activate-user';
import DeleteUser from './delete-user';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { COLORS } from 'app/config/constants';
import { AvatarUpload } from 'app/shared/components/avatar-upload';
import { handleModal } from 'app/shared/reducers/modals';
import Address from 'app/shared/forms/address';
import CategoriesContainer from 'app/shared/forms/categories-container';
import AreasContainer from 'app/shared/forms/areas-container';

export const UserDetail = () => {
  const { login }: any = useParams();

  const dispatch = useDispatch();
  const userState = useSelector((state: IRootState) => state.users);
  const me = useSelector((state: IRootState) => state.authentication.me);

  const { user } = userState;
  const status = user && getUserStatus(user);
  const canManage = user && hasAnyAuthority(me.authorities, ['ROLE_ADMIN', 'ROLE_SYSTEM']);
  const isRoleUser = user && hasAnyAuthority(user.authorities, ['ROLE_USER']);

  const modalState = useSelector((state: IRootState) => state.modal);

  useEffect(() => {
    dispatch(getUser(login));

    console.log('DETAILS');

    return () => {
      dispatch(clearUser());
    };
  }, []);

  return (
    <div className="container xl">
      <Back />

      <div className="Settings">
        <PageHeader title={translate('userManagement.detail.title')}>
          {!userState.loading && !userState.errorMessage && user && (
            <>
              <Flex container alignItems="center" className="ml-auto">
                {canManage && (status === 'PENDING' || status === 'ACTIVATED') && (
                  <Button size="md" color="primary" onClick={() => dispatch(handleModal('activateUser', true))}>
                    {status === 'PENDING' ? 'Activate' : 'Deactivate'}
                  </Button>
                )}

                <Dropdown className="ml-1">
                  <DropdownToogle>
                    <Button size="md" color="white">
                      <Icon content="dotsHor" />
                    </Button>
                  </DropdownToogle>
                  <DropdownContainer>
                    <DropdownItem onClick={() => dispatch(handleModal('deleteUser', true))}>
                      <Translate contentKey="entity.action.delete">delete</Translate>
                    </DropdownItem>
                  </DropdownContainer>
                </Dropdown>
              </Flex>
            </>
          )}
        </PageHeader>

        {userState.loading && !user && <Loader color={COLORS.PRIMARY} />}
        {userState.errorMessage && !user && <Alert type="error" message={translate('error.loadingData')} />}

        {user && (
          <>
            <div className="Settings-container">
              <div className="Settings-left-col">
                <AvatarUpload account={user.account} canEdit={false} />
              </div>
              <div className="Settings-right-col">
                <Form className="Settings-section">
                  <Heading content={translate('global.menu.account.general')} size={15} className="mb-1" />
                  <FormRow>
                    <FormItem label={translate('userManagement.status')} className="col-50 col-sm-100">
                      <ReadOnly content={<div className={`status-badge ${status}`}>{translate(`userManagement.tabs.${status}`)}</div>} />
                    </FormItem>
                    <FormItem label={translate('userManagement.createdDate')} className="col-50 col-sm-100">
                      <ReadOnly content={<Text type="date" format="DATETIME" content={user.createdDate} />} />
                    </FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem label={translate('userManagement.username')} className="col-50 col-sm-100">
                      <ReadOnly content={user.login} />
                    </FormItem>
                    <FormItem label={translate('userManagement.email')} className="col-50 col-sm-100">
                      <ReadOnly content={user.email} />
                    </FormItem>
                  </FormRow>
                </Form>

                {isRoleUser && <Account account={user.account} type="USER" canEdit={status !== 'UNVERIFIED'} />}
                {isRoleUser && <Address email={user.email} type="USER" />}
                {isRoleUser && <CategoriesContainer type="USER" login={user.login} mode="EDIT" />}
                {isRoleUser && <AreasContainer type="USER" login={user.login} mode="EDIT" />}
              </div>
            </div>

            {modalState.deleteUser && (
              <Modal size="xs" close={() => dispatch(handleModal('deleteUser', false))} title={translate('userManagement.delete.title')}>
                <DeleteUser user={user} />
              </Modal>
            )}

            {modalState.activateUser && (
              <Modal
                size="xs"
                close={() => dispatch(handleModal('activateUser', false))}
                title={translate(`userManagement.${status === 'PENDING' ? 'activate' : 'deactivate'}.title`)}
              >
                <ActivateUser user={user} />
              </Modal>
            )}
          </>
        )}
      </div>
    </div>
  );
};
