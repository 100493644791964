import { Back } from 'app/shared/components/back';
import { PageHeader } from 'app/shared/components/page';
import AccountNew from 'app/shared/forms/account-new';
import React from 'react';
import { translate } from 'react-jhipster';

export const InsureeNew = () => {
  return (
    <>
      <div className="container sm">
        <Back />

        <PageHeader title={translate('insurees.search.addInsuree')} />

        <AccountNew type="INSUREE" />
      </div>
    </>
  );
};
