import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { TAuthorities } from 'app/shared/model/user.model';
import { useSelector } from 'react-redux';

type PrivateRouteProps = {
  component: any;
  path: string;
  hasAnyAuthorities?: TAuthorities[];
};

export const PrivateRoute = (props: PrivateRouteProps & RouteProps) => {
  const authentication = useSelector((state: IRootState) => state.authentication);

  const { hasAnyAuthorities } = props;

  const Component = props.component;

  const checkAuthorities = () => {
    const isAuthorized = hasAnyAuthority(authentication.me.authorities, hasAnyAuthorities);

    if (isAuthorized) {
      return (
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      );
    } else {
      return (
        <div className="insufficient-authority">
          <div className="alert alert-danger">
            <Translate contentKey="error.http.403">You are not authorized to access this page.</Translate>
          </div>
        </div>
      );
    }
  };

  const renderRedirect = () => {
    if (!authentication.sessionHasBeenFetched) {
      return <div></div>;
    } else {
      return authentication.isAuthenticated ? (
        checkAuthorities()
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            // search: props.location.search,
            // state: { from: props.location },
          }}
        />
      );
    }
  };

  return <Route {...props}>{renderRedirect}</Route>;
};

export const hasAnyAuthority = (authorities: TAuthorities[], hasAnyAuthorities: TAuthorities[]) => {
  if (!hasAnyAuthorities || hasAnyAuthorities?.length === 0) {
    return true;
  }
  return authorities.some(auth => hasAnyAuthorities?.includes(auth));
};

export const hasAnyCapability = (capabilities: string[], hasAnyCapabilities: string[]) => {
  if (hasAnyCapabilities) {
    // if (hasAnyCapabilities.length === 0) {
    //   return true;
    // }
    // return  hasAnyCapabilities.some(cap => capabilities.includes(cap));

    return hasAnyCapabilities.length === 0 ? true : hasAnyCapabilities.some(cap => capabilities.includes(cap));
  }
  return true;
};

export default PrivateRoute;
