import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';

import { Flex } from 'app/shared/components/flex';
import { Icon } from 'app/shared/icons';
import { TAccount, TAvatarContent } from 'app/shared/model/user.model';
import { Translate, translate } from 'react-jhipster';
import { Loader } from '../loader';

import { handleFileUpload } from 'app/shared/util/upload-utils';
import { updateAvatar } from 'app/modules/me/account.reducer';
import { Button } from '../button';
import { COLORS } from 'app/config/constants';

type TAvatarUpload = {
  account: TAccount;
  canEdit?: boolean;
  className?: string;
};

export const AvatarUpload = (props: TAvatarUpload) => {
  const { canEdit = true, className } = props;
  const accountState = useSelector((state: IRootState) => state.account);
  const account = props.account;
  const avatar = account?.avatar;
  const dispatch = useDispatch();
  const input = useRef(null);

  const onAvatarChange = async event => {
    const content = await handleFileUpload({ target: event.target.files[0], size: 3, type: 'image', resize: { width: 200, height: 200 } });
    'fileContent' in content && dispatch(updateAvatar(content, account.id));
    input.current.value = '';
  };

  useEffect(() => {}, [avatar]);

  return (
    <div className={`Avatar-upload ${className || ''}`}>
      <div className="avatar-container">
        {avatar && <img src={`data:${avatar.fileContent?.contentType};base64,` + avatar?.fileContent.contents} />}
        {!avatar && <img src="content/images/default-avatar.png" />}
      </div>

      {canEdit && (
        <>
          <div className="button-upload">
            <input
              type="file"
              id="avatar-upload-button"
              onChange={() => onAvatarChange(event)}
              ref={input}
              disabled={accountState.loading}
            />
            <label className="button super-light-grey sm" htmlFor="avatar-upload-button">
              {accountState.loading ? <Loader color={COLORS.BLACK} /> : translate('entity.action.selectImage')}
            </label>
          </div>
        </>
      )}
    </div>
  );
};
