import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { PageHeader } from 'app/shared/components/page';
import { translate } from 'react-jhipster';
import { Loader } from 'app/shared/components/loader';
import { Alert } from 'app/shared/components/alert';
import { Main } from './sections/main';
import { Account } from '../../../shared/forms/account/account';
import { Email } from '../settings/sections/email';
import { Password } from '../settings/sections/password';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AvatarUpload } from 'app/shared/components/avatar-upload';
import Address from 'app/shared/forms/address';
import CategoriesContainer from 'app/shared/forms/categories-container';
import AreasContainer from 'app/shared/forms/areas-container';

export const Settings = () => {
  const authentication = useSelector((state: IRootState) => state.authentication);
  const isRoleUser = hasAnyAuthority(authentication.me.authorities, ['ROLE_USER']);

  const dispatch = useDispatch();

  return (
    <>
      {authentication.loading && <Loader />}
      {!authentication.loading && !authentication.me && <Alert type="error" message={translate('error.loadingData')} />}
      {authentication.sessionHasBeenFetched && authentication.me && (
        <div className="Settings">
          <PageHeader title={translate('global.menu.account.settings')} />
          <div className="Settings-container">
            <div className="Settings-left-col">
              <AvatarUpload account={authentication.me.account} />
            </div>
            <div className="Settings-right-col">
              <Main />
              {isRoleUser && <Account account={authentication.me.account} type="USER" canEdit={true} />}
              {isRoleUser && <Address email={authentication.me.email} type="USER" />}
              {isRoleUser && <CategoriesContainer type="USER" login={authentication.me.login} mode="EDIT" />}
              {isRoleUser && <AreasContainer type="USER" login={authentication.me.login} mode="EDIT" />}
              <Email />
              <Password />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
