import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-jhipster';
import { Icon } from 'app/shared/icons';
import { Text } from 'app/shared/components/text';
import { Avatar } from 'app/shared/components/avatar';
import { IUser } from 'app/shared/model/user.model';
import { Flex } from 'app/shared/components/flex';
import { getUserStatus } from '../user.utils';

type TCardUser = {
  user: IUser;
  className?: string;
};

export const CardUser = (props: TCardUser) => {
  const { user, className } = props;

  const status = getUserStatus(user);

  return (
    <tr className={`table-item ${className || ''} `}>
      <td>
        <Link to={`users/${user.login}`}>
          <Avatar avatar={user?.account?.avatar} size="lg" />
        </Link>
      </td>
      <td>
        <Flex container flexDirection="column">
          <Link to={`users/${user.login}`}>
            <b>{user.login}</b>
          </Link>
          {user.email}
        </Flex>
      </td>
      <td>{user.account?.type ? translate(`userManagement.${user.account?.type}`) : '-'} </td>
      <td>{user.createdDate ? <Text type="date" format="DATETIME" content={user.createdDate} /> : '-'}</td>
      <td>
        <div className={`status-badge ${status}`}>{translate(`userManagement.tabs.${status}`)}</div>
      </td>
      <td>{user.account?.rui}</td>
      <td>-</td>
      <td>-</td>
      <td>
        {user.overDraft ? (
          <div className="rounde-check-success">
            <Icon content="checked" />
          </div>
        ) : (
          '-'
        )}
      </td>
      <td className="text-right">
        <div className="button-group">
          <Link to={`users/${user.login}`} className="button sm white">
            <Icon content="arrowRight" />
          </Link>
        </div>
      </td>
    </tr>
  );
};
