import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IProduct } from 'app/shared/model/products.model';
import { avatarDto } from 'app/shared/model/user.model';
import { translate } from 'react-jhipster';
import { handleModal } from 'app/shared/reducers/modals';

export const ACTION_TYPES = {
  FETCH_PRODUCTS: 'products/FETCH_PRODUCTS',
  FETCH_PRODUCTS_COUNTS: 'products/FETCH_PRODUCTS_COUNTS',
  FETCH_PRODUCT: 'products/FETCH_PRODUCT',
  CREATE_PRODUCT: 'products/CREATE_PRODUCT',
  EDIT_PRODUCT: 'products/EDIT_PRODUCT',
  UPLOAD_PRODUCT_AVATAR: 'products/UPLOAD_PRODUCT_AVATAR',
  CLEAR_PRODUCT: 'products/CLEAR_PRODUCT',
  PUBLISH_PRODUCT: 'products/PUBLISH_PRODUCT',
  DELETE_PRODUCT: 'products/DELETE_PRODUCT',
  RESET: 'products/RESET',
  EDIT: 'products/EDIT',
};

const initialState = {
  loading: false,
  editing: false,
  publishing: false,
  deleting: false,
  errorMessage: null,
  products: [] as IProduct[],
  product: null as IProduct,
  updating: false,
  uploading: false,
  uploadSuccess: false,
  uploadError: false,
  fetchSuccess: false,
  fetchError: false,
  totalItems: 0,
  count: null as any,
};

export type ProductsState = Readonly<typeof initialState>;

// Reducer
export default (state: ProductsState = initialState, action): ProductsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PRODUCTS):
    case REQUEST(ACTION_TYPES.FETCH_PRODUCT):
    case REQUEST(ACTION_TYPES.CREATE_PRODUCT):
    case REQUEST(ACTION_TYPES.EDIT_PRODUCT):
      return {
        ...state,
        errorMessage: null,
        fetchSuccess: false,
        loading: true,
      };

    case REQUEST(ACTION_TYPES.UPLOAD_PRODUCT_AVATAR):
      return {
        ...state,
        uploading: true,
        uploadSuccess: false,
        uploadError: false,
      };
    case REQUEST(ACTION_TYPES.PUBLISH_PRODUCT):
      return {
        ...state,
        publishing: true,
      };
    case REQUEST(ACTION_TYPES.DELETE_PRODUCT):
      return {
        ...state,
        deleting: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_PRODUCTS):
    case FAILURE(ACTION_TYPES.FETCH_PRODUCT):
    case FAILURE(ACTION_TYPES.CREATE_PRODUCT):
    case FAILURE(ACTION_TYPES.EDIT_PRODUCT):
      return {
        ...state,
        loading: false,
        updating: false,
        fetchSuccess: false,
        fetchError: true,
        errorMessage: action.payload.response?.data?.message,
      };

    case FAILURE(ACTION_TYPES.UPLOAD_PRODUCT_AVATAR):
      return {
        ...state,
        uploading: false,
        uploadSuccess: false,
        uploadError: true,
      };

    case FAILURE(ACTION_TYPES.PUBLISH_PRODUCT):
      return {
        ...state,
        publishing: false,
      };
    case FAILURE(ACTION_TYPES.DELETE_PRODUCT):
      return {
        ...state,
        deleting: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PRODUCTS):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
        products: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_PRODUCTS_COUNTS):
      return {
        ...state,
        count: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PRODUCT):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
        product: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.CREATE_PRODUCT):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
      };
    case SUCCESS(ACTION_TYPES.EDIT_PRODUCT):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        fetchError: false,
        editing: !state.editing,
      };

    case SUCCESS(ACTION_TYPES.UPLOAD_PRODUCT_AVATAR):
      return {
        ...state,
        uploading: false,
        uploadSuccess: true,
        uploadError: false,
      };

    case SUCCESS(ACTION_TYPES.PUBLISH_PRODUCT):
      return {
        ...state,
        publishing: false,
      };

    case SUCCESS(ACTION_TYPES.DELETE_PRODUCT):
      return {
        ...state,
        publishing: false,
      };

    case ACTION_TYPES.CLEAR_PRODUCT:
      return {
        ...initialState,
        product: null,
      };

    case ACTION_TYPES.EDIT:
      return {
        ...state,
        editing: !state.editing,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

const apiUrl = 'api/products';
// Actions
export const getProducts = params => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.FETCH_PRODUCTS,
    payload: axios.post(`${apiUrl}/search`, params),
  });
};
export const getProductsCounts = params => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.FETCH_PRODUCTS_COUNTS,
    payload: axios.post(`${apiUrl}/counts`, params),
    meta: 'HIDE_NOTIFICATIONS',
  });
};

export const getProduct = (id: number) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.FETCH_PRODUCT,
    payload: axios.get(`${apiUrl}/${id}`),
    meta: 'HIDE_NOTIFICATIONS',
  });
};

export const createProduct = (product: IProduct, history) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PRODUCT,
    payload: axios.post(`${apiUrl}`, product),
    meta: {
      successMessage: translate('products.messages.createSuccess'),
      errorMessage: translate('products.messages.createError'),
    },
  });
  result.value.status === 201 && history.push(`/products/${result.value.data.id}`);
};
export const updateProduct = (product: IProduct) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.EDIT_PRODUCT,
    payload: axios.patch(`${apiUrl}/${product.id}`, product),
    meta: {
      successMessage: translate('products.messages.updateSuccess'),
      errorMessage: translate('products.messages.updateError'),
    },
  });
  dispatch(getProduct(product.id));
};

export const publishProduct = (product: IProduct) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.PUBLISH_PRODUCT,
    payload: axios.patch(`${apiUrl}/${product.id}`, product),
    meta: {
      successMessage: translate('products.messages.publishSuccess'),
      errorMessage: translate('products.messages.publishError'),
    },
  });
  dispatch(getProduct(product.id));
};

export const updateProductAvatar = (productId: number, avatar: avatarDto) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.UPLOAD_PRODUCT_AVATAR,
    payload: axios.post(`${apiUrl}/${productId}/avatar`, avatar),
    meta: {
      successMessage: translate('products.messages.avatarSuccess'),
      errorMessage: translate('products.messages.avatarError'),
    },
  });
  dispatch(getProduct(productId));
};

export const deleteProduct = (productId: number, history) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PRODUCT,
    payload: axios.delete(`${apiUrl}/${productId}`),
    meta: {
      successMessage: translate('products.messages.deleteSuccess'),
      errorMessage: translate('products.messages.deleteError'),
    },
  });
  dispatch(handleModal('deleteProduct', false));
  result.value.status === 204 && history.push(`/products`);
};

export const clearProduct = () => ({
  type: ACTION_TYPES.CLEAR_PRODUCT,
});

export const editProduct = () => ({
  type: ACTION_TYPES.EDIT,
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
