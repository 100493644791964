import './home.scss';

import React from 'react';
 

export const Home = () => {
 

  return <> Dashboard</>;
};

export default Home;
