import { avatarDto } from 'app/shared/model/user.model';
import React from 'react';

type ProductImageProps = {
  image: avatarDto;
  size?: 'sm' | 'md' | 'auto';
};

export const ProductImage = (props: ProductImageProps) => {
  const { image, size = 'md' } = props;

  const contents = image?.fileContent?.contents;
  const type = image?.fileContent?.contentType;

  const src = contents && type ? `data:${type};base64,${contents}` : 'content/images/default-product.png';

  return (
    <div className={`product-image ${size}`}>
      <img src={src} />
    </div>
  );
};
