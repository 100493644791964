import React, { useState, useEffect } from 'react';
import { defaultAccount, defaultInsuree, defaultUser, IInsuree, IUser, TAccount } from 'app/shared/model/user.model';
import { Heading } from 'app/shared/components/heading';
import { translate } from 'react-jhipster';
import { useForm } from 'react-hook-form';
import { Form, FormItem, FormError, FormRow, ReadOnly, FormGroupTitle } from 'app/shared/components/form/form';
import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Icon } from 'app/shared/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateAccount } from 'app/modules/me/account.reducer';
import { getOptionLabel } from 'app/shared/util/account-utils';
import { IRootState } from 'app/shared/reducers';
import { Loader } from 'app/shared/components/loader';
import { Select } from 'app/shared/components/select';
import { FormGroup } from 'reactstrap';
import { createInsuree, InsureesState } from 'app/modules/insurees/insurees.reducer';
import { useHistory } from 'react-router';
import { optionsTypeInsuree, optionsTypeInsureeSelect, optionsTypeUser, optionsTypeUserSelect } from 'app/shared/util/select.utils';
import { validationSchema } from 'app/shared/util/validations.utils';
import { createUser, UsersState } from 'app/modules/users/users.reducer';

type TAddAccount = {
  type: 'USER' | 'INSUREE';
};

type FormType = IUser & IInsuree;
type StateType = UsersState & InsureesState;

export const AccountNew = (props: TAddAccount) => {
  const { type } = props;

  const [clicked, setClicked] = useState<boolean>(false);

  const defaultFormValues = (type === 'USER' ? defaultUser : defaultInsuree) as FormType;

  const history = useHistory();
  const dispatch = useDispatch();
  const addState = useSelector((state: IRootState) => (type === 'USER' ? state.users : state.insurees)) as StateType;

  const optionsType = type === 'USER' ? optionsTypeUserSelect() : optionsTypeInsureeSelect();
  const defaultForm = {
    ...defaultFormValues,
    account: { ...defaultFormValues.account, type: type === 'USER' ? 'BROKER' : 'PHYSICAL' },
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultForm,
    shouldUnregister: true,
  });

  const watchType = watch('account.type');

  const onAccountTypeChange = option => {
    setValue('account.type', option.value, { shouldValidate: true });

    if (option.value === 'BROKER' || option.value === 'PHYSICAL') {
      unregister('account.firstName');
      unregister('account.lastName');
    } else {
      unregister('account.companyName');
    }
  };

  const onUpdateAccount = data => {
    setClicked(true);
    type === 'USER' ? dispatch(createUser(data)) : dispatch(createInsuree(data));
  };

  useEffect(() => {
    if (addState.fetchSuccess && clicked) {
      if (type === 'USER') {
        history.push(`/users/${addState.user.login}`);
      } else {
        history.push(`/insurees/${addState.insuree.id}`);
      }
    }
  }, [addState.fetchSuccess]);

  return (
    <Form>
      <FormGroup>
        <FormGroupTitle title="General" />
        <FormRow>
          <FormItem
            htmlFor="email"
            label={translate('userManagement.email')}
            className={`col ${type === 'USER' ? 'col-50' : 'col-100'} col-xs-100 `}
          >
            <input
              className={`form-control ${errors?.email ? 'is-invalid' : ''}`}
              id="email"
              name="email"
              type="text"
              placeholder={translate('userManagement.email')}
              {...register('email', validationSchema.email)}
            />
            <FormError error={errors?.email} validation={validationSchema.email} />
          </FormItem>

          {type === 'USER' && (
            <FormItem htmlFor="username" label={translate('userManagement.username')} className="col col-50 col-xs-100">
              <input
                className={`form-control ${errors?.login ? 'is-invalid' : ''}`}
                id="username"
                type="text"
                placeholder={translate('userManagement.username')}
                {...register('login', validationSchema.userName)}
              />
              <FormError error={errors?.login} validation={validationSchema.userName} />
            </FormItem>
          )}
        </FormRow>
      </FormGroup>

      <FormGroup>
        <FormGroupTitle title="Account" />

        <FormRow>
          <FormItem htmlFor="type" label={translate('userManagement.type')}>
            <Select
              options={optionsType}
              onSelect={onAccountTypeChange}
              selected={defaultForm.account.type}
              showReset={false}
              isPlaceholderIcon={false}
            />
            <input name="isCompany" type="hidden" {...register('account.type')} />
          </FormItem>
        </FormRow>

        {(watchType === 'BROKER' || watchType === 'PHYSICAL') && (
          <FormRow>
            <FormItem htmlFor="firstName" label={translate('userManagement.firstName')} className="col col-50 col-xs-100">
              <input
                className={`form-control ${errors?.account?.firstName ? 'is-invalid' : ''}`}
                id="firstName"
                type="text"
                placeholder={translate('userManagement.firstName')}
                {...register('account.firstName', validationSchema.firstName)}
              />
              <FormError error={errors?.account?.firstName} validation={validationSchema.firstName} />
            </FormItem>
            <FormItem htmlFor="lastName" label={translate('userManagement.lastName')} className="col col-50 col-xs-100">
              <input
                className={`form-control ${errors?.account?.lastName ? 'is-invalid' : ''}`}
                id="lastName"
                type="text"
                placeholder={translate('userManagement.lastName')}
                {...register('account.lastName', validationSchema.lastName)}
              />
              <FormError error={errors?.account?.lastName} validation={validationSchema.lastName} />
            </FormItem>
          </FormRow>
        )}

        {(watchType === 'AGENCY' || watchType === 'JURIDICAL') && (
          <FormRow>
            <FormItem htmlFor="firstName" label={translate('userManagement.companyName')}>
              <input
                className={`form-control ${errors?.account?.companyName ? 'is-invalid' : ''}`}
                id="companyName"
                type="text"
                placeholder={translate('userManagement.companyName')}
                {...register('account.companyName', validationSchema.companyName)}
              />
              <FormError error={errors?.account?.companyName} validation={validationSchema.companyName} />
            </FormItem>
          </FormRow>
        )}

        <FormRow>
          <FormItem htmlFor="taxCode" label={translate('userManagement.fiscalCode')}>
            <input
              className={`form-control ${errors?.account?.taxCode ? 'is-invalid' : ''}`}
              id="taxCode"
              type="text"
              placeholder={translate('userManagement.fiscalCode')}
              {...register('account.taxCode', validationSchema.taxCode)}
            />
            <FormError error={errors?.account?.taxCode} validation={validationSchema.taxCode} />
          </FormItem>
        </FormRow>

        <FormRow>
          <FormItem htmlFor="mobileLine" label={translate('userManagement.mobileLine')} className="col col-50 col-xs-100">
            <input
              className={`form-control ${errors?.account?.mobileLine ? 'is-invalid' : ''}`}
              id="mobileLine"
              type="text"
              placeholder={translate('userManagement.mobileLine')}
              {...register('account.mobileLine', validationSchema.mobileLine)}
            />
            <FormError error={errors?.account?.mobileLine} validation={validationSchema.mobileLine} />
          </FormItem>

          <FormItem htmlFor="landLine" label={translate('userManagement.landLine')} className="col col-50 col-xs-100">
            <input
              className={`form-control ${errors?.account?.landLine ? 'is-invalid' : ''}`}
              id="landLine"
              type="text"
              placeholder={translate('userManagement.landLine')}
              {...register('account.landLine', validationSchema.landLine)}
            />
            <FormError error={errors?.account?.landLine} validation={validationSchema.landLine} />
          </FormItem>
        </FormRow>

        {type === 'USER' && (
          <FormRow>
            <FormItem htmlFor="rui" label={translate('userManagement.rui')}>
              <input
                className={`form-control ${errors?.account?.rui ? 'is-invalid' : ''}`}
                id="rui"
                type="text"
                placeholder={translate('userManagement.rui')}
                {...register('account.rui', validationSchema.rui)}
              />
              <FormError error={errors?.account?.rui} validation={validationSchema.rui} />
            </FormItem>
          </FormRow>
        )}
        {(type === 'USER' || (type === 'INSUREE' && watchType === 'JURIDICAL')) && (
          <FormRow>
            <FormItem htmlFor="vat" label={translate('userManagement.vat')}>
              <input
                className={`form-control ${errors?.account?.vat ? 'is-invalid' : ''}`}
                id="vat"
                type="text"
                placeholder={translate('userManagement.vat')}
                {...register('account.vat', validationSchema.vat)}
              />
              <FormError error={errors?.account?.vat} validation={validationSchema.vat} />
            </FormItem>
          </FormRow>
        )}
      </FormGroup>

      <FormRow>
        <FormItem>
          <Flex container alignItems="center">
            <Button className="ml-auto" size="md" color="primary" onClick={handleSubmit(onUpdateAccount)} disabled={!isValid}>
              {addState.loading ? <Loader /> : translate('entity.action.save')}
            </Button>
          </Flex>
        </FormItem>
      </FormRow>
    </Form>
  );
};
