import React, { useEffect, useRef } from 'react';
import Backdrop from './backdrop';
import { Icon } from 'app/shared/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Heading } from 'app/shared/components/heading';
import './modal.scss';
import { Button } from '../button';

type ModalProps = {
  close?: () => void;
  showCloseButton?: boolean;
  title?: string;
  children: any;
  size?: TSizes;
};

type TSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const Modal = (props: ModalProps) => {
  const { close, showCloseButton = true, title, children, size } = props;
  const modal = useRef(null);
  const backdrop = useRef(null);

  const handleClose = () => {
    modal.current?.classList.remove('open');
    backdrop.current?.classList.remove('open');
    setTimeout(() => {
      close();
    }, 100);
  };

  const handleKey = e => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKey);
    document.body.classList.add('oy-hidden');
    return () => {
      window.removeEventListener('keydown', handleKey);
      document.body.classList.remove('oy-hidden');
    };
  }, [handleKey]);

  useEffect(() => {
    setTimeout(() => {
      modal.current?.classList.add('open');
      backdrop.current?.classList.add('open');
    }, 100);
  }, []);

  return (
    <>
      <div ref={backdrop} className="Backdrop" onClick={handleClose} />

      <div ref={modal} className={`Modal ${size || ''}  `}>
        <div className="Modal-header">
          {title && <Heading content={title} size={15} />}
          {showCloseButton && (
            <Button size="sm" color="white" className="ml-auto" onClick={handleClose}>
              <Icon content="close" />
            </Button>
          )}
        </div>
        <PerfectScrollbar
          options={{
            wheelPropagation: false,
            suppressScrollX: true,
          }}
        >
          <div className="Modal-content">{children}</div>
        </PerfectScrollbar>
      </div>
    </>
  );
};
