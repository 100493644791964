import { Alert } from 'app/shared/components/alert';
import { Back } from 'app/shared/components/back';
import { Loader } from 'app/shared/components/loader';
import { PageHeader } from 'app/shared/components/page';
import Price from 'app/shared/components/price';
import AreasContainer from 'app/shared/forms/areas-container';
import CategoriesContainer from 'app/shared/forms/categories-container';
import { ProductForm } from 'app/shared/forms/product/product-form';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect } from 'react';
import { translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProductImage from '../product-image';
import { clearProduct, getProduct } from '../products.reducer';

export const ProductViewUser = () => {
  const { id }: any = useParams();

  const products = useSelector((state: IRootState) => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('DISPATCH GET PRODUCT');

    dispatch(getProduct(id));
    return () => {
      dispatch(clearProduct());
    };
  }, []);

  return (
    <>
      <div className="container xl">
        <Back />

        <div className="Settings Product">
          {products.loading && !products.product && <Loader />}
          {!products.loading && products.fetchError && <Alert type="error" message={translate('error.loadingData')} />}
          {!products.loading && products.product && (
            <div className="Settings-container">
              <div className="Settings-left-col">
                <div className="product-quotation">
                  <div className="product-price">
                    <label>{translate('products.labels.price')}</label>
                    <h2>
                      <Price amount={products.product.price} />
                    </h2>
                  </div>
                  <div className="button md primary">{translate('products.view.requestQuotation')}</div>
                </div>
              </div>
              <div className="Settings-right-col">
                <div className="Settings-section product-details">
                  <h1>{products.product.title}</h1>

                  <div className="product-cover">
                    <ProductImage image={products.product.avatar} size="auto" />
                  </div>

                  <div className="product-description">{products.product.description}</div>
                </div>

                <CategoriesContainer type="PRODUCT" id={products.product.id} mode="VIEW" />
                <AreasContainer type="PRODUCT" id={products.product.id} mode="VIEW" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
