import React, { useState, useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Icon } from 'app/shared/icons';
import { TIcons } from 'app/config/types';
import './select.scss';

type SelectType = {
  className?: string;
  options: OptionType[];
  selected?: string | number | boolean;
  onSelect: (option) => void;
  placeholder?: string;
  showReset?: boolean;
  isPlaceholderIcon?: boolean;
  icon?: string;
};

type OptionType = {
  label: string;
  value: string | number | boolean;
};

export const Select = (props: SelectType) => {
  const { className, options, selected, onSelect, placeholder, showReset = true, isPlaceholderIcon, icon } = props;

  const [selectState, setSelectState] = useState({
    isSelectOpen: false,
    currentValue: selected,
  });

  const select = useRef(null);

  const [styles, setStyle] = useState({
    left: 0,
    top: 0,
    width: 0,
  });

  const handleOptionClick = (option: OptionType) => {
    setSelectState({ isSelectOpen: false, currentValue: option.value });
    onSelect(option);
  };

  const clearValue = () => {
    setSelectState({ ...selectState, currentValue: '' });
    onSelect('');
  };

  const toggleSelect = () => {
    setSelectState({ ...selectState, isSelectOpen: !selectState.isSelectOpen });
  };

  function handleClickOutside(event) {
    if (select && !select.current.contains(event.target)) {
      setSelectState({ ...selectState, isSelectOpen: false });
    }
  }

  useEffect(() => {
    setSelectState({ ...selectState, currentValue: props.selected });
  }, [props.selected]);

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const OptionContainer = () => {
    return (
      <PerfectScrollbar
        options={{
          wheelPropagation: false,
          suppressScrollX: true,
        }}
        className="Select-options"
      >
        {options.map((option, i) => (
          <div
            className={`Select-option ${option.value === selectState.currentValue ? 'active' : ''}`}
            onClick={() => handleOptionClick(option)}
            key={i}
          >
            {option.label}
          </div>
        ))}
      </PerfectScrollbar>
    );
  };

  const getLabelByValue = value => {
    const selectedItem = options.filter(option => option.value === value);

    if (selectedItem.length > 0) {
      return selectedItem[0].label;
    }

    return '';
  };

  if (options.length === 0) {
    return <></>;
  }

  return (
    <div className={`Select ${className}`} ref={select}>
      {isPlaceholderIcon && (
        <button className="button sm solid white" onClick={toggleSelect}>
          <Icon content={icon as TIcons} />
        </button>
      )}
      {!isPlaceholderIcon && (
        <>
          <input
            readOnly
            className="Select-input"
            onClick={toggleSelect}
            placeholder={placeholder}
            value={getLabelByValue(selectState.currentValue)}
          />
          {showReset && selectState.currentValue && (
            <div className="Select-clear" onClick={clearValue}>
              <Icon content="close" />
            </div>
          )}
          {(!showReset || !selectState.currentValue) && (
            <div className="Select-arrow" onClick={clearValue}>
              <Icon content="arrowDown" />
            </div>
          )}
        </>
      )}

      {selectState.isSelectOpen && OptionContainer()}
    </div>
  );
};
