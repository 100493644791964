import { COLORS } from 'app/config/constants';
import { Alert } from 'app/shared/components/alert';
import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Heading } from 'app/shared/components/heading';
import { Loader } from 'app/shared/components/loader';
import { Modal } from 'app/shared/components/modal';
import { Icon } from 'app/shared/icons';
import { IRootState } from 'app/shared/reducers';
import { handleModal } from 'app/shared/reducers/modals';
import { SelectItems } from 'app/shared/select-items/select-items';
import { saveProductCategories, saveUserCategories } from 'app/shared/select-items/select-items.reducer';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { getProductCategories, getUserCategories } from './categories-container.reducer';

type UserCat = {
  type: 'USER';
  login: string;
};

type ProductCat = {
  type: 'PRODUCT';
  id: number;
};

type ModeCat = {
  mode: 'VIEW' | 'EDIT';
};

type CategoriesContainerProps = ModeCat & (UserCat | ProductCat);

export const CategoriesContainer = (props: CategoriesContainerProps) => {
  const selectedCategories = useSelector((state: IRootState) => state.selectedCategories);
  const modalState = useSelector((state: IRootState) => state.modal);
  const dispatch = useDispatch();

  const getSelected = () => {
    return selectedCategories?.categories?.map(category => category.id);
  };

  const onSave = (ids: number[]) => {
    props.type === 'USER' ? dispatch(saveUserCategories(props.login, ids)) : dispatch(saveProductCategories(props.id, ids));
  };

  useEffect(() => {
    props.type === 'USER' ? dispatch(getUserCategories(props.login)) : dispatch(getProductCategories(props.id));
  }, []);

  return (
    <div className="Settings-section">
      <Flex container alignItems="center" className="mb-1">
        <Heading content={translate('categories.title')} size={15} />

        {props.mode === 'EDIT' && (
          <Button className="ml-auto" size="sm" color="white" onClick={() => dispatch(handleModal('selectCategories', true))}>
            <Icon content="edit" />
          </Button>
        )}
      </Flex>

      {selectedCategories.loading && <Loader color={COLORS.PRIMARY} />}
      {selectedCategories.fetchError && <Alert type="error" message={translate('error.loadingData')} />}
      {selectedCategories.categories && (
        <>
          {selectedCategories.categories?.length === 0 && translate('global.messages.info.noResults')}
          {selectedCategories.categories?.length > 0 && (
            <Flex container flexWrap="wrap" style={{ gridGap: '1rem' }}>
              {selectedCategories.categories.map((category, i) => (
                <div key={category.id} className="category-badge">
                  {category.name}
                </div>
              ))}
            </Flex>
          )}
        </>
      )}

      {modalState.selectCategories && (
        <Modal title={translate('categories.selectCategories')} size="xs" close={() => dispatch(handleModal('selectCategories', false))}>
          <SelectItems selected={getSelected()} type="CATEGORIES" onSave={onSave} />
        </Modal>
      )}
    </div>
  );
};
