import React from 'react';

type HeadingProps = {
  content: string;
  size: 1 | 12 | 15 | 18 | 2 | 3 | 4 | 5 | 6;
  className?: string;
};

export const Heading = (props: HeadingProps) => {
  const { content, className, size } = props;

  return (
    <div className={`heading ${className || ''}`} data-size={size}>
      {content}
    </div>
  );
};
