import React from 'react';
import './alert.scss';

type AlertType = {
  type: 'info' | 'success' | 'error' | 'warning';
  message: string;
};

const Alert = (props: AlertType) => {
  const { type, message } = props;

  return <div className={`Alert ${type}`}>{message}</div>;
};

export default Alert;
