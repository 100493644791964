import { createProduct, editProduct, updateProduct } from 'app/modules/products/products.reducer';
import { Back } from 'app/shared/components/back';
import { Button } from 'app/shared/components/button';
import { Flex } from 'app/shared/components/flex';
import { Form, FormError, FormItem, FormRow, ReadOnly } from 'app/shared/components/form';
import { Heading } from 'app/shared/components/heading';
import { Loader } from 'app/shared/components/loader';
import { PageHeader } from 'app/shared/components/page';
import Price from 'app/shared/components/price';
import CategoriesContainer from 'app/shared/forms/categories-container';
import { Icon } from 'app/shared/icons';
import { defaultProduct, IProduct } from 'app/shared/model/products.model';
import { IRootState } from 'app/shared/reducers';
import { validationSchema } from 'app/shared/util/validations.utils';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

type ProductFormProps = {
  product?: IProduct;
};

export const ProductForm = (props: ProductFormProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const products = useSelector((state: IRootState) => state.products);

  const isNew = products.product?.id ? false : true;
  const edit = isNew ? true : products.editing;

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: props.product || defaultProduct,
  });

  const maxLength = 2000;
  const watchDesc = watch('description', props.product?.description);

  const toggleEdit = () => {
    dispatch(editProduct());
    reset(props.product || defaultProduct);
  };

  const onSubmit = data => {
    products.product?.id ? dispatch(updateProduct(data)) : dispatch(createProduct(data, history));
  };

  return (
    <div className="Settings-section">
      <Flex container alignItems="center" className="mb-1">
        {!isNew && <Heading content={translate('products.view.subtitle')} size={15} />}
        {!edit && (
          <Button className="ml-auto" size="sm" color="white" onClick={toggleEdit}>
            <Icon content="edit" />
          </Button>
        )}
      </Flex>

      <Form>
        <FormRow>
          <FormItem htmlFor="title" label={translate('products.labels.title')} className="col">
            {edit ? (
              <>
                <input
                  className={`form-control ${errors?.title ? 'is-invalid' : ''}`}
                  id="title"
                  type="text"
                  placeholder={translate('products.labels.title')}
                  {...register('title', validationSchema.required)}
                />
                <FormError error={errors?.title} validation={validationSchema.required} />
              </>
            ) : (
              <ReadOnly content={products.product?.title} />
            )}
          </FormItem>

          <FormItem htmlFor="price" label={translate('products.labels.price')} className="col">
            {edit ? (
              <>
                <input
                  className={`form-control ${errors?.title ? 'is-invalid' : ''}`}
                  id="price"
                  type="text"
                  placeholder={translate('products.labels.price')}
                  {...register('price', validationSchema.required)}
                />
                <FormError error={errors?.price} validation={validationSchema.required} />
              </>
            ) : (
              <ReadOnly content={<Price amount={products.product?.price} />} />
            )}
          </FormItem>

          <FormItem htmlFor="insurance" label={translate('products.labels.insurance')} className="col">
            {edit ? (
              <>
                <input
                  className={`form-control ${errors?.title ? 'is-invalid' : ''}`}
                  id="insurance"
                  type="text"
                  placeholder={translate('products.labels.insurance')}
                  {...register('insurance', validationSchema.required)}
                />
                <FormError error={errors?.insurance} validation={validationSchema.required} />
              </>
            ) : (
              <ReadOnly content={products.product?.insurance} />
            )}
          </FormItem>

          <FormItem htmlFor="description" label={translate('products.labels.description')} className="col">
            {edit ? (
              <>
                <div className="descCharCount">
                  {watchDesc?.length || 0} / {maxLength}
                </div>
                <textarea
                  className={`form-control product-description ${errors?.title ? 'is-invalid' : ''}`}
                  id="description"
                  placeholder={translate('products.labels.description')}
                  maxLength={maxLength}
                  defaultValue={products.product?.description}
                  {...register('description', validationSchema.required)}
                />
                <FormError error={errors?.description} validation={validationSchema.required} />
              </>
            ) : (
              <ReadOnly content={products.product?.description} />
            )}
          </FormItem>
        </FormRow>

        {edit && (
          <FormRow>
            <FormItem>
              <Flex container alignItems="center">
                {!isNew && (
                  <Button size="md" color="white" onClick={toggleEdit}>
                    {translate('entity.action.cancel')}
                  </Button>
                )}
                <Button className="ml-auto" size="md" color="primary" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                  {products.loading ? <Loader /> : translate('entity.action.save')}
                </Button>
              </Flex>
            </FormItem>
          </FormRow>
        )}
      </Form>
    </div>
  );
};
