import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import { ProductsSearch } from './search/products-search';
import PageNotFound from 'app/shared/error/page-not-found';
import ProductNew from './new';
import { ProductViewAdmin } from './view/product-view-admin';
import PrivateRoute, { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { ProductViewUser } from './view/product-view-user';

const Routes = () => {
  const authentication = useSelector((state: IRootState) => state.authentication);
  const isRoleAdmin = hasAnyAuthority(authentication.me.authorities, ['ROLE_ADMIN']);

  return (
    <>
      <Switch>
        <PrivateRoute exact path="/products/new" component={ProductNew} hasAnyAuthorities={[AUTHORITIES.SYSTEM, AUTHORITIES.ADMIN]} />
        <ErrorBoundaryRoute exact path="/products/:id" component={isRoleAdmin ? ProductViewAdmin : ProductViewUser} />
        <ErrorBoundaryRoute exact path="/products" component={ProductsSearch} />
        <ErrorBoundaryRoute component={PageNotFound} />
      </Switch>
    </>
  );
};

export default Routes;
