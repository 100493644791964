import { TTableListHeaderItem } from 'app/shared/components/table-list';
import { translate } from 'react-jhipster';

export type TOrder = 'ASC' | 'DESC';
export type TSort = 'name' | 'createdDate';
export type TSearchTabs = 'ALL';

export const defaultInsureesSearchParams = {
  postPerPage: 10,
  currentPage: 0,
  sortBy: 'createdDate' as TSort,
  sortDirection: 'ASC' as TOrder,
  name: null,
  taxCode: null,
  email: null,
  typeInsuree: null,
};

export type TdefaultInsureesSearchParams = Readonly<typeof defaultInsureesSearchParams>;

export const getInsureesSearchTableHeader = sort => {
  return [
    {},
    {
      name: translate('userManagement.name'),
    },
    {
      name: translate('userManagement.type'),
    },
    {
      name: translate('userManagement.fiscalCode'),
    },
    {
      id: 'createdDate',
      name: translate('userManagement.createdDate'),
      onClick: sort('createdDate'),
    },

    {},
  ] as TTableListHeaderItem[];
};

export const insureeSearchTabs: () => { [key in TSearchTabs]: string } = () => {
  return {
    ALL: translate('userManagement.tabs.ALL'),
  };
};

export const genInsureeSearchParams = (params: TdefaultInsureesSearchParams) => {
  return {
    currentPage: params.currentPage,
    postPerPage: params.postPerPage,
    sortBy: [params.sortBy],
    sortDirection: params.sortDirection,
    name: params.name,
    email: params.email,
    type: params.typeInsuree,
    taxCode: params.taxCode,
    areas: [],
    categories: [],
  };
};
