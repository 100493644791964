import React, { useState, useEffect } from 'react';
import { translate, Translate } from 'react-jhipster';
import { Button } from 'app/shared/components/button';
import { FilterStatusBar } from './filters-status-bar';
import Filters from './filters';
import './filter-bar.scss';

type FilterbarProps = {
  showFilters: boolean;
  toggleFilters: () => void;
  params: any;
  onChangeParams: (params: any) => void;
  onRemoveParams: (param: string) => void;
  showFilterBar: boolean;
};

export const FilterNav = (props: FilterbarProps) => {
  const { showFilters, toggleFilters, params, onChangeParams, onRemoveParams, showFilterBar } = props;

  const body = document.querySelector('body');

  const onFilterParams = filteredParams => {
    onChangeParams(filteredParams);
    props.toggleFilters();
  };

  const toggleBody = () => {
    body.style.overflow = showFilters ? 'hidden' : '';
  };

  useEffect(() => {
    toggleBody();
  }, [showFilters]);

  useEffect(() => {}, [params]);

  return (
    <>
      <div className="filter-nav">
        <div className={`filter-form  ${showFilters ? 'isOpen' : ''}`}>
          <div className="filter-form-header">
            <h3>{translate('filter.filterBy')}</h3>
          </div>
          <Filters params={params} onFilterParams={onFilterParams} />
        </div>
        <div className={`backdrop ${showFilters ? 'isOpen' : ''}`} onClick={toggleFilters}></div>
      </div>
      {showFilterBar && <FilterStatusBar params={params} onRemoveParams={onRemoveParams} />}
    </>
  );
};
