import React from 'react';

type PriceProps = {
  amount: number;
  currency?: 'EUR';
  className?: string;
};

export const Price = (props: PriceProps) => {
  const { amount, currency = 'EUR', className } = props;

  const formattedPrice = new Intl.NumberFormat('it-It', {
    style: 'currency',
    currency,
  }).format(amount);

  return <div className={`price ${className || ''}`}> {formattedPrice}</div>;
};
