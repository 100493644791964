import React, { useEffect } from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { getUrlParameter, Translate } from 'react-jhipster';

import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { activateAction, reset } from './verify.reducer';
import { Flex } from 'app/shared/components/flex';
import { Brand } from 'app/shared/layout/header/header-components';

const successAlert = (
  <Alert color="success">
    <p>
      <Translate contentKey="activate.messages.success">Your user account has been activated.</Translate>
    </p>
    <Link to="/login" className="button md primary">
      <Translate contentKey="global.messages.info.authenticated.link">sign in</Translate>
    </Link>
  </Alert>
);

const failureAlert = (
  <Alert color="danger">
    <Translate contentKey="activate.messages.error">Your user could not be activated.</Translate>
  </Alert>
);

export const Verify = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const activate = useSelector((state: IRootState) => state.verify);
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);

  useEffect(() => {
    const key = getUrlParameter('key', props.location.search);
    dispatch(activateAction(key));
    return () => {
      dispatch(reset());
    };
  }, []);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Flex container flexDirection="column" className="no-auth-form">
        <Brand />
        <h1>
          <Translate contentKey="activate.title">Activation</Translate>
        </h1>
        {activate.verificationSuccess && successAlert}
        {activate.verificationFailure && failureAlert}
      </Flex>
    </>
  );
};
