import { COLORS } from 'app/config/constants';
import { Alert } from 'app/shared/components/alert';
import { Loader } from 'app/shared/components/loader';
import { PageHeader } from 'app/shared/components/page';
import { TableList, TableListBody, TableListHeader, TableListPagination } from 'app/shared/components/table-list';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { getInsurees } from '../insurees.reducer';
import { InsureeCard } from './insuree-card';
import { getParamsFromUrl, setParamsToUrl } from 'app/shared/util/pagination-utils';
import {
  defaultInsureesSearchParams,
  genInsureeSearchParams,
  getInsureesSearchTableHeader,
  insureeSearchTabs,
  TdefaultInsureesSearchParams,
  TSort,
} from '../insurees.utils';
import { Flex } from 'app/shared/components/flex';
import { Button } from 'app/shared/components/button';
import { Icon } from 'app/shared/icons';
import { FilterNav } from 'app/shared/components/filters-nav';
import { TabFilter } from 'app/shared/components/tab-filter';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Modal } from 'app/shared/components/modal';
import { InsureeCheck } from '../check/insuree-check';
import { handleModal } from 'app/shared/reducers/modals';
import { resetCheck } from '../check/insuree-check.reducer';
import { clearStoreParams, setStoreParams } from 'app/shared/reducers/params';

export const InsureesSearch = (props: RouteComponentProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const insureesState = useSelector((state: IRootState) => state.insurees);
  const authentication = useSelector((state: IRootState) => state.authentication);
  const modalState = useSelector((state: IRootState) => state.modal);
  const isRoleAdmin = hasAnyAuthority(authentication.me.authorities, ['ROLE_ADMIN']);

  const [params, setParams] = useState<TdefaultInsureesSearchParams>(getParamsFromUrl(defaultInsureesSearchParams, props.location));
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const sort = (param: TSort) => () => {
    setParams({
      ...params,
      sortDirection: params.sortDirection === 'ASC' ? 'DESC' : 'ASC',
      sortBy: param,
    });
  };

  const handlePagination = (currentPage: number) => {
    setParams({
      ...params,
      currentPage,
    });
  };

  const onChangeParams = filteredParamns => {
    setParams({ ...params, ...filteredParamns });
  };

  const onRemoveParams = key => {
    setParams({
      ...params,
      [key]: null,
    });
  };

  const onAddInsuree = () => {
    dispatch(handleModal('insureeCheck', true));
    dispatch(resetCheck());
  };

  useEffect(() => {
    dispatch(getInsurees(genInsureeSearchParams(params)));
    setParamsToUrl(params, history);
    dispatch(setStoreParams(genInsureeSearchParams(params)));
  }, [params.currentPage, params.sortDirection, params.sortBy, params.name, params.email, params.typeInsuree, params.taxCode]);

  useEffect(() => {
    setParams(getParamsFromUrl(defaultInsureesSearchParams, props.location));
  }, [props.location.search]);

  return (
    <>
      <PageHeader title={translate('insurees.search.title')}>
        <div className="button-group ml-auto">
          {!isRoleAdmin && (
            <Button size="md" color="primary" onClick={onAddInsuree}>
              <Translate contentKey="insurees.search.addInsuree">Create a new insuree</Translate>
            </Button>
          )}
        </div>
      </PageHeader>

      <Flex container alignItems="center" className="mb-1">
        <TabFilter tabs={insureeSearchTabs()} onChangeTab={null} currentTab={'ALL'} count={{ ALL: insureesState.totalItems }} />

        <div className="button-group">
          <Button size="sm" color="super-light-grey" onClick={() => setShowFilters(true)}>
            <span className="mr-1">{translate('filter.filter')}</span>
            <Icon content="filter" />
          </Button>
        </div>
      </Flex>

      <FilterNav
        showFilters={showFilters}
        params={params}
        onChangeParams={onChangeParams}
        onRemoveParams={onRemoveParams}
        toggleFilters={() => setShowFilters(!showFilters)}
        showFilterBar={true}
      />

      {insureesState.loading && <Loader color={COLORS.PRIMARY} />}
      {insureesState.fetchError && <Alert type="error" message={translate('error.loadingData')} />}
      {!insureesState.loading && !insureesState.fetchError && (
        <>
          {insureesState.insurees?.length === 0 && translate('global.messages.info.noResults')}
          {insureesState.insurees?.length > 0 && (
            <TableList className="color-black">
              <TableListHeader items={getInsureesSearchTableHeader(sort)} sortBy={params.sortBy} sortDirection={params.sortDirection} />
              <TableListBody>
                {insureesState.insurees.map(item => (
                  <InsureeCard key={`insuree-${item.id}`} insuree={item} className="card-insuree" />
                ))}
              </TableListBody>
              <TableListPagination
                activePage={params.currentPage}
                paginate={handlePagination}
                itemsPerPage={params.postPerPage}
                totalItems={insureesState.totalItems}
              />
            </TableList>
          )}
        </>
      )}

      {modalState.insureeCheck && (
        <Modal title={translate('insurees.search.addInsuree')} size="xs" close={() => dispatch(handleModal('insureeCheck', false))}>
          <InsureeCheck />
        </Modal>
      )}
    </>
  );
};
