import { Back } from 'app/shared/components/back';
import { PageHeader } from 'app/shared/components/page';
import AccountNew from 'app/shared/forms/account-new';
import React from 'react';
import { translate } from 'react-jhipster';

export const UsersNew = () => {
  return (
    <>
      <div className="container sm">
        <Back />

        <PageHeader title={translate('userManagement.home.createLabel')} />

        <AccountNew type="USER" />
      </div>
    </>
  );
};
